import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormsValidatorsService } from '@core/forms-validators/forms-validators.service';
import { FormErrorsService } from '@core/services/errors/form-errors/form-errors.service';
import { messagesConstant } from '@shared/constants/messages.constants';
import { SendEmailDialog } from '@shared/models/dialog';
import { FormErrorMessage } from '@shared/models/form-errors';
import {
  sendEmailDialogFormErrorIds,
  sendEmailDialogFormIds,
  SendEmailDialogFormNames,
} from './send-email-dialog.config';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendEmailDialogComponent implements OnInit {
  @Input() defaultEmail: string;
  @Input() defaultEmailBody: string;
  @Input() maxEmailsTextField = 5;
  @Input() sendMeCopyDefault;
  @Output() sendEmail = new EventEmitter<SendEmailDialog>();

  form: FormGroup;
  formFieldsNames = SendEmailDialogFormNames;
  formFieldsId = sendEmailDialogFormIds;
  formFieldsErrorId = sendEmailDialogFormErrorIds;

  constructor(
    public cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SendEmailDialogComponent>,
    private formsValidatorsService: FormsValidatorsService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      [this.formFieldsNames.emailBody]: [this.defaultEmailBody],
      [this.formFieldsNames.emailList]: this.fb.array([this.getFormEmail(this.defaultEmail)]),
      [this.formFieldsNames.sendMeCopy]: [this.sendMeCopyDefault],
    });

    this.setEmailValidations();
  }

  sendEmailClick(): void {
    this.formValidations();
    if (!this.form.valid) {
      return;
    }
    this.sendEmail.emit(this.getDataToSend());
  }

  close(): void {
    this.dialogRef.close();
  }

  getDataToSend(): SendEmailDialog {
    return {
      emails: this.getEmails(),
      emailBody: this.form.controls[this.formFieldsNames.emailBody].value,
      sendCopy: this.form.controls[this.formFieldsNames.sendMeCopy].value,
    };
  }

  // Form Rules
  isThereAtLeastOneEmail(): boolean {
    return this.getEmailFormArray()
      .controls.map((emailForm: FormGroup) => emailForm.controls[this.formFieldsNames.email].value)
      .some((emailValue: string) => !!emailValue);
  }

  formValidations(): void {
    if (!this.isThereAtLeastOneEmail() && !this.form.controls[this.formFieldsNames.sendMeCopy].value) {
      this.form.controls[this.formFieldsNames.emailList].setErrors({
        errorMessage: messagesConstant.general.forEmailRequiredMessage,
      } as FormErrorMessage);
    }

    this.cdr.detectChanges();
  }

  // Email Form Array
  setEmailValidations(): void {
    this.getEmailFormArray().controls.forEach((emailForm: FormGroup) =>
      emailForm.controls[this.formFieldsNames.email].setValidators([
        Validators.email,
        this.formsValidatorsService.validateDuplicatedEmail(this.getEmailFormArray(), this.formFieldsNames.email),
      ])
    );
  }

  getFormEmail(defaultEmail: string = null): AbstractControl {
    return this.fb.group({ [this.formFieldsNames.email]: [defaultEmail] });
  }

  getEmailFormArray(): FormArray {
    return this.form.controls[this.formFieldsNames.emailList] as FormArray;
  }

  getEmails(): string[] {
    return this.getEmailFormArray().controls.map(
      (emailForm: FormGroup) => emailForm.controls[this.formFieldsNames.email].value
    );
  }

  // Add more Emails button
  addMoreEmails(): void {
    if (!this.canAddMoreEmails()) {
      return;
    }
    this.getEmailFormArray().push(this.getFormEmail());
    this.setEmailValidations();
  }

  canAddMoreEmails(): boolean {
    return this.getEmailFormArray().controls.length < this.maxEmailsTextField;
  }

  // Form Errors to display
  fieldEmailErrorMessage(fieldName: string, index: number): string {
    const emailForm = this.getEmailFormArray().controls[index] as FormGroup;
    return FormErrorsService.getErrorFieldMessage(emailForm, fieldName);
  }

  fieldErrorMessage(fieldName: string): string {
    return FormErrorsService.getErrorFieldMessage(this.form, fieldName);
  }
}
