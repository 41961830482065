<mat-drawer-container #fullTray [hasBackdrop]="hasBackdrop" (backdropClick)="backdropClick()">
  <mat-drawer
    xpoTrayDrawer
    mode="over"
    position="end"
    [extended]="extended"
    [ngStyle]="getStyle()"
    (closedStart)="closedStart()"
    [disableClose]="contentComponent?.disableClose"
  >
    <xpo-tray-panel>
      <xpo-tray-panel-header class="tray-header">
        <xpo-tray-panel-header-title>
          <span>{{ contentComponent?.title }}</span>
        </xpo-tray-panel-header-title>
        <xpo-tray-panel-header-items *ngIf="contentComponent?.headerItems">
          <ng-container [ngTemplateOutlet]="contentComponent?.headerItems"> </ng-container>
        </xpo-tray-panel-header-items>
      </xpo-tray-panel-header>
      <xpo-tray-panel-content>
        <ng-container [ngTemplateOutlet]="contentComponent?.content"> </ng-container>
      </xpo-tray-panel-content>
      <xpo-tray-panel-footer *ngIf="contentComponent?.footer">
        <ng-container [ngTemplateOutlet]="contentComponent.footer"> </ng-container>
      </xpo-tray-panel-footer>
    </xpo-tray-panel>
  </mat-drawer>
  <mat-drawer-content>
    <ng-content select="[mainContent]"></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
