import { Pipe, PipeTransform } from '@angular/core';
import { CommodityPackageCdValues } from '@shared/constants/commodityPackageCdValues.constant';
import { CommodityPackageCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'commodityPackagesPipe'
})
export class CommodityPackagesPipe implements PipeTransform {

  transform(value: CommodityPackageCd): string {
    const transformedValue = CommodityPackageCdValues[value];

    if (!transformedValue) {
      console.error('commodityPackagesPipe, value is not part of commodity package enum', value);
      return value;
    }

    return transformedValue;
  }

}
