import { CommodityPackageCd } from "@xpo-ltl/sdk-common";

export const CommodityPackageCdValues = {
  [CommodityPackageCd.AMMO_PACK]: 'AMM',
  [CommodityPackageCd.ATTM]: 'ATH',
  [CommodityPackageCd.BAG]: 'BAG',
  [CommodityPackageCd.BALE]: 'BAL',
  [CommodityPackageCd.BANDING]: 'BDG',
  [CommodityPackageCd.BARGE]: 'BRG',
  [CommodityPackageCd.BARREL]: 'BBL',
  [CommodityPackageCd.BASKET]: 'BSK',
  [CommodityPackageCd.BEAM]: 'BEM',
  [CommodityPackageCd.BELTING]: 'BLT',
  [CommodityPackageCd.BIN]: 'BIN',
  [CommodityPackageCd.BING_CHEST]: 'BIC',
  [CommodityPackageCd.BOBBIN]: 'BOB',
  [CommodityPackageCd.BOTTLE]: 'BTL',
  [CommodityPackageCd.BOX]: 'BOX',
  [CommodityPackageCd.BOX_WITH_CONTAINER]: 'BXI',
  [CommodityPackageCd.BRACING]: 'BRC',
  [CommodityPackageCd.BUCKET]: 'BXT',
  [CommodityPackageCd.BULK]: 'BLK',
  [CommodityPackageCd.BULK_CARGO]: 'CBC',
  [CommodityPackageCd.BUNDLE]: 'BDL',
  [CommodityPackageCd.CABINET]: 'CAB',
  [CommodityPackageCd.CAGE]: 'CAG',
  [CommodityPackageCd.CAN]: 'CAN',
  [CommodityPackageCd.CAN_CASE]: 'CCS',
  [CommodityPackageCd.CARBOY]: 'CBY',
  [CommodityPackageCd.CARR]: 'CAR',
  [CommodityPackageCd.CARTON]: 'CTN',
  [CommodityPackageCd.CAR_LOAD_RAIL]: 'CLD',
  [CommodityPackageCd.CASE]: 'CAS',
  [CommodityPackageCd.CASK]: 'CSK',
  [CommodityPackageCd.CHEESES]: 'CHE',
  [CommodityPackageCd.CHEST]: 'CHS',
  [CommodityPackageCd.COIL]: 'COL',
  [CommodityPackageCd.CONES]: 'CON',
  [CommodityPackageCd.CONTAINER]: 'CNT',
  [CommodityPackageCd.CORE]: 'COR',
  [CommodityPackageCd.CORNER_REINFORCEMENT]: 'CRF',
  [CommodityPackageCd.CRADLE]: 'CRD',
  [CommodityPackageCd.CRATE]: 'CRT',
  [CommodityPackageCd.CYLINDER]: 'CYL',
  [CommodityPackageCd.DOUBLE_RACK]: 'DRK',
  [CommodityPackageCd.DOUBLE_SKID]: 'DSK',
  [CommodityPackageCd.DOUBLE_TOTE_BIN]: 'DTB',
  [CommodityPackageCd.DRUM]: 'DRM',
  [CommodityPackageCd.DRY_BULK]: 'DBK',
  [CommodityPackageCd.EDGE_PROTECTION]: 'EPR',
  [CommodityPackageCd.EGG_CRATING]: 'EGG',
  [CommodityPackageCd.ENVELOPE]: 'ENV',
  [CommodityPackageCd.FIRKIN]: 'FIR',
  [CommodityPackageCd.FLASK]: 'FSK',
  [CommodityPackageCd.FLO_BIN]: 'FLO',
  [CommodityPackageCd.FORWARD_REEL]: 'FWR',
  [CommodityPackageCd.FRAME]: 'FRM',
  [CommodityPackageCd.HALF_RACK]: 'HRK',
  [CommodityPackageCd.HALF_TOTE_BIN]: 'HTB',
  [CommodityPackageCd.HANGER_RACK]: 'HRB',
  [CommodityPackageCd.HEADS_BEEF]: 'HED',
  [CommodityPackageCd.HOGSHEAD]: 'HGH',
  [CommodityPackageCd.HOPPER_TRUCK]: 'HPT',
  [CommodityPackageCd.INT_BULK_CTR]: 'IBC',
  [CommodityPackageCd.JAR]: 'JAR',
  [CommodityPackageCd.JERRYCAN]: 'JER',
  [CommodityPackageCd.JUG]: 'JUG',
  [CommodityPackageCd.KEG]: 'KEG',
  [CommodityPackageCd.KIT]: 'KIT',
  [CommodityPackageCd.KNOCKDOWN_RACK]: 'KRK',
  [CommodityPackageCd.KNOCKDOWN_TOTE_BIN]: 'KTB',
  [CommodityPackageCd.LID_TOP]: 'LID',
  [CommodityPackageCd.LIFTS]: 'LIF',
  [CommodityPackageCd.LIFT_VAN]: 'LVN',
  [CommodityPackageCd.LINERS]: 'LNR',
  [CommodityPackageCd.LOG]: 'LOG',
  [CommodityPackageCd.LOOSE]: 'LSE',
  [CommodityPackageCd.LQD_BULK]: 'LBK',
  [CommodityPackageCd.LUG]: 'LUG',
  [CommodityPackageCd.MISCELLANEOUS]: 'MSC',
  [CommodityPackageCd.MIXED_CONTAINER]: 'MIX',
  [CommodityPackageCd.MULTI_RACK]: 'MRP',
  [CommodityPackageCd.NOIL]: 'NOL',
  [CommodityPackageCd.ON_OWN_WHEEL]: 'WHE',
  [CommodityPackageCd.PACKED]: 'PCK',
  [CommodityPackageCd.PAIL]: 'PAL',
  [CommodityPackageCd.PALLET]: 'PLT',
  [CommodityPackageCd.PALLET_2_WAY]: 'PAT',
  [CommodityPackageCd.PALLET_4_WAY]: 'PAF',
  [CommodityPackageCd.PARTIONING]: 'PRT',
  [CommodityPackageCd.PCS]: 'PCS',
  [CommodityPackageCd.PIMS]: 'PIR',
  [CommodityPackageCd.PIPELINE]: 'PLN',
  [CommodityPackageCd.PIPE_RACK]: 'PRK',
  [CommodityPackageCd.PKG]: 'PKG',
  [CommodityPackageCd.PLATFORM]: 'PLF',
  [CommodityPackageCd.PRIMARY_LIFT_CONTAINER]: 'PLC',
  [CommodityPackageCd.PRIVATE_VEHCLE]: 'POV',
  [CommodityPackageCd.QUARTER_BEEF]: 'QTR',
  [CommodityPackageCd.RACK]: 'RCK',
  [CommodityPackageCd.RAIL_SEMICONDUCTOR]: 'RAL',
  [CommodityPackageCd.REEL]: 'REL',
  [CommodityPackageCd.REINFORCEMNT]: 'RFT',
  [CommodityPackageCd.REVERSE_REEL]: 'RVR',
  [CommodityPackageCd.ROLL]: 'ROL',
  [CommodityPackageCd.SACK]: 'SAK',
  [CommodityPackageCd.SEPARATOR]: 'SPR',
  [CommodityPackageCd.SHEET]: 'SHT',
  [CommodityPackageCd.SHOOK]: 'SHK',
  [CommodityPackageCd.SHRINK_WRAP]: 'SHW',
  [CommodityPackageCd.SIDE_OF_BEEF]: 'SID',
  [CommodityPackageCd.SKID]: 'SKD',
  [CommodityPackageCd.SKID_ELEVATE]: 'SKE',
  [CommodityPackageCd.SLEEVE]: 'SLV',
  [CommodityPackageCd.SLIP_SHEET]: 'SLP',
  [CommodityPackageCd.SPIN_CYLINDERS]: 'SPI',
  [CommodityPackageCd.SPOOL]: 'SPL',
  [CommodityPackageCd.TANK]: 'TNK',
  [CommodityPackageCd.TANK_CAR]: 'TKR',
  [CommodityPackageCd.TANK_TRUCK]: 'TKT',
  [CommodityPackageCd.TIERCE]: 'TRC',
  [CommodityPackageCd.TOTE_BIN]: 'TBN',
  [CommodityPackageCd.TRAILER_CONTAINER_LOAD]: 'TLD',
  [CommodityPackageCd.TRAY]: 'TRY',
  [CommodityPackageCd.TRUNK_CHEST]: 'TRK',
  [CommodityPackageCd.TRUNK_SAMPLE]: 'TSS',
  [CommodityPackageCd.TUB]: 'TUB',
  [CommodityPackageCd.TUBE]: 'TBE',
  [CommodityPackageCd.UNIT]: 'UNT',
  [CommodityPackageCd.UNPACKED]: 'UNP',
  [CommodityPackageCd.VAN_PACK]: 'VPK',
  [CommodityPackageCd.VEHICLES]: 'VEH',
  [CommodityPackageCd.WHEELED_CAR]: 'WLC',
  [CommodityPackageCd.WRAPPED]: 'WRP',
};
