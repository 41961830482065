import { Injectable } from '@angular/core';
import { AccountUrls } from '@shared/enums/account-urls.enum';
import { ConfigManagerProperties } from '@shared/enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(
    public loggedInUserService: XpoLtlLoggedInUserService,
    public configManagerService: ConfigManagerService
  ) {}

  getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  getUser(): Observable<User> {
    return this.loggedInUserService.getLoggedInUser(
      this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot)
    );
  }
}
