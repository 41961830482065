import { WhatIfRatingRequest } from '@xpo-ltl-2.0/sdk-rating';
import { WhatIfShipmentSelectionTypeCd } from '@xpo-ltl/sdk-common';

export interface RequestStatus {
  [key: string]: { key: string; text: string; color: string };
}

export enum whatIfRequestsStatusKey {
  Submitted = 'Submitted',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
  OnHold = 'On Hold',
}

export const whatIfRequestsStatus: RequestStatus = {
  [whatIfRequestsStatusKey.Submitted]: {
    key: whatIfRequestsStatusKey.Submitted,
    text: 'Submitted',
    color: 'grey',
  },
  [whatIfRequestsStatusKey.InProgress]: {
    key: whatIfRequestsStatusKey.InProgress,
    text: 'In Progress',
    color: 'blue',
  },
  [whatIfRequestsStatusKey.Completed]: {
    key: whatIfRequestsStatusKey.Completed,
    text: 'Completed',
    color: '#4CAF50',
  },
  [whatIfRequestsStatusKey.Failed]: {
    key: whatIfRequestsStatusKey.Failed,
    text: 'Failed',
    color: 'red',
  },
  [whatIfRequestsStatusKey.OnHold]: {
    key: whatIfRequestsStatusKey.OnHold,
    text: 'On Hold',
    color: '#FF6D00',
  },
};

export const whatIfRequestsShipmentSelection = {
  [WhatIfShipmentSelectionTypeCd.ACCOUNT]: {
    text: 'Account',
  },
  [WhatIfShipmentSelectionTypeCd.XPO_SHIPMENT]: {
    text: 'XPO Shipment',
  },
  [WhatIfShipmentSelectionTypeCd.AGREEMENT_ID]: {
    text: 'Agreement',
  },
  [WhatIfShipmentSelectionTypeCd.CUSTOMER_SHIPMENT]: {
    text: 'Customer Shipment',
  },
};

export interface WhatIfRequests extends Omit<WhatIfRatingRequest, 'requestDateTime'> {
  agreementId?: string;
  agreementIdOverride?: string;
  requestDateTime: number;
}

export interface WhatIfRequestGrid extends WhatIfRequests {
  accountOrAgreement: string | number;
}

export interface WhatIfRequestsAgrements {
  id: string;
  effective: string;
  expiry: string;
  reneg?: string;
  agreementInstId: number;
}
