<h1 mat-dialog-title class="info-dialog" id="title">
  <span class="info-dialog__title">
    <xpo-icon [iconName]="infoData.type" xpoIconLarge></xpo-icon>
    {{ infoData.title }}
  </span>
  <xpo-dialog-close-button *ngIf="!infoData.hideCloseOption"></xpo-dialog-close-button>
</h1>

<main mat-dialog-content class="info-dialog__content">
  <div class="info-dialog__content__message" id="contentMessage">
    <p *ngIf="infoData.message">{{ infoData.message }}</p>
    <ng-container *ngIf="infoData.template" [ngTemplateOutlet]="infoData.template"></ng-container>
  </div>
</main>

<div mat-dialog-actions [ngStyle]="{ 'justify-content': getButtonAlignment() }">
  <button mat-flat-button xpoButton (click)="close()" id="closeBtn">{{ getButtonText() }}</button>
</div>
