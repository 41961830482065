import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateFormats, DateRatingUtils } from '@shared/utils/dates/date.utils';

@Pipe({
  name: 'dateXpo'
})
export class DateXpoPipe implements PipeTransform {

  constructor(private angularDatePipe: DatePipe) {}

  private transformNumber(dateTime: number): string {
    if (dateTime == null) return '';
    return DateRatingUtils.getDateFromTimeStamp(dateTime, DateFormats.monthDayYearDashMoment);
  }

  private transformString(date: string): string | null {
    if (!date) return '';
    return this.angularDatePipe.transform(date, DateFormats.monthDayYearDash);
  }

  transform(date: string | number): string | null {
    return typeof date === 'number' ? this.transformNumber(date) : this.transformString(date);
  }

}
