export enum SendEmailDialogFormNames {
  emailBody = 'emailBody',
  emailList = 'emailList',
  email = 'email',
  sendMeCopy = 'sendMeCopy',
}

export const sendEmailDialogFormIds = {
  [SendEmailDialogFormNames.email]: 'emailField',
  [SendEmailDialogFormNames.emailBody]: 'emailBodyField',
  [SendEmailDialogFormNames.sendMeCopy]: 'sendMeCopyField',
};

export const sendEmailDialogFormErrorIds = {
  [SendEmailDialogFormNames.emailList]: 'emailListError',
  [SendEmailDialogFormNames.email]: 'emailFieldError',
  [SendEmailDialogFormNames.sendMeCopy]: 'sendMeCopyFieldError',
};
