import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

export type SelectHeaders = { viewValue: string };

@Component({
  selector: 'app-select-header',
  templateUrl: './select-header.component.html',
  styleUrls: ['./select-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectHeaderComponent {
  @Input() idTitle = 'selectHeaderTitle';
  @Input() idList = 'selectHeaderList';
  @Input() items: SelectHeaders[] = [];
  @Input() defaultIdValue: string;

  @Output() selectedIdValueItem = new EventEmitter<string>();

  constructor(public cdr: ChangeDetectorRef) {}

  selectItem(selectedIdValue: string): void {
    this.selectedIdValueItem.emit(selectedIdValue);
  }
}
