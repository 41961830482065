import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/persistence/local-storage/local-storage.service';
import { GetSicForPostalCodesResp, PostalSicAssignment } from '@xpo-ltl-2.0/sdk-location';
import { LocationStorageEnum } from './location-storage.enum';
import { SicForPostalCodesStorage } from './location-storage.model';

@Injectable({
  providedIn: 'root'
})
export class LocationStorageService {

  constructor(private localStorageService: LocalStorageService) { }

  saveSicForPostalCodes(postalSicAssignments: PostalSicAssignment[]): void {
    if (!postalSicAssignments || postalSicAssignments.length === 0) { return; }
    const sicForPostalCodesString: string = this.localStorageService.getData(LocationStorageEnum.sicForPostalCodesKey);
    const sicForPostalCodesStoraged: SicForPostalCodesStorage = sicForPostalCodesString ? JSON.parse(sicForPostalCodesString) : {};

    postalSicAssignments.forEach((postalCodeSic: PostalSicAssignment) =>
      sicForPostalCodesStoraged[postalCodeSic.postalCd] = postalCodeSic
    );

    const responseString = JSON.stringify(sicForPostalCodesStoraged);
    this.localStorageService.saveData(LocationStorageEnum.sicForPostalCodesKey, responseString);
  }

  getSicForPostalCodesFiltered(postalCds: string[] = []): GetSicForPostalCodesResp {
    const response: string = this.localStorageService.getData(LocationStorageEnum.sicForPostalCodesKey);
    if (!response || postalCds.length === 0) { return; }

    const sicForPostalCodesStoraged: SicForPostalCodesStorage = JSON.parse(response);
    const getSicForPostalCodes = new GetSicForPostalCodesResp();
    getSicForPostalCodes.postalSicAssignments = [];

    for (const postalCd of postalCds) {
      if (sicForPostalCodesStoraged[postalCd]) {
        getSicForPostalCodes.postalSicAssignments.push(sicForPostalCodesStoraged[postalCd]);
      }
    }

    return getSicForPostalCodes.postalSicAssignments.length === postalCds.length ? getSicForPostalCodes : undefined;
  }
}
