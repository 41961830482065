import { ErrorModel } from '@shared/models/error';
import { WhatIfRequests, WhatIfRequestsAgrements } from '@shared/models/what-if-requests';
import { createAction, props } from '@ngrx/store';
import { CustomerMatch } from '@xpo-ltl-2.0/sdk-customer';
import { CreateWhatIfRatingResp, CreateWhatIfRatingRqst, WhatIfRatingRequester } from '@xpo-ltl-2.0/sdk-rating';
import { RatingShipmentFileTypeCd, WhatIfDownloadFileTypeCd } from '@xpo-ltl/sdk-common';

export const whatIfRequestsClearStore = createAction('[WhatIfRequests] Clear Store');

export const whatIfRequestsGetAll = createAction(
  '[WhatIfRequests] Get all',
  props<{ selectedUser: WhatIfRatingRequester }>()
);
export const whatIfRequestsGetAllSuccess = createAction(
  '[WhatIfRequests] Get all success',
  props<{ whatIfRequestsList: WhatIfRequests[] }>()
);
export const whatIfRequestsGetAllFail = createAction('[WhatIfRequests] Get all fail');

export const whatIfRequestsAdd = createAction(
  '[WhatIfRequests] Add',
  props<{ whatIfRequest: Partial<CreateWhatIfRatingRqst> }>()
);
export const whatIfRequestsAddSuccess = createAction(
  '[WhatIfRequests] Add success',
  props<{ whatIfRequest: CreateWhatIfRatingResp }>()
);
export const whatIfRequestsAddFail = createAction('[WhatIfRequests] Add fail', props<{ error: ErrorModel[] }>());

export const whatIfRequestsDelete = createAction('[WhatIfRequests] Delete', props<{ ratingRequestIds: number[] }>());
export const whatIfRequestsDeleteSuccess = createAction('[WhatIfRequests] Delete success');
export const whatIfRequestsDeleteFail = createAction('[WhatIfRequests] Delete fail', props<{ errors: ErrorModel[] }>());

export const whatIfGetUsersRequesters = createAction(
  '[WhatIfRequests] Get Users Requesters',
  props<{ defaultUser: WhatIfRatingRequester }>()
);
export const whatIfGetUsersRequestersSuccess = createAction(
  '[WhatIfRequests] Get Users Requesters success',
  props<{ usersRequesters: WhatIfRatingRequester[] }>()
);
export const whatIfGetUsersRequestersFail = createAction('[WhatIfRequests] Get Users Requesters fail');

export const whatIfGetAccountsFiltered = createAction(
  '[WhatIfRequestsAccounts] Get filtered',
  props<{ filterData: string }>()
);
export const whatIfGetAccountsFilteredSuccess = createAction(
  '[WhatIfRequestsAccounts] Get filtered Success',
  props<{ accounts: CustomerMatch[] }>()
);
export const whatIfGetAccountsFilteredFail = createAction(
  '[WhatIfRequestsAccounts] Get filtered Fail',
  props<{ errors: ErrorModel[] }>()
);

export const whatIfGetAccountsOverrideFiltered = createAction(
  '[whatIfGetAccountsOverrideFiltered] Get filtered',
  props<{ filterData: string }>()
);
export const whatIfGetAccountsOverrideFilteredSuccess = createAction(
  '[whatIfGetAccountsOverrideFiltered] Get filtered Success',
  props<{ accounts: CustomerMatch[] }>()
);
export const whatIfGetAccountsOverrideFilteredFail = createAction(
  '[whatIfGetAccountsOverrideFiltered] Get filtered Fail',
  props<{ errors: ErrorModel[] }>()
);

export const whatIfGetAgreementIdFiltered = createAction(
  '[WhatIfRequestsAgreementId] Get filtered',
  props<{ filterData: number; accountValue: CustomerMatch }>()
);
export const whatIfGetAgreementIdFilteredSuccess = createAction(
  '[WhatIfRequestsAgreementId] Get filtered Success',
  props<{ agreements: WhatIfRequestsAgrements[] }>()
);
export const whatIfGetAgreementIdFilteredFail = createAction(
  '[WhatIfRequestsAgreementId] Get filtered Fail',
  props<{ errorMessage: string }>()
);

export const whatIfGetAgreementIdFilteredOverride = createAction(
  '[WhatIfRequestsAgreementIdOverride] Get filtered',
  props<{ filterData: number; accountValue: CustomerMatch }>()
);
export const whatIfGetAgreementIdFilteredOverrideSuccess = createAction(
  '[WhatIfRequestsAgreementIdOverride] Get filtered Success',
  props<{ agreements: WhatIfRequestsAgrements[] }>()
);
export const whatIfGetAgreementIdFilteredOverrideFail = createAction(
  '[WhatIfRequestsAgreementIdOverride] Get filtered Fail',
  props<{ errorMessage: string }>()
);

export const whatIfRequestsGetDetail = createAction(
  '[whatIfRequestsGetDetail] Get what if request detail',
  props<{ ratingRequestId: number }>()
);

export const whatIfRequestsDownloadShipmentFile = createAction(
  '[whatIfRequestsDownloadShipmentFile] Download what if request shipment file',
  props<{ fileId: string; whatIfDownloadFileTypeCd: WhatIfDownloadFileTypeCd; whatIfRequests: WhatIfRequests }>()
);
export const whatIfRequestsDownloadShipmentFileSuccess = createAction(
  '[whatIfRequestsDownloadShipmentFile] Download what if request shipment file success',
  props<{ fileData: string; whatIfRequests: WhatIfRequests }>()
);

export const whatIfRequestsDownloadAnalysisResultSuccess = createAction(
  '[whatIfRequestsDownloadShipmentFile] Download what if request analysis result success',
  props<{ fileData: string; whatIfRequests: WhatIfRequests }>()
);

export const whatIfRequestsDownloadShipmentFileFail = createAction(
  '[whatIfRequestsDownloadShipmentFile] Download what if request shipment file Fail',
  props<{ errors: ErrorModel[] }>()
);

export const whatIfRequestsDownloadShipmentTemplate = createAction(
  '[whatIfRequestsDownloadShipmentTemplate] Download what if request shipment fileTemplate ',
  props<{ templateFileTypeCd: RatingShipmentFileTypeCd }>()
);
export const whatIfRequestsDownloadShipmentTemplateSuccess = createAction(
  '[whatIfRequestsDownloadShipmentTemplate] Download what if request shipment file Template success',
  props<{ fileData: string }>()
);
export const whatIfRequestsDownloadShipmentTemplateFail = createAction(
  '[whatIfRequestsDownloadShipmentTemplate] Download what if request shipment file Template Fail',
  props<{ errors: ErrorModel[] }>()
);

export const whatIfSetRequestsOnGrid = createAction(
  '[whatIfSetRequestsOnGrid] Set the requests that are in the grid taking into account the filters and sort',
  props<{ requestIds: number[] }>()
);

export const whatIfRequestsGetNext = createAction(
  '[whatIfRequestsGetNext] Get next request on board',
  props<{ currentRequestId: number }>()
);
