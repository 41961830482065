import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RatingAnalysisDetail } from '@shared/models/rating-analysis';
import { map } from 'rxjs/operators';
import { RatingAnalysisActions } from '../actions/rating-analysis.actions';
import { RatingAnalysisSelectors } from '../selectors/rating-analysis.selector';

@Injectable({
  providedIn: 'root',
})
export class RatingAnalysisHandlerService {
  // ACTIONS
  getProNumberDetailsSuccess$ = this.actions$.pipe(
    ofType(RatingAnalysisActions.getProNumberDetailsSuccess),
    map((action) => action.proNumberDetailList)
  );

  getProNumberDetailsFail$ = this.actions$.pipe(
    ofType(RatingAnalysisActions.getProNumberDetailsFail),
    map((action) => action.error)
  );

  getProNumberDetailBreakdownSuccess$ = this.actions$.pipe(
    ofType(RatingAnalysisActions.getProNumberDetailBreakdownSuccess),
    map((action) => action.ratingAnalysisDetailBreakDown)
  );

  getProNumberDetailBreakdownFail$ = this.actions$.pipe(
    ofType(RatingAnalysisActions.getProNumberDetailBreakdownFail),
    map((action) => action.error)
  );

  getPricingAgreementSuccess$ = this.actions$.pipe(
    ofType(RatingAnalysisActions.getPricingAgreementSuccess),
    map((action) => action.ratingAnalysisPricingAgreement)
  );

  // SELECTORS
  getSelectedProNumberDetailSelector$ = this.store$.pipe(select(RatingAnalysisSelectors.getSelectedProNumberDetail));
  loadingGetProNumberDetailBreakdown$ = this.store$.pipe(
    select(RatingAnalysisSelectors.loadingGetProNumberDetailBreakdown)
  );

  constructor(public actions$: Actions, public store$: Store) {}

  getProNumberDetails(proNumber: string): void {
    this.store$.dispatch(RatingAnalysisActions.getProNumberDetails({ proNumber }));
  }

  getProNumberDetailBreakdown(shipmentInstId: number, effectiveDateTime: Date): void {
    this.store$.dispatch(RatingAnalysisActions.getProNumberDetailBreakdown({ shipmentInstId, effectiveDateTime }));
  }

  getPricingAgreement(agreementInstIdList: number[]): void {
    this.store$.dispatch(RatingAnalysisActions.getPricingAgreement({ agreementInstIdList }));
  }

  clearRatingAnalysisStore(): void {
    this.store$.dispatch(RatingAnalysisActions.clearRatingAnalysisStore());
  }

  setSelectedProNumberDetail(selectedProNumberDetail: RatingAnalysisDetail): void {
    this.store$.dispatch(RatingAnalysisActions.setSelectedProNumberDetail({ selectedProNumberDetail }));
  }
}
