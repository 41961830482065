export enum ConfigManagerProperties {
  buildVersion = 'buildVersion',
  production = 'production',
  apiUrl = 'apiUrl',
  scopeOptions = 'scopeOptions',
  secretToken = 'secretToken',
  appName = 'appName',
  region = 'region',
  loggedInUserRoot = 'loggedInUserRoot',
  userContextPath = 'userContextPath',
  appMetadataEndpoint = 'appMetadataEndpoint',
  bolApiEndpoint = 'bolApiEndpoint',
  customerLocationApiEndpoint = 'customerLocationApiEndpoint',
  elasticSearchApiEndpoint = 'elasticSearchApiEndpoint',
  freightFlowApiEndpoint = 'freightFlowApiEndpoint',
  infrastructureApiEndpoint = 'infrastructureApiEndpoint',
  invoiceApiEndpoint = 'invoiceApiEndpoint',
  locationApiEndpoint = 'locationApiEndpoint',
  loggingApiEndpoint = 'loggingApiEndpoint',
  membershipApiEndpoint = 'membershipApiEndpoint',
  pricingApiEndpoint = 'pricingApiEndpoint',
  shipmentApiEndpoint = 'shipmentApiEndpoint',
  userpreferenceApiEndpoint = 'userpreferenceApiEndpoint',
  errorToastDuration = 'errorToastDuration',
  successToastDuration = 'successToastDuration',
  feedbackToAddress = 'feedbackToAddress',
  feedbackSubject = 'feedbackSubject',
  ratingEdgeApps = 'ratingEdgeApps',
  navigatorUrl = 'navigatorUrl',
  regionUrl = 'regionUrl',
  csShipmentRegionUrl = 'csShipmentRegionUrl',
  ratingRulesetDetailsEndpoint = 'ratingRulesetDetailsEndpoint',
  pricingLegalDocsEndpoint = 'pricingLegalDocsEndpoint',
  freightBillCopyEndpoint = 'freightBillCopyEndpoint',
  pickupRequestEndpoint = 'pickupRequestEndpoint',
  spotQuoteEndpoint = 'spotQuoteEndpoint',
  zipQEndpoint = 'zipQEndpoint',
  edgeInvoicingUrl = 'edgeInvoicingUrl',
  schedulePickupUrl = 'schedulePickupUrl',
  tariffMgmtRegionUrl = 'tariffMgmtRegionUrl',
  densityClassCalculatorWebUrl = 'densityClassCalculatorWebUrl',
  turnOffRateQuote = 'turnOffRateQuote',
  turnOffManualRating = 'turnOffManualRating',
  turnOffRatingAnalysis = 'turnOffRatingAnalysis',
  turnOffMrra = 'turnOffMrra',
  ratingGriEffDate = 'ratingGriEffDate',
}
