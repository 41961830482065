import { Injectable } from '@angular/core';
import { DataOptions } from '@xpo-ltl/data-api';
import { Envelope } from '@xpo-ltl/sdk-common';
import {
  ListTariffsResp,
  ListTariffsRqst,
  ListZoneCitiesResp,
  Tariff,
  TariffManagementApiService
} from '@xpo-ltl/sdk-tariffmanagement';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UtilsRequestsService } from '../utils/utils-requests.service';

@Injectable({
  providedIn: 'root',
})
export class TariffService {
  constructor(
    public tariffManagementApiService: TariffManagementApiService,
    public utilsRequestsService: UtilsRequestsService
  ) { }

  getTariffs(): Observable<Tariff[]> {
    const request = new ListTariffsRqst();
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };

    return this.tariffManagementApiService.listTariffs(request, dataOptions).pipe(
      map((response: ListTariffsResp) => response.tariffs),
      catchError((response: Envelope<ListTariffsResp>) => {
        console.error(response);
        return throwError(
          this.utilsRequestsService.handleError<ListTariffsResp>(
            response
          )
        );
      })
    );
  }

  getZoneCities(dataOptions: DataOptions = { loadingOverlayEnabled: false }): Observable<ListZoneCitiesResp> {
    return this.tariffManagementApiService.listZoneCities(dataOptions).pipe(
      catchError((response: Envelope<ListZoneCitiesResp>) => {
        console.error(response);
        return throwError(
          this.utilsRequestsService.handleError<ListZoneCitiesResp>(
            response
          )
        );
      })
    );
  }
}
