import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { tap } from 'rxjs/operators';

export const trayCustomStyles = {
  mid: { 'max-width': '780px' },
  big: { 'max-width': '980px' },
  large: { 'max-width': '1280px' },
};

export interface TrayTemplate {
  title: string;
  content: TemplateRef<any>;
  headerItems?: TemplateRef<any>;
  footer?: TemplateRef<any>;
  closeActions: Function;
  trayCustomStyles?: any;
  disableClose?: boolean;
  clickBackDropAction?: Function;
}

@Component({
  selector: 'app-tray',
  templateUrl: './tray.component.html',
  styleUrls: ['./tray.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrayComponent {
  @ViewChild('fullTray') drawerContainer: MatDrawerContainer;

  contentComponent: TrayTemplate;
  @Input() hasBackdrop: boolean;
  @Input() extended: boolean;

  constructor(public cdr: ChangeDetectorRef) {}

  openTray(): void {
    this.drawerContainer.open();
    this.cdr.detectChanges();
  }

  close(): void {
    this.drawerContainer.close();
    this.cdr.detectChanges();
  }

  getStyle(): string {
    return this.contentComponent?.trayCustomStyles;
  }

  closedStart(): void {
    this.contentComponent.closeActions();
  }

  backdropClick(): void {
    this.contentComponent.clickBackDropAction &&
      this.contentComponent
        .clickBackDropAction()
        .pipe(tap((close: boolean) => close && this.close()))
        .subscribe();
  }
}
