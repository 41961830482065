import { Injectable } from '@angular/core';
import { MexicoCitiesMapperService } from '@core/mappers/mexico-cities/mexico-cities-mapper.service';
import { TariffService } from '@core/services/tariff/tariff.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorModel } from '@shared/models/error';
import { ListZoneCitiesResp, Tariff } from '@xpo-ltl/sdk-tariffmanagement';
import { TransformData } from 'app/business/utils/transform-data/transform-data.utils';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { TariffManagementActions } from '../actions/tariff-management.actions';

@Injectable()
export class TariffManagementEffects {
  getTariffs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TariffManagementActions.getTariff),
      exhaustMap(() => {
        return this.tariffService.getTariffs().pipe(
          map((tariffs: Tariff[]) => {
            const tariffList: Tariff[] = TransformData.sortTariffByName(tariffs);
            return TariffManagementActions.getTariffSuccess({ tariffList });
          }),
          catchError((errors: ErrorModel[]) => of(TariffManagementActions.getTariffFail({ errors })))
        );
      })
    )
  );

  getMexicoCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TariffManagementActions.getMexicoCities),
      exhaustMap(() => {
        return this.tariffService.getZoneCities().pipe(
          map((listZoneCitiesResp: ListZoneCitiesResp) => {
            const mexicoCities = this.mexicoCitiesMapperService.getCities(listZoneCitiesResp);
            return TariffManagementActions.getMexicoCitiesSuccess({ mexicoCities })
          }
          ),
          catchError((errors: ErrorModel[]) => of(TariffManagementActions.getMexicoCitiesFail({ errors })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    public tariffService: TariffService,
    private mexicoCitiesMapperService: MexicoCitiesMapperService
  ) { }
}
