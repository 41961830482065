import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { WhatIfRequestsAgrements } from '@shared/models/what-if-requests';
import { WhatIfRequestsRules } from 'app/business/what-if/requests/what-if-requests-rules';
import { Observable, of } from 'rxjs';
import { FormFieldAutocompleteParentComponent } from '../form-field-autocomplete-parent-component';

@Component({
  selector: 'app-agreement-form-field',
  templateUrl: './agreement-form-field.component.html',
  styleUrls: ['./agreement-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgreementFormFieldComponent extends FormFieldAutocompleteParentComponent implements OnInit, OnDestroy {
  @Input() labelName: string = 'Agreement';
  @Input() idField = 'agreement';
  @Input() fieldLabelId = 'agreementLabel';
  @Input() errorId = 'agreementError';
  @Input() errorMessage: string;
  @Input() placeHolder: string;
  @Input() itemList$ = of();
  @Input() loading$: Observable<boolean>;
  @Input() displayPlaceHolderLoading: boolean; // Optional, by default is false, if the attribute is false the component doesn't display the placeHolderLoading when is loading
  @Input() setFocusAfterEnable: boolean = true; // Optional, by default is true, if the attribute is false the component doesn't apply the focus once the enable method is applied

  constructor(public cdr: ChangeDetectorRef, @Optional() @Self() public ngControl: NgControl) {
    super(ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.listenChangeValue();
    this.inheritValidators();
    this.enableOrDisableField();
    this.enableOrDisableWhenIsLoading();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  // FORM METHODS
  enableOrDisableWhenIsLoading() {
    super.enableOrDisableWhenIsLoading(this.loading$, this.displayPlaceHolderLoading);
  }

  setFocus(): void {
    if (this.setFocusAfterEnable) {
      document.getElementById(this.idField)?.focus();
    }
  }

  displayAgreementDetails(agreementId: WhatIfRequestsAgrements): boolean {
    return !WhatIfRequestsRules.isAgreementIdEmpty([agreementId]);
  }

  // NGFOR METHODS
  trackByAgreementId(index: number, agreement: WhatIfRequestsAgrements): string {
    return agreement.id;
  }
}
