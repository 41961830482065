import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { SharedActions } from '../actions/shared.actions';

export const sharedFeatureKey = 'shared';
export const sharedFeatureSelector = createFeatureSelector<SharedState>(sharedFeatureKey);

export interface SharedState {
  previousUrl: string;
}

export const sharedInitState: SharedState = {
  previousUrl: undefined,
};

export const reducer = createReducer(
  sharedInitState,
  on(SharedActions.setPreviousUrl, (state, { previousUrl }) => {
    return {
      ...state,
      previousUrl,
    };
  })
);
