import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { JustifyContent } from '@shared/enums/css-classes.enum';
import { InfoStatus } from '@shared/enums/status.enum';
import { InfoDialogInterface } from '@shared/models/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogComponent {
  @Input() infoData: InfoDialogInterface;
  @Output() confirmationBtn = new EventEmitter<void>();

  componentType = InfoStatus;

  constructor(private dialogRef: MatDialogRef<InfoDialogComponent>) {}

  close(): void {
    this.confirmationBtn.emit();
    this.dialogRef.close();
  }

  getButtonText(): string {
    return this.infoData.buttonText || 'Close';
  }

  getButtonAlignment(): string {
    return this.infoData.buttonAlignment || JustifyContent.start;
  }
}
