export enum SnackbarStatus {
  error = 'error',
  success = 'success',
  warn = 'warn',
  warning = 'warn',
}

export enum ConfirmDialogStatus {
  warning = 'warning',
}

export enum InfoStatus {
  error = 'error',
  info = 'info',
}

export enum XflowItemStatus {
  approved = 'APPROVED',
  unassignItem = 'UNASSIGN_ITEM',
  underReview = 'UNDER_REVIEW',
}
