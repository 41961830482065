<pdf-viewer
  #pdf
  [src]="pdfSrc"
  [render-text]="renderText"
  [original-size]="originalSize"
  [fit-to-page]="fitToPage"
  [zoom]="zoom"
  [(page)]="pageVariable"
  (after-load-complete)="afterLoadComplete($event)"
  (text-layer-rendered)="textLayerRendered($event)"
  [style]="style"
>
</pdf-viewer>
