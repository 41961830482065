import { messagesConstant } from '@shared/constants/messages.constants';
import { CustomerMatch } from '@xpo-ltl-2.0/sdk-customer';
import { ValidationRegexPatterns } from '@xpo-ltl/common-services';
import { RegexRules } from '../utils/regex/regex';

export const allowFileExtensionsRegex = (fileName: string, extensions: string[]): boolean => {
  if (!fileName || !extensions?.length) {
    throw new Error(messagesConstant.regex.allowFileExtensionsRegex.empty);
  }
  return new RegExp('(' + extensions.join('|').replace(/\./g, '\\.') + ')$').test(fileName);
};

export const validateUsZipCodeRegex = (zipCode: string): boolean => {
  return new RegExp(ValidationRegexPatterns.usZipCode).test(zipCode);
};

export const validateCaZipCodeRegex = (zipCode: string): boolean => {
  return new RegExp(ValidationRegexPatterns.caPostalCode).test(zipCode);
};

export const validateMxZipCodeRegex = (zipCode: string): boolean => {
  return new RegExp(ValidationRegexPatterns.mxPostalCode).test(zipCode);
};

export const validateCurrencyMaxFiveCharacters = (currency: number): boolean => {
  return RegexRules.currencyMaxFiveCharacters.test(`${currency}`);
};

export const removeBlankSpaces = (text: string): string => {
  return text.replace(RegexRules.findBlankSpaces, '');
};

export const splitStringByUpperCase = (text: string): string[] => {
  if (!text) {
    return [''];
  }
  return text.split(RegexRules.upperCaseInString);
};

export const splitAndJoinStringByUpperCase = (text: string): string => {
  return splitStringByUpperCase(text).join(' ');
};

export const isAValidNmfc = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.nmfcValidationPattern).test(text);
};

export const validateFileNameWithExtension = (text: string): boolean => {
  return new RegExp(RegexRules.fileNameWithExtension).test(text);
};

export const validateAgreementWithHypen = (text: string): boolean => {
  return new RegExp(RegexRules.agreementWithHypen).test(text);
};

export const getAccInstIdFromAutoComplete = (customer: string | CustomerMatch): string => {
  if (typeof customer !== 'string') {
    return `${customer.customerNbr}`;
  }

  const accInstId: any[] = customer.match(RegexRules.accInstIdFromAutoComplete);
  return accInstId[0].replaceAll('-', '').trim();
};

export const removeHypens = (text: string): string => {
  return text.replace(RegexRules.getHypens, '');
};

export const excessiveValueChangeValidationRegex = (value: string | number): boolean => {
  return RegexRules.excessiveValueChange.test(`${value}`);
};

export const validateProNumberLength = (text: string): boolean => {
  return RegexRules.proNumberLength.test(text);
};

export const validateProNumberNineCharacters = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.nineDigitPro).test(text);
};

export const validateProNumberTenCharacters = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.tenDigitPro).test(text);
};

export const validateProNumberElevenCharacters = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.elevenDigitPro).test(text);
};

export const validateApiTechnicalCodeError = (text: string): boolean => {
  return new RegExp(RegexRules.apiTechnicalCodeError).test(text);
};
