import { Injectable } from '@angular/core';
import { LocationService } from '@core/services/location/location.service';
import { LocationStorageService } from '@core/services/location/persistence/location-storage.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorModel } from '@shared/models/error';
import { GetSicForPostalCodesResp } from '@xpo-ltl-2.0/sdk-location';
import { of } from 'rxjs';
import { catchError, map, mergeMap, delay } from 'rxjs/operators';
import { LocationActions } from '../actions/location.actions';

@Injectable()
export class LocationEffects {
  getSicForPostalCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.getSicForPostalCodes),
      mergeMap(({ request }) => {
        // TODO: Enable or remove this functionality once we get an opinion from Ramnaresh Karnaty

        // // first check if the needed data is in the local storage in order to avoid to call the api to get the same result
        // const getSicForPostalCodesResp: GetSicForPostalCodesResp = this.locationStorageService.getSicForPostalCodesFiltered(request.postalCds);
        // if (getSicForPostalCodesResp) {
        //   return of(getSicForPostalCodesResp).pipe(
        //     delay(1), // the delay is necessary for the ngrx engine works properly
        //     map((getSicForPostalCodesResp: GetSicForPostalCodesResp) => LocationActions.getSicForPostalCodesSuccess({ getSicForPostalCodesResp }))
        //   );
        // }

        // if the needed data is not in the local storage it is necessary to call the location API
        return this.locationService.getSicForPostalCodes(request).pipe(
          map((getSicForPostalCodesResp: GetSicForPostalCodesResp) => {

            // TODO: Enable or remove this functionality once we get an opinion from Ramnaresh Karnaty
            // this.locationStorageService.saveSicForPostalCodes(getSicForPostalCodesResp.postalSicAssignments);

            return LocationActions.getSicForPostalCodesSuccess({ getSicForPostalCodesResp })
          }
          ),
          catchError((errors: ErrorModel[]) => of(LocationActions.getSicForPostalCodesFail({ errors })))
        );
      })
    )
  );

  constructor(private actions$: Actions, private locationService: LocationService, private locationStorageService: LocationStorageService) { }
}
