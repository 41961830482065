import { ApplicationRoles } from '@shared/models/user';
import { createAction, props } from '@ngrx/store';
import { User } from '@xpo-ltl/sdk-common';

const getUser = createAction('[User] Get user session data');
const getUserSuccess = createAction('[User] Get user session data Success', props<{ user: User }>());
const getUserFail = createAction('[User] Get user session data Fail');

const setRequiredRolesCurrentPage = createAction(
  '[Roles] Set Required current page roles',
  props<{ requiredRolesCurrentPage: ApplicationRoles }>()
);

export const UserActions = { getUser, getUserSuccess, getUserFail, setRequiredRolesCurrentPage };
