import { Injectable } from '@angular/core';
import { messagesConstant } from '@shared/constants/messages.constants';
import { ErrorModel } from '@shared/models/error';
import { Envelope, ListInfo, MoreInfo } from '@xpo-ltl/sdk-common';
import { validateApiTechnicalCodeError } from 'app/business/validations/regex.validations';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UtilsRequestsService {
  constructor() { }

  handleError<T>(
    errorEnvelope: Envelope<T>,
    generalErrorMessage: string = messagesConstant.general.serviceError
  ): ErrorModel[] {

    if (errorEnvelope?.code && validateApiTechnicalCodeError(errorEnvelope.code)) {
      return [{ message: messagesConstant.general.serviceError, code: errorEnvelope?.code }];
    }

    if (errorEnvelope?.error?.moreInfo?.length > 0) {
      return errorEnvelope.error.moreInfo.map((infoError: MoreInfo) => ({
        message: infoError.message,
        location: infoError.location,
        code: errorEnvelope?.code,
      }));
    }

    return [{ message: generalErrorMessage, code: errorEnvelope?.code }];
  }

  /**
   *
   * @param valueList
   * @param listInfo
   * @param functionToCall
   * @param functionParamsToCall put here all the parameters that are needed to call the recursive function except the startAt
   * @returns
   * the recursive function should receive startAt as the last parameter
   */
  pagination<T>(
    valueList: Observable<T[]>,
    listInfo: ListInfo,
    functionToCall: Function,
    ...functionParamsToCall: any
  ): Observable<T[]> {
    if (!listInfo) {
      return valueList;
    }
    const nextStartAt = listInfo.startAt + listInfo.numberOfRows;
    if (nextStartAt < listInfo.totalRowCount) {
      return zip(valueList, functionToCall(...functionParamsToCall, nextStartAt)).pipe(
        map((resp: any) => {
          return resp[0].concat(resp[1]);
        })
      );
    } else {
      return valueList;
    }
  }
}
