import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RatingPagesTitle } from '@shared/enums/app-title.enum';
import { ManteinanceEmailSubject } from '@shared/models/maintenance-page';
import { RatingEmails } from '@shared/models/rating';
import { XpoErrorPagesType } from '@xpo-ltl/ngx-ltl-core';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenancePageComponent implements OnInit {
  @Input() page: RatingPagesTitle;

  errorPagesType = XpoErrorPagesType;
  emailSupport = RatingEmails.ltlItratingTeam;
  emailSubject: string;

  constructor() { }

  ngOnInit(): void {
    this.getSubject();
  }

  reloadSite(): void {
    window.location.reload();
  }

  getSubject(): void {
    switch (this.page) {
      case RatingPagesTitle.rateQuote:
        this.emailSubject = ManteinanceEmailSubject.rateQuote;
        break;
      case RatingPagesTitle.ratingAnalysis:
        this.emailSubject = ManteinanceEmailSubject.ratingAnalysis;
        break;

      default:
        this.emailSubject = ManteinanceEmailSubject.rating;
        break;
    }
  }

  sendEmail(): string {
    return `mailto:${this.emailSupport}?subject=${this.emailSubject}`;
  }
}
