import { createFeatureSelector, createReducer } from '@ngrx/store';

export const infrastructureFeatureKey = 'infrastructure';
export const infrastructureFeatureSelector = createFeatureSelector<InfrastructureState>(infrastructureFeatureKey);

export interface InfrastructureState {}

export const rateQuoteInitialState: InfrastructureState = {};

export const reducer = createReducer(rateQuoteInitialState);
