import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { messagesConstant } from '@shared/constants/messages.constants';
import { SearchFormValidation } from '@shared/enums/search-form-validations.enum';
import { FormErrorMessage } from '@shared/models/form-errors';
import {
  validateCaZipCodeRegex,
  validateCurrencyMaxFiveCharacters,
  validateMxZipCodeRegex,
  validateProNumberElevenCharacters,
  validateProNumberLength,
  validateProNumberNineCharacters,
  validateProNumberTenCharacters,
  validateUsZipCodeRegex,
} from 'app/business/validations/regex.validations';

@Injectable({
  providedIn: 'root',
})
export class FormsValidatorsService {
  constructor() {}

  validateStartDate(endDateControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const startDate: Date = control.value;
      const endDate: Date = endDateControl.value;
      if (!startDate || !endDate) {
        return;
      }
      if (startDate > endDate) {
        return { invalidStartGratherThanEnd: true };
      }
      return undefined;
    };
  }

  validateMaxDateMonths(maxDate: number, message?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const validateDate = new Date();
      const selectedDate: Date = control.value?.toDate();

      validateDate.setMonth(validateDate.getMonth() + maxDate);
      validateDate.setHours(0, 0, 0, 0);

      if (selectedDate > validateDate) {
        return { invalidDate: true, errorMessage: message };
      }
      return undefined;
    };
  }

  validateMinDateMonths(minDate: number, message?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const validateDate = new Date();
      const selectedDate: Date = control.value?.toDate();

      validateDate.setMonth(validateDate.getMonth() - minDate);
      validateDate.setHours(0, 0, 0, 0);

      if (selectedDate < validateDate) {
        return { invalidDate: true, errorMessage: message };
      }
      return undefined;
    };
  }

  validateZipCodes(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return;
      }
      const zipCode: string = control.value;
      if (!validateUsZipCodeRegex(zipCode) && !validateCaZipCodeRegex(zipCode) && !validateMxZipCodeRegex(zipCode)) {
        return { invalidPostalCode: true };
      }
      return undefined;
    };
  }

  validateCurrency(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value == null || control.value == '') {
        return;
      }
      const currency: number = control.value;
      if (!validateCurrencyMaxFiveCharacters(currency)) {
        return { invalidCurrency: true };
      }
      return undefined;
    };
  }

  validateFn(validatorFunction: Function, validatorProperty: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value === null || control.value === '') {
        return;
      }
      if (!validatorFunction(control.value)) {
        return { [validatorProperty]: true };
      }
      return undefined;
    };
  }

  setSubmitError(form: FormGroup): void {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (!control.valid) {
        form.get(field).setErrors({ ...form.get(field).errors, submitErrors: true });
      }
    });
  }

  checkInvalidFieldWhenSubmitButtonIsClicked(fieldName: string, form: FormGroup): boolean {
    return form.controls[fieldName]?.errors?.submitErrors;
  }

  validateDuplicatedEmail(
    formEmailArray: FormArray,
    formName: string,
    errorMessage: string = messagesConstant.general.duplicatedEmail
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return;
      }

      let duplicatedTimes = 0;

      formEmailArray.controls.forEach((emailForm: FormGroup) => {
        if (emailForm.controls[formName].value === control.value) {
          duplicatedTimes++;
        }
      });

      return duplicatedTimes > 1 ? ({ errorMessage } as FormErrorMessage) : undefined;
    };
  }

  setProNumberValidations(proNumberLength: number, form: FormGroup, proNumberNameField: string): void {
    const newValidators = [];
    switch (proNumberLength) {
      case 9:
        newValidators.push(
          this.validateFn(
            validateProNumberNineCharacters,
            SearchFormValidation.proNumberNineValidation
          )
        );
        break;
      case 10:
        newValidators.push(
          this.validateFn(
            validateProNumberTenCharacters,
            SearchFormValidation.proNumberTenValidation
          )
        );
        break;
      case 11:
        newValidators.push(
          this.validateFn(
            validateProNumberElevenCharacters,
            SearchFormValidation.proNumberElevenValidation
          )
        );
        break;
      default:
        newValidators.push(
          this.validateFn(
            validateProNumberLength,
            SearchFormValidation.proNumberLengthValidation
          )
        );
        break;
    }
    form.get(proNumberNameField).setValidators(newValidators);
    form.get(proNumberNameField).updateValueAndValidity();
  }

  setDefaultProNumberValidations(form: FormGroup, proNumberFieldName: string): void {
    form.get(proNumberFieldName).setValidators(Validators.required);
    form.get(proNumberFieldName).updateValueAndValidity();
  }
}
