import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/error';
import { RatingAnalysisDetail } from '@shared/models/rating-analysis';
import {
  GetRatingInformationHistoryForShipmentResp,
  ListRatingInformationHistoryForShipmentResp,
} from '@xpo-ltl-2.0/sdk-rating';
import { GetPricingAgreementResp } from '@xpo-ltl/sdk-pricingagmtmgmt';

export abstract class RatingAnalysisActions {
  static clearRatingAnalysisStore = createAction('[RatingAnalysis] Clear Rating Analysis Store');

  static getProNumberDetails = createAction(
    '[RatingAnalysis] Get proNumber Detail List',
    props<{ proNumber: string }>()
  );
  static getProNumberDetailsSuccess = createAction(
    '[RatingAnalysis] Get proNumber Detail List Success',
    props<{
      proNumberDetailList: ListRatingInformationHistoryForShipmentResp;
    }>()
  );
  static getProNumberDetailsFail = createAction(
    '[RatingAnalysis] Get proNumber Detail List Fail',
    props<{ error: ErrorModel[] }>()
  );

  static getProNumberDetailBreakdown = createAction(
    '[RatingAnalysis] Get proNumber Detail Breakdown',
    props<{ shipmentInstId: number, effectiveDateTime: Date }>()
  );
  static getProNumberDetailBreakdownSuccess = createAction(
    '[RatingAnalysis] Get proNumber Detail Breakdown Success',
    props<{
      ratingAnalysisDetailBreakDown: GetRatingInformationHistoryForShipmentResp;
    }>()
  );
  static getProNumberDetailBreakdownFail = createAction(
    '[RatingAnalysis] Get proNumber Detail Breakdown Fail',
    props<{ error: ErrorModel[] }>()
  );

  static getPricingAgreement = createAction(
    '[RatingAnalysis] Get Pricing Agreement',
    props<{ agreementInstIdList: number[] }>()
  );
  static getPricingAgreementSuccess = createAction(
    '[RatingAnalysis] Get Pricing Agreement Success',
    props<{
      ratingAnalysisPricingAgreement: GetPricingAgreementResp[];
    }>()
  );
  static getPricingAgreementFail = createAction(
    '[RatingAnalysis] Get Pricing Agreement Fail',
    props<{ error: ErrorModel[] }>()
  );

  static setSelectedProNumberDetail = createAction(
    '[RatingAnalysis] Set selected proNumber Detail',
    props<{ selectedProNumberDetail: RatingAnalysisDetail }>()
  );
}
