import { ShipmentPickupRequest } from '@xpo-ltl-2.0/sdk-rating';

export const excessiveLengthEmptyValue = 'Select a length';
export const excessiveLengthListValues: (string | number)[] = [
  excessiveLengthEmptyValue,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
];

export enum FileExtensions {
  pdf = 'pdf',
  csv = 'csv',
}

export const postalCodes = {
  usCountry: {
    countryCd: 'US',
  },
  usHawaii: {
    countryCd: 'US',
    stateCoude: 'HI',
  },
  usPuertoRico: {
    countryCd: 'US',
    stateCoude: 'PR',
  },
  usAlaska: {
    countryCd: 'US',
    stateCoude: 'AK',
  },
  mexicoCountry: {
    countryCd: 'MX'
  }
};

export class ShipmentPickupRatingRequests extends ShipmentPickupRequest {
  originSic: string;
  destSic: string;
}

export enum RatingEmails {
  ltlWebSupport = 'LTLWebSupport@xpo.com',
  LtlPuertoRico = 'LTL.PuertoRico@xpo.com',
  LtlHawaii = 'LTLHawaii@xpo.com',
  LtlAlaska = 'LTLAlaska@xpo.com',
  ltlItratingTeam = 'ltlitratingteam@xpo.com',
  ltlMexico = 'LTL.Mexico@xpo.com',
}

export interface MailtoUtils {
  email: string;
  subject: string;
  body?: string;
}

export enum SpecialClassNames {
  _50 = '50.0',
  _55 = '55.0',
  _60 = '60.0',
  _65 = '65.0',
  _70 = '70.0',
  _775 = '775',
  _85 = '85.0',
  _925 = '925',
  _100 = '100.0',
  _110 = '110.0',
  _125 = '125.0',
  _150 = '150.0',
  _175 = '175.0',
  _200 = '200.0',
  _250 = '250.0',
  _300 = '300.0',
  _350 = '350.0',
  _400 = '400.0',
  _500 = '500.0',
}

export const SpecialClassValues = {
  [SpecialClassNames._50]: '50',
  [SpecialClassNames._55]: '55',
  [SpecialClassNames._60]: '60',
  [SpecialClassNames._65]: '65',
  [SpecialClassNames._70]: '70',
  [SpecialClassNames._775]: '77.5',
  [SpecialClassNames._85]: '85',
  [SpecialClassNames._925]: '92.5',
  [SpecialClassNames._100]: '100',
  [SpecialClassNames._110]: '110',
  [SpecialClassNames._125]: '125',
  [SpecialClassNames._150]: '150',
  [SpecialClassNames._175]: '175',
  [SpecialClassNames._200]: '200',
  [SpecialClassNames._250]: '250',
  [SpecialClassNames._300]: '300',
  [SpecialClassNames._350]: '350',
  [SpecialClassNames._400]: '400',
  [SpecialClassNames._500]: '500',
};

export enum ShipmentRatingInfoPassTypeCd {
  fscTariff = '1',
}
