import { ApplicationRoles } from '@shared/models/user';
import { WhatIfRatingRequester } from '@xpo-ltl-2.0/sdk-rating';
import { User } from '@xpo-ltl/sdk-common';

export const requestersAllMock: WhatIfRatingRequester[] = [
  {
    requestedBy: undefined,
    requestedByName: 'All',
  },
];

export const requestersMock: WhatIfRatingRequester[] = [
  {
    requestedBy: 'mvelilla',
    requestedByName: 'Milton Velilla',
  },
  {
    requestedBy: 'afraga',
    requestedByName: 'Andres Fraga',
  },
  {
    requestedBy: 'sanandan',
    requestedByName: 'Saravana Anandan',
  },
  {
    requestedBy: 'mquintero002',
    requestedByName: 'Michel Quintero',
  },
];

export const usersMock: User[] = [
  {
    userId: 'mvelilla',
    employeeId: '01A',
    racfId: '01B',
    profileInstId: '01C',
    displayName: 'Milton Velilla',
    givenName: 'Milton',
    lastName: 'Velilla',
    title: 'User title',
    emailAddress: 'johnDoe@fake.com',
    mobile: '3000000000',
    telephone: '300000000',
    streetAddress: 'fake street 123',
    stateCd: 'MED',
    countryCd: 'CO',
    postalCode: '000000',
    department: 'Seller',
    businessUnit: 'HR',
    regionDescription: 'Dev',
    manager: 'Camisama',
    jobRoleCode: '0001',
    roles: ['Seller'],
    sicCode: '0001',
  },
  {
    userId: 'afraga',
    employeeId: '02A',
    racfId: '02B',
    profileInstId: '02C',
    displayName: 'Andres Fraga',
    givenName: 'Andres',
    lastName: 'Fraga',
    title: 'User title',
    emailAddress: 'songoku@fake.com',
    mobile: '3000000000',
    telephone: '300000000',
    streetAddress: 'fake street 123',
    stateCd: 'MED',
    countryCd: 'CO',
    postalCode: '000000',
    department: 'Seller',
    businessUnit: 'HR',
    regionDescription: 'Dev',
    manager: 'Camisama',
    jobRoleCode: '0001',
    roles: ['Seller'],
    sicCode: '0001',
  },
  {
    userId: 'sanandan',
    employeeId: '03A',
    racfId: '03B',
    profileInstId: '03C',
    displayName: 'Saravana Anandan',
    givenName: 'Saravana',
    lastName: 'Anandan',
    title: 'User title',
    emailAddress: 'songoku@fake.com',
    mobile: '3000000000',
    telephone: '300000000',
    streetAddress: 'fake street 123',
    stateCd: 'MED',
    countryCd: 'CO',
    postalCode: '000000',
    department: 'Seller',
    businessUnit: 'HR',
    regionDescription: 'Dev',
    manager: 'Camisama',
    jobRoleCode: '0001',
    roles: ['Seller'],
    sicCode: '0001',
  },
  {
    userId: 'mquintero002',
    employeeId: '04A',
    racfId: '04B',
    profileInstId: '04C',
    displayName: 'Michel Quintero',
    givenName: 'Michel',
    lastName: 'Quintero',
    title: 'User title',
    emailAddress: 'songoku@fake.com',
    mobile: '3000000000',
    telephone: '300000000',
    streetAddress: 'fake street 123',
    stateCd: 'MED',
    countryCd: 'CO',
    postalCode: '000000',
    department: 'Seller',
    businessUnit: 'HR',
    regionDescription: 'Dev',
    manager: 'Camisama',
    jobRoleCode: '0001',
    roles: ['Seller'],
    sicCode: '0001',
  },
];

export const requiredRolesCurrentPageMock: ApplicationRoles[] = [
  {
    user: {
      key: 'KEYROLE',
      name: 'ROLE_NAME',
    },
    testUser: {
      key: 'TESTROLE',
      name: 'TEST_ROLE_NAME',
    },
  },
  {
    user: {
      key: 'KEYROLETWO',
      name: 'ROLE_NAME_TWO',
    },
  },
];
