import { Injectable } from '@angular/core';
import { UserPreferenceComponentNameType, UserPreferenceResultType } from '@shared/models/user-preference';
import { DataOptions } from '@xpo-ltl/data-api';
import { Envelope } from '@xpo-ltl/sdk-common';
import {
  GetUserPreferenceQuery,
  GetUserPreferenceResp,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UtilsRequestsService } from '../utils/utils-requests.service';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  constructor(
    public userPreferenceApiService: UserPreferenceApiService,
    public utilsRequestsService: UtilsRequestsService
  ) {}

  getPreferencesFor(
    componentName: UserPreferenceComponentNameType,
    dataOptions: DataOptions = { loadingOverlayEnabled: false }
  ): Observable<UserPreferenceResultType> {
    const getPreferencesQuery = new GetUserPreferenceQuery();
    getPreferencesQuery.uiComponentName = componentName;
    return this.userPreferenceApiService.getUserPreference(getPreferencesQuery, dataOptions).pipe(
      map((response: any) => {
        try {
          const rawPreferences: string = response?.preferences;
          return rawPreferences ? (JSON.parse(rawPreferences) as UserPreferenceResultType) : undefined;
        } catch (error) {
          console.error(`Error parsing the release user preferences for ${componentName}`, error);
        }
      }),
      catchError((response: Envelope<GetUserPreferenceResp>) => {
        console.error(response);
        return throwError(this.utilsRequestsService.handleError<GetUserPreferenceResp>(response));
      })
    );
  }

  updatePreferencesFor(
    componentName: UserPreferenceComponentNameType,
    preferences: UserPreferenceResultType,
    dataOptions: DataOptions = { loadingOverlayEnabled: false }
  ): Observable<void> {
    const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
    upsertPreferencesRequest.uiComponentName = componentName;
    upsertPreferencesRequest.preferences = JSON.stringify(preferences);
    const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
    return this.userPreferenceApiService
      .upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery, dataOptions)
      .pipe(
        catchError((response) => {
          console.error(response);
          return throwError(this.utilsRequestsService.handleError(response));
        })
      );
  }
}
