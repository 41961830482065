import { AbstractControl, FormGroup } from '@angular/forms';
import { AccessorialsCdEnum } from '@shared/enums/accessorials-cd.enum';
import {
  RateQuoteOtherAccessorialCode,
  RateQuoteSelectInterface,
  ShipOptionsInterface
} from '@shared/models/rate-quote';
import { GetRateQuoteForShipmentResp, GetRateQuoteResp } from '@xpo-ltl-2.0/sdk-rating';
import { ChargeToCd, PricingRulesetOwnerCd, WeightUomCd } from '@xpo-ltl/sdk-common';
import { OffshoreOptionsDialogFormName } from '../../add-quote-details/shared/components/dialog/offshore-options/offshore-options-dialog.config';

// GENERAL ################################################################################################
export enum AddQuoteFormFieldsNames {
  customerInformation = 'customerInformation',
  paymentTermInformation = 'paymentTermInformation',
  pickupInformation = 'pickupInformation',
  deliveryInformation = 'deliveryInformation',
  commodityInformation = 'commodityInformation',
  otherSpecialServices = 'otherSpecialServices',
  comments = 'comments',
  accountInformation = 'accountInformation',
  specialServicesInternational = 'specialServicesInternational',
  internationalShippingInformation = 'internationalShippingInformation',
  internationalCommodity = 'internationalCommodity',
}

export interface AddQuoteFormObject {
  [AddQuoteFormFieldsNames.pickupInformation]: FormGroup;
  [AddQuoteFormFieldsNames.deliveryInformation]: FormGroup;
  [AddQuoteFormFieldsNames.commodityInformation]: FormGroup;
  [AddQuoteFormFieldsNames.otherSpecialServices]: FormGroup;
  [AddQuoteFormFieldsNames.comments]: any;
}

export interface AddQuoteCustomerShipmentFormObject extends AddQuoteFormObject {
  [AddQuoteFormFieldsNames.customerInformation]: FormGroup;
}

export interface AddQuoteGenericFormObject extends AddQuoteFormObject {
  [AddQuoteFormFieldsNames.paymentTermInformation]: FormGroup;
}

export interface AddQuoteCustomerInternationalFormObject {
  [AddQuoteFormFieldsNames.accountInformation]: FormGroup;
  [AddQuoteFormFieldsNames.internationalCommodity]: FormGroup;
  [AddQuoteFormFieldsNames.specialServicesInternational]: FormGroup;
  [AddQuoteFormFieldsNames.comments]: any;
}

export interface AddQuoteGenericInternationalFormObject {
  [AddQuoteFormFieldsNames.internationalShippingInformation]: FormGroup;
  [AddQuoteFormFieldsNames.internationalCommodity]: FormGroup;
  [AddQuoteFormFieldsNames.specialServicesInternational]: FormGroup;
  [AddQuoteFormFieldsNames.comments]: any;
}

export const RateQuoteListText = {
  liftGateService: ' Liftgate Hand-Unload',
  sorting: 'Sorting/Segregating',
  notification: 'Notification Prior to Delivery',
  inside: 'Inside',
  exhibition: 'Exhibition',
  residential: 'Residential/Limited Access',
  containerStation: 'Container Station',
  construction: 'Construction / Utility',
  mineGovAirp: 'Mine / Gov’t. / Airport',
  appointment: 'Appointment',
  timeDateCritical: 'Time / Date Critical',
  holidayWeekend: 'Holiday / Weekend',
  afterBusinessHoursDelivery: 'After Business Hours Delivery',
  consolidation: 'Consolidation Handling',
  reconsignment: 'Reconsignment',
  redelivery: 'Redelivery',
  piers: 'Piers / Wharves Unloading',
  piersLoading: 'Piers / Wharves Loading',
  inspection: 'Inspection of Shipment',
  weigh: 'Weigh / Reweigh Shipment',
  inBondFreight: 'In Bond Freight',
  inBondTirCarnet: 'In Bond TIR Carnet',
  inBondCustomsInspectionDelay: 'In Bond Customs Inspection Delay',
  inBondCustomsRelForms: 'In Bond Customs Rel. Forms',
  SufferanceNbOnQc: 'Sufferance (Canada): NB, ON, QC',
  SufferanceOther: 'Sufferance (Canada): Other',
  SufferanceRemanifest: 'Sufferance Re-manifest',
  SufferanceHandling: 'Sufferance Handling',
  SufferanceExamination: 'Sufferance Examination',
  SufferanceInspectionDelay: 'Sufferance Inspection Delay',
  instantGuaranteedService: 'Instant Guaranteed Service (G!)',
  guaranteedByNoon: 'Guaranteed by Noon (G!12)',
  blindShipment: 'Blind Shipment',
  correctiontoBol: 'Correction to BOL',
  codCancelChange: 'C.O.D. Cancel / Change',
  returnUndeliveredShipment: 'Return Undelivered Shipment',
  returnCheck: 'Return Check',
  rapidRemoteService: 'Rapid Remote Service',
  excessiveLength: 'Excessive Length',
  excessiveValueCharge: 'Excessive Value Charge',
  freezableProtection: 'Freezable Protection',
  borderCrossingService: 'Border Crossing Service',
};

export enum LooKupButtonId {
  pickUp = 'pickupInformationLookUpButton',
  delivery = 'deliveryInformationLookUpButton',
}

export enum AddQuoteCssClassNames {
  firstGroupList = 'first-group-list-element',
}

export type AddQuoteAccesorialsType =
  | AccessorialsCdEnum
  | AddQuoteCmmodityInformationFormFieldsNames
  | AddQuoteCmmodityListInformationFormFieldsNames
  | RateQuoteOtherAccessorialCode
  | OffshoreOptionsDialogFormName
  | AccessorialsCdEnum;

export const addCommodityDimentionsClassDefaultValue = '100';

export enum AddQuoteSpecialAccessorials {
  specialPricing = 'XSS',
  vsp = 'TLF',
}

// Payment Term #################################################################################################

export enum AddQuotePaymentTermsFormFieldsNames {
  paymentTerms = 'chargeToCd',
  discountLevel = 'overrideDiscountPct',
  tariff = 'overrideRatingTariff',
}
export interface RateQuotePaymentTermsInterface {
  prepaid: RateQuoteSelectInterface;
  collect: RateQuoteSelectInterface;
}

export enum PaymentTermsText {
  prepaid = 'Prepaid',
  collect = 'Collect',
}

export enum PaymentTermsShortValue {
  prepaid = 'P',
  collect = 'C',
}

export const RateQuotePaymentTerms: RateQuotePaymentTermsInterface = {
  prepaid: {
    value: ChargeToCd.PPD,
    text: PaymentTermsText.prepaid,
  },
  collect: {
    value: ChargeToCd.COLL,
    text: PaymentTermsText.collect,
  },
};

export const getRateQuotePaymentTermsTextFromValue = (value: RateQuotePaymentTermsInterface): PaymentTermsText => {
  for (const [_, objValue] of Object.entries(RateQuotePaymentTerms)) {
    if (objValue.value === value) {
      return objValue.text;
    }
  }
};

export const getPaymentTermsFromShortValue = (
  rateQuote: GetRateQuoteResp | GetRateQuoteForShipmentResp
): PaymentTermsText => {
  return rateQuote.quoteRequest.requesterPaymentTerms === PaymentTermsShortValue.prepaid
    ? (RateQuotePaymentTerms.prepaid.value as PaymentTermsText)
    : (RateQuotePaymentTerms.collect.value as PaymentTermsText);
};

export interface AddQuotePaymentTermsFormObject {
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: any;
  [AddQuotePaymentTermsFormFieldsNames.paymentTerms]: any;
  [AddQuotePaymentTermsFormFieldsNames.tariff]: any;
}

export const AddQuotePaymentTermsIds = {
  [AddQuotePaymentTermsFormFieldsNames.paymentTerms]: 'paymentTermsAddQuotePayment',
  [AddQuotePaymentTermsFormFieldsNames.tariff]: 'paymentTermsTariff',
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: 'discountLevelAddQuotePayment',
  prepaid: 'prepaidPaymentTermsAddQuotePayment',
  collect: 'collectDiscountLevelAddQuotePayment',
};

export const AddQuotePaymentTermsErrorIds = {
  [AddQuotePaymentTermsFormFieldsNames.paymentTerms]: 'paymentTermsAddQuotePaymentError',
  [AddQuotePaymentTermsFormFieldsNames.tariff]: 'paymentTermsTariffError',
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: 'discountLevelAddQuotePaymentError',
};

// CUSTOMER ###############################################################################################
export enum AddQuoteCustomerInformationFormFieldsNames {
  company = 'company',
  account = 'account',
  accountPostalCode = 'accountPostalCode',
  customerRole = 'customerRole',
  paymentTerms = 'paymentTerms',
}

export interface AddQuoteCustomerInformationFormObject {
  [AddQuoteCustomerInformationFormFieldsNames.company]: any;
  [AddQuoteCustomerInformationFormFieldsNames.account]: any;
  [AddQuoteCustomerInformationFormFieldsNames.accountPostalCode]: any;
  [AddQuoteCustomerInformationFormFieldsNames.customerRole]: any;
  [AddQuoteCustomerInformationFormFieldsNames.paymentTerms]: any;
}

export interface RateQuoteCustomerRoleType {
  shipper: RateQuoteSelectInterface;
  consignee: RateQuoteSelectInterface;
  thirdParty: RateQuoteSelectInterface;
}

export enum RateQUoteCustomerRoleTypeText {
  shipper = 'Shipper',
  consignee = 'Consignee',
  thirdParty = 'Third Party',
}

export enum RateQuoteCustomerRoleValueFromApi {
  shipper = '1',
  consignee = '2',
  thirdParty = '5',
}

export const RateQuoteCustomerRole: RateQuoteCustomerRoleType = {
  shipper: {
    value: PricingRulesetOwnerCd.SHIPPER,
    text: RateQUoteCustomerRoleTypeText.shipper,
  },
  consignee: {
    value: PricingRulesetOwnerCd.CONSIGNEE,
    text: RateQUoteCustomerRoleTypeText.consignee,
  },
  thirdParty: {
    value: PricingRulesetOwnerCd.BILL_TO,
    text: RateQUoteCustomerRoleTypeText.thirdParty,
  },
};

export function getCustomerShipperValueFromText(customerShipperValue: string): PricingRulesetOwnerCd {
  switch (customerShipperValue) {
    case RateQuoteCustomerRole.shipper.value:
    case RateQuoteCustomerRole.shipper.text:
      return RateQuoteCustomerRole.shipper.value as PricingRulesetOwnerCd;
    case RateQuoteCustomerRole.consignee.value:
    case RateQuoteCustomerRole.consignee.text:
      return RateQuoteCustomerRole.consignee.value as PricingRulesetOwnerCd;
    case RateQuoteCustomerRole.thirdParty.value:
    case RateQuoteCustomerRole.thirdParty.text:
      return RateQuoteCustomerRole.thirdParty.value as PricingRulesetOwnerCd;

    default:
      return RateQuoteCustomerRole.shipper.value as PricingRulesetOwnerCd;
  }
}

export const getRateQuoteCustomerShipperValueTextFromValue = (
  value: PricingRulesetOwnerCd
): RateQUoteCustomerRoleTypeText => {
  for (const [_, objValue] of Object.entries(RateQuoteCustomerRole)) {
    if (objValue.value === value) {
      return objValue.text;
    }
  }
};

export const getRateQuoteCustomerShipperFromApiValue = (roleApiValue: string) => {
  if (roleApiValue === RateQuoteCustomerRoleValueFromApi.shipper) {
    return PricingRulesetOwnerCd.SHIPPER;
  }
  if (roleApiValue === RateQuoteCustomerRoleValueFromApi.consignee) {
    return PricingRulesetOwnerCd.CONSIGNEE;
  }
  if (roleApiValue === RateQuoteCustomerRoleValueFromApi.thirdParty) {
    return RateQUoteCustomerRoleTypeText.thirdParty;
  }
};

export const AddQuoteCustomerInformationIds = {
  company: 'companyQuoteCustomer',
  account: 'accountQuoteCustomer',
  accountPostalCode: 'accountPostalCodeQuoteCustomer',
  customerRole: 'customerRoleQuoteCustomer',
  customerRoleShipper: 'customerRoleShipperQuoteCustomer',
  customerRoleConsignee: 'customerRoleConsigneeQuoteCustomer',
  customerRoleThridParty: 'customerRoleThridPartyQuoteCustomer',
  paymentTerms: 'paymentTermsQuoteCustomer',
  paymentTermsPrepaid: 'paymentTermsPrepaidQuoteCustomer',
  paymentTermsCollect: 'paymentTermsCollectQuoteCustomer',
};

// Pickup #################################################################################################
export enum AddQuotePickupInformationFormFieldsNames {
  countryCd = 'countryCd',
  originPostalCode = 'originZipNbr',
  city = 'city',
  effectiveShipmentDate = 'pickupDate',
  specialServicesCommon = 'specialPickupServicesCommon',
  specialServicesOther = 'specialPickupServicesOther',
}
export interface AddQuotePickupInformationFormObject {
  [AddQuotePickupInformationFormFieldsNames.countryCd]: AbstractControl;
  [AddQuotePickupInformationFormFieldsNames.originPostalCode]: AbstractControl;
  [AddQuotePickupInformationFormFieldsNames.city]: AbstractControl;
  [AddQuotePickupInformationFormFieldsNames.effectiveShipmentDate]: AbstractControl;
  [AddQuotePickupInformationFormFieldsNames.specialServicesCommon]: AbstractControl;
  [AddQuotePickupInformationFormFieldsNames.specialServicesOther]: AbstractControl;
}

export const AddQuotePickupInformationIds = {
  [AddQuotePickupInformationFormFieldsNames.countryCd]: 'originPostalCodeQuoteCustomer',
  [AddQuotePickupInformationFormFieldsNames.originPostalCode]: 'originPostalCodeQuoteCustomer',
  [AddQuotePickupInformationFormFieldsNames.effectiveShipmentDate]: 'effectiveShipmentDateQuoteCustomer',
  [AddQuotePickupInformationFormFieldsNames.city]: 'effectiveShipmentDateQuoteCustomer',
  [AddQuotePickupInformationFormFieldsNames.specialServicesCommon]: 'specialServicesCommonQuoteCustomer',
  [AddQuotePickupInformationFormFieldsNames.specialServicesOther]: 'specialServicesOtherQuoteCustomer',

  [AccessorialsCdEnum.insidePickup]: 'specialServicesCommonInside',
  [AccessorialsCdEnum.exhibitionPickup]: 'specialServicesCommonExhibition',
  [AccessorialsCdEnum.liftGateServicePickup]: 'specialServicesCommonLLiftGateService',
  [AccessorialsCdEnum.containerStationPickup]: 'specialServicesCommonContainerStation',
  [AccessorialsCdEnum.residentialPickup]: 'specialServicesCommonResidential',
  [AccessorialsCdEnum.sortingPickup]: 'specialServicesCommonSorting',

  [AccessorialsCdEnum.consolidationPickup]: 'specialServicesOtherConsolidation',
  [AccessorialsCdEnum.inspectionPickup]: 'specialServicesOtherInspection',
  [AccessorialsCdEnum.reconsignmentPickup]: 'specialServicesOtherReconsignment',
  [AccessorialsCdEnum.constructionPickup]: 'specialServicesOtherConstruction',
  [AccessorialsCdEnum.mineGovAirpPickup]: 'specialServicesOtherMineGovAirp',
  [AccessorialsCdEnum.weighPickup]: 'specialServicesOtherWeigh',
  [AccessorialsCdEnum.holidayWeekendPickup]: 'specialServicesOtherHolidayWeekend',
  [AccessorialsCdEnum.piersPickup]: 'specialServicesOtherPiers',
};

export const AddQuotePickupInformationErrorIds = {
  [AddQuotePickupInformationFormFieldsNames.effectiveShipmentDate]: 'effectiveShipmentDateQuoteCustomerError',
  [AddQuotePickupInformationFormFieldsNames.originPostalCode]: 'originPostalCodeQuoteCustomerError',
};

export const AddQuotePickupSpecialServicesCommonIds = {
  [AccessorialsCdEnum.insidePickup]: 'insideQuoteCustomer',
  [AccessorialsCdEnum.exhibitionPickup]: 'exhibitionQuoteCustomer',
  [AccessorialsCdEnum.liftGateServicePickup]: 'liftGateServiceQuoteCustomer',
  [AccessorialsCdEnum.containerStationPickup]: 'containerStationQuoteCustomer',
  [AccessorialsCdEnum.residentialPickup]: 'residentialQuoteCustomer',
  [AccessorialsCdEnum.sortingPickup]: 'sortingQuoteCustomer',
};

export const RateQuotePickupListText = {
  [AddQuotePickupInformationFormFieldsNames.specialServicesCommon]: {
    [AccessorialsCdEnum.insidePickup]: RateQuoteListText.inside,
    [AccessorialsCdEnum.exhibitionPickup]: RateQuoteListText.exhibition,
    [AccessorialsCdEnum.liftGateServicePickup]: RateQuoteListText.liftGateService,
    [AccessorialsCdEnum.containerStationPickup]: RateQuoteListText.containerStation,
    [AccessorialsCdEnum.residentialPickup]: RateQuoteListText.residential,
    [AccessorialsCdEnum.sortingPickup]: RateQuoteListText.sorting,
  },
  [AddQuotePickupInformationFormFieldsNames.specialServicesOther]: {
    [AccessorialsCdEnum.consolidationPickup]: RateQuoteListText.consolidation,
    [AccessorialsCdEnum.inspectionPickup]: RateQuoteListText.inspection,
    [AccessorialsCdEnum.reconsignmentPickup]: RateQuoteListText.reconsignment,
    [AccessorialsCdEnum.constructionPickup]: RateQuoteListText.construction,
    [AccessorialsCdEnum.mineGovAirpPickup]: RateQuoteListText.mineGovAirp,
    [AccessorialsCdEnum.weighPickup]: RateQuoteListText.weigh,
    [AccessorialsCdEnum.holidayWeekendPickup]: RateQuoteListText.holidayWeekend,
    [AccessorialsCdEnum.piersPickup]: RateQuoteListText.piers,
  },
};

export const AddQuotePickupInformationTooltipsId = {
  [AccessorialsCdEnum.exhibitionPickup]: 'pickupInformationCommonExhibitionTooltip',
  [AccessorialsCdEnum.containerStationPickup]: 'pickupInformationCommonContainerStationTooltip',
  [AccessorialsCdEnum.sortingPickup]: 'pickupInformationCommonSortingTooltip',

  [AccessorialsCdEnum.consolidationPickup]: 'pickupInformationOtherConsolidationTooltip',
  [AccessorialsCdEnum.reconsignmentPickup]: 'pickupInformationOtherReconsignmentTooltip',
  [AccessorialsCdEnum.constructionPickup]: 'pickupInformationOtherConstructionTooltip',
  [AccessorialsCdEnum.holidayWeekendPickup]: 'pickupInformationOtherHolidayWeekendTooltip',
  [AccessorialsCdEnum.piersPickup]: 'pickupInformationOtherPiersTooltip',
};

// Delivery Information #################################################################################################
export enum AddQuoteDeliveryInformationFormFieldsNames {
  countryCd = 'countryCd',
  destPostalCode = 'destinationZipNbr',
  city = 'city',
  specialDeliveryServicesCommon = 'specialDeliveryServicesCommon',
  specialDeliveryServicesOther = 'specialDeliveryServicesOther',
}
export interface AddQuoteDeliveryInformationFormObject {
  [AddQuoteDeliveryInformationFormFieldsNames.countryCd]: AbstractControl;
  [AddQuoteDeliveryInformationFormFieldsNames.destPostalCode]: AbstractControl;
  [AddQuoteDeliveryInformationFormFieldsNames.city]: AbstractControl;
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesCommon]: AbstractControl;
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesOther]: AbstractControl;
}

export const RateQuoteDeliveryListText = {
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesCommon]: {
    [AccessorialsCdEnum.liftGateServiceDelivery]: RateQuoteListText.liftGateService,
    [AccessorialsCdEnum.sortingDelivery]: RateQuoteListText.sorting,
    [AccessorialsCdEnum.notificationDelivery]: RateQuoteListText.notification,
    [AccessorialsCdEnum.insideDelivery]: RateQuoteListText.inside,
    [AccessorialsCdEnum.exhibitionDelivery]: RateQuoteListText.exhibition,
    [AccessorialsCdEnum.residentialDelivery]: RateQuoteListText.residential,
    [AccessorialsCdEnum.containerStationDelivery]: RateQuoteListText.containerStation,
    [AccessorialsCdEnum.constructionDelivery]: RateQuoteListText.construction,
    [AccessorialsCdEnum.mineGovAirpDelivery]: RateQuoteListText.mineGovAirp,
    [AccessorialsCdEnum.appointmentDelivery]: RateQuoteListText.appointment,
    [AccessorialsCdEnum.timeDateCriticalDelivery]: RateQuoteListText.timeDateCritical,
    [AccessorialsCdEnum.holidayWeekendDelivery]: RateQuoteListText.holidayWeekend,
    [AccessorialsCdEnum.afterBusinessHoursDelivery]: RateQuoteListText.afterBusinessHoursDelivery,
    [AccessorialsCdEnum.borderCrossingServiceDelivery]: RateQuoteListText.borderCrossingService,
  },
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesOther]: {
    [AccessorialsCdEnum.consolidationDelivery]: RateQuoteListText.consolidation,
    [AccessorialsCdEnum.reconsignmentDelivery]: RateQuoteListText.reconsignment,
    [AccessorialsCdEnum.redeliveryDelivery]: RateQuoteListText.redelivery,
    [AccessorialsCdEnum.piersDelivery]: RateQuoteListText.piersLoading,
  },
};

export const AddQuoteDeliveryInformationIds = {
  [AddQuoteDeliveryInformationFormFieldsNames.destPostalCode]: 'destPostalCode',
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesCommon]: 'specialDeliveryServicesCommon',
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesOther]: 'specialDeliveryServicesOther',

  [AccessorialsCdEnum.liftGateServiceDelivery]: 'deliverySpecialServicesCommonLiftGateService',
  [AccessorialsCdEnum.sortingDelivery]: 'deliverySpecialServicesCommonSorting',
  [AccessorialsCdEnum.notificationDelivery]: 'deliverySpecialServicesCommonNotification',
  [AccessorialsCdEnum.insideDelivery]: 'deliverySpecialServicesCommonInside',
  [AccessorialsCdEnum.exhibitionDelivery]: 'deliverySpecialServicesCommonExhibition',
  [AccessorialsCdEnum.residentialDelivery]: 'deliverySpecialServicesCommonResidential',
  [AccessorialsCdEnum.containerStationDelivery]: 'deliverySpecialServicesCommonContainerStation',
  [AccessorialsCdEnum.constructionDelivery]: 'deliverySpecialServicesCommonConstruction',
  [AccessorialsCdEnum.mineGovAirpDelivery]: 'deliverySpecialServicesCommonMineGovAirp',
  [AccessorialsCdEnum.appointmentDelivery]: 'deliverySpecialServicesCommonAppointment',
  [AccessorialsCdEnum.timeDateCriticalDelivery]: 'deliverySpecialServicesCommonTimeDateCritical',
  [AccessorialsCdEnum.holidayWeekendDelivery]: 'deliverySpecialServicesCommonHolidayWeekend',
  [AccessorialsCdEnum.afterBusinessHoursDelivery]:
    'deliverySpecialServicesCommonAfterBusinessHoursDelivery',

  [AccessorialsCdEnum.consolidationDelivery]: 'deliverySpecialServicesOtherConsolidation',
  [AccessorialsCdEnum.reconsignmentDelivery]: 'deliverySpecialServicesOtherReconsignment',
  [AccessorialsCdEnum.redeliveryDelivery]: 'deliverySpecialServicesOtherRedelivery',
  [AccessorialsCdEnum.piersDelivery]: 'deliverySpecialServicesOtherPpiers',
};

export const AddQuoteDeliveryInformationFormErrorIds = {
  [AddQuoteDeliveryInformationFormFieldsNames.destPostalCode]: 'destPostalCodeError',
};

export const AddQuoteDeliveryInformationFirstGroupListAttributes = {
  [AccessorialsCdEnum.insideDelivery]: true,
  [AccessorialsCdEnum.appointmentDelivery]: true,
};

export const AddQuoteDeliveryInformationTooltipIds = {
  [AccessorialsCdEnum.liftGateServiceDelivery]:
    'deliverySpecialServicesCommonLiftGateServiceTooltip',
  [AccessorialsCdEnum.sortingDelivery]: 'deliverySpecialServicesCommonSortingTooltip',
  [AccessorialsCdEnum.exhibitionDelivery]: 'deliverySpecialServicesCommonExhibitionTooltip',
  [AccessorialsCdEnum.containerStationDelivery]:
    'deliverySpecialServicesCommonContainerStationTooltip',
  [AccessorialsCdEnum.constructionDelivery]: 'deliverySpecialServicesCommonConstructionTooltip',
  [AccessorialsCdEnum.appointmentDelivery]: 'deliverySpecialServicesCommonAppointmentTooltip',
  [AccessorialsCdEnum.holidayWeekendDelivery]:
    'deliverySpecialServicesCommonHolidayWeekendTooltip',
  [AccessorialsCdEnum.timeDateCriticalDelivery]:
    'deliverySpecialServicesCommonTimeDateCriticalTooltip',

  [AccessorialsCdEnum.consolidationDelivery]: 'deliverySpecialServicesOtherConsolidationTooltip',
  [AccessorialsCdEnum.reconsignmentDelivery]: 'deliverySpecialServicesOtherReconsignmentTooltip',
  [AccessorialsCdEnum.piersDelivery]: 'deliverySpecialServicesOtherPiersTooltip',
};

// Commodity Information ##################################################################################################
export enum AddQuoteCmmodityInformationFormFieldsNames {
  commodityList = 'commodityList',
  singleShipment = 'SSC',
  totalPalletCount = 'totalPalletCnt',
  totalLinealFeet = 'totalLinealFeet',
}

export enum AddQuoteCmmodityListInformationFormFieldsNames {
  class = 'class',
  weight = 'weight',
  length = 'length',
  width = 'width',
  height = 'height',
  numberOfUnits = 'numberOfUnits',
  commodity = 'commodity',
  nmfcCode = 'nmfcCode',
  hazmat = 'hazmat',
}

export interface AddQuoteCommodityInformationFormObject {
  [AddQuoteCmmodityInformationFormFieldsNames.commodityList]: any;
  [AddQuoteCmmodityInformationFormFieldsNames.singleShipment]: any;
  [AddQuoteCmmodityInformationFormFieldsNames.totalPalletCount]: any;
  [AddQuoteCmmodityInformationFormFieldsNames.totalLinealFeet]: any;
}

export interface AddQuoteCommodityInformationListFormObject {
  [AddQuoteCmmodityListInformationFormFieldsNames.class]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.weight]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.length]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.width]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.height]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.numberOfUnits]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.commodity]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.nmfcCode]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.hazmat]: any;
}

export const AddQuoteCmmodityInformationIds = {
  [AddQuoteCmmodityInformationFormFieldsNames.singleShipment]: 'singleShipmentQuoteCmmodity',
  [AddQuoteCmmodityInformationFormFieldsNames.totalPalletCount]: 'totalPalletCountQuoteCmmodity',
  [AddQuoteCmmodityInformationFormFieldsNames.totalLinealFeet]: 'totalLinealFeetQuoteCmmodity',
  addAnhotherCommodity: 'addAnhotherCommodity',
  totalPalletCountInfo: 'totalPalletCountInfo',
};

export const AddQuoteCmmodityInformationListIds = {
  [AddQuoteCmmodityListInformationFormFieldsNames.class]: 'classQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.weight]: 'weightQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.length]: 'lengthQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.width]: 'widthQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.height]: 'heightQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.numberOfUnits]: 'numberOfUnitsQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.commodity]: 'commodityQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.nmfcCode]: 'nmfcCodeQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.hazmat]: 'hazmatQuoteCommodityList',
};

export const AddQuoteCmmodityInformationListErrorIds = {
  [AddQuoteCmmodityListInformationFormFieldsNames.class]: 'classQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.weight]: 'weightQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.length]: 'lengthQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.width]: 'widthQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.height]: 'heightQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.numberOfUnits]: 'numberOfUnitsQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.commodity]: 'commodityQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.nmfcCode]: 'nmfcCodeQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.hazmat]: 'hazmatQuoteCommodityListError',
};

export const QuoteCommodityInformationDefaultValues = {
  [AddQuoteCmmodityListInformationFormFieldsNames.class]: addCommodityDimentionsClassDefaultValue,
};

export const NewCommodityInformationList: AddQuoteCommodityInformationListFormObject = {
  [AddQuoteCmmodityListInformationFormFieldsNames.class]: [
    QuoteCommodityInformationDefaultValues[AddQuoteCmmodityListInformationFormFieldsNames.class],
  ],
  [AddQuoteCmmodityListInformationFormFieldsNames.weight]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.length]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.width]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.height]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.numberOfUnits]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.commodity]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.nmfcCode]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.hazmat]: [null],
};

export const AddQuoteCmmodityInformationTooltipIds = {
  [AddQuoteCmmodityListInformationFormFieldsNames.hazmat]: 'hazmatQuoteCommodityListTooltipId',
};

// Other Special Services #################################################################################################
export enum AddQuoteOtherSpecialServicesFormFieldsNames {
  internationalServices = 'internationalServices',
  miscellaneousServices = 'miscellaneousServices',
}

export enum AccessorialsCdEnumJoin {
  instantGuaranteedRapidRemote = 'GUR_RRS',
}

export enum AddQuoteOtherSpecialServicesMiscellaneousInputFieldsNames {
  excessiveLengthList = 'excessiveLengthList',
  excessiveValueChargeValue = 'excessiveValueChargeValue',
}

export interface AddQuoteOtherSpecialServicesFormObject {
  [AddQuoteOtherSpecialServicesFormFieldsNames.internationalServices]: any;
  [AddQuoteOtherSpecialServicesFormFieldsNames.miscellaneousServices]: any;
}

export const RateQuoteOtherSpecialServicesListText = {
  [AddQuoteOtherSpecialServicesFormFieldsNames.internationalServices]: {
    [AccessorialsCdEnum.inBondFreight]: RateQuoteListText.inBondFreight,
    [AccessorialsCdEnum.inBondTirCarnet]: RateQuoteListText.inBondTirCarnet,
    [AccessorialsCdEnum.inBondCustomsInspectionDelay]:
      RateQuoteListText.inBondCustomsInspectionDelay,
    [AccessorialsCdEnum.inBondCustomsRelForms]:
      RateQuoteListText.inBondCustomsRelForms,
    [AccessorialsCdEnum.sufferanceNbOnQc]: RateQuoteListText.SufferanceNbOnQc,
    [AccessorialsCdEnum.sufferanceOther]: RateQuoteListText.SufferanceOther,
    [AccessorialsCdEnum.sufferanceRemanifest]: RateQuoteListText.SufferanceRemanifest,
    [AccessorialsCdEnum.sufferanceHandling]: RateQuoteListText.SufferanceHandling,
    [AccessorialsCdEnum.sufferanceExamination]:
      RateQuoteListText.SufferanceExamination,
    [AccessorialsCdEnum.sufferanceInspectionDelay]:
      RateQuoteListText.SufferanceInspectionDelay,
  },
  [AddQuoteOtherSpecialServicesFormFieldsNames.miscellaneousServices]: {
    [AccessorialsCdEnum.instantGuaranteedService]:
      RateQuoteListText.instantGuaranteedService,
    [AccessorialsCdEnum.guaranteedByNoon]: RateQuoteListText.guaranteedByNoon,
    [AccessorialsCdEnum.blindShipment]: RateQuoteListText.blindShipment,
    [AccessorialsCdEnum.correctiontoBol]: RateQuoteListText.correctiontoBol,
    [AccessorialsCdEnum.codCancelChange]: RateQuoteListText.codCancelChange,
    [AccessorialsCdEnum.returnUndeliveredShipment]:
      RateQuoteListText.returnUndeliveredShipment,
    [AccessorialsCdEnum.returnCheck]: RateQuoteListText.returnCheck,
    [AccessorialsCdEnum.rapidRemoteService]: RateQuoteListText.rapidRemoteService,
    [AccessorialsCdEnum.excessiveLength]: RateQuoteListText.excessiveLength,
    [AccessorialsCdEnum.excessiveValueCharge]: RateQuoteListText.excessiveValueCharge,
    [AccessorialsCdEnum.freezableProtection]: RateQuoteListText.freezableProtection,
  },
};

export const AddQuoteOtherSpecialServicesIds = {
  [AddQuoteOtherSpecialServicesFormFieldsNames.internationalServices]: 'otherServicesInternationalServices',
  [AddQuoteOtherSpecialServicesFormFieldsNames.miscellaneousServices]: 'otherServicesMiscellaneousServices',

  [AccessorialsCdEnum.inBondFreight]: 'otherServicesInBondFreight',
  [AccessorialsCdEnum.inBondTirCarnet]: 'otherServicesInBondTirCarnet',
  [AccessorialsCdEnum.inBondCustomsInspectionDelay]:
    'otherServicesInBondCustomsInspectionDelay',
  [AccessorialsCdEnum.inBondCustomsRelForms]: 'otherServicesInBondCustomsRelForms',
  [AccessorialsCdEnum.sufferanceNbOnQc]: 'otherServicesSufferanceNbOnQc',
  [AccessorialsCdEnum.sufferanceOther]: 'otherServicesSufferanceOther',
  [AccessorialsCdEnum.sufferanceRemanifest]: 'otherServicesSufferanceRemanifest',
  [AccessorialsCdEnum.sufferanceHandling]: 'otherServicesSufferanceHandling',
  [AccessorialsCdEnum.sufferanceExamination]: 'otherServicesSufferanceExamination',
  [AccessorialsCdEnum.sufferanceInspectionDelay]:
    'otherServicesSufferanceInspectionDelay',

  [AccessorialsCdEnum.instantGuaranteedService]:
    'otherServicesInstantGuaranteedService',
  [AccessorialsCdEnum.guaranteedByNoon]: 'otherServicesGuaranteedByNoon',
  [AccessorialsCdEnum.blindShipment]: 'otherServicesBlindShipment',
  [AccessorialsCdEnum.correctiontoBol]: 'otherServicesCorrectiontoBol',
  [AccessorialsCdEnum.codCancelChange]: 'otherServicesCodCancelChange',
  [AccessorialsCdEnum.returnUndeliveredShipment]:
    'otherServicesReturnUndeliveredShipment',
  [AccessorialsCdEnum.returnCheck]: 'otherServicesReturnCheck',
  [AccessorialsCdEnum.rapidRemoteService]: 'otherServicesRapidRemoteService',
  [AccessorialsCdEnum.excessiveLength]: 'otherServicesExcessiveLength',
  [AddQuoteOtherSpecialServicesMiscellaneousInputFieldsNames.excessiveLengthList]: 'otherServicesExcessiveLengthList',
  [AccessorialsCdEnum.excessiveValueCharge]: 'otherServicesExcessiveValueCharge',
  [AddQuoteOtherSpecialServicesMiscellaneousInputFieldsNames.excessiveValueChargeValue]:
    'otherServicesExcessiveValueChargeValue',
  [AccessorialsCdEnum.freezableProtection]: 'otherServicesFreezableProtection',
};

export const AddQuoteOtherSpecialServicesFirstGroupListAttributes = {
  [AccessorialsCdEnum.sufferanceNbOnQc]: true,
  [AccessorialsCdEnum.blindShipment]: true,
  [AccessorialsCdEnum.returnUndeliveredShipment]: true,
  [AccessorialsCdEnum.excessiveLength]: true,
  [AccessorialsCdEnum.excessiveValueCharge]: true,
};

export const AddQuoteOtherSpecialServicesTooltipIds = {
  [AccessorialsCdEnum.instantGuaranteedService]:
    'otherServicesInstantGuaranteedServiceTooltip',
  [AccessorialsCdEnum.blindShipment]: 'otherServicesBlindShipmentTooltip',
  [AccessorialsCdEnum.codCancelChange]: 'otherServicesCodCancelChangeTooltip',
  [AccessorialsCdEnum.returnUndeliveredShipment]:
    'otherServicesReturnUndeliveredShipmentTooltip',
  [AccessorialsCdEnum.returnCheck]: 'otherServicesReturnCheckTooltip',
  [AccessorialsCdEnum.freezableProtection]: 'otherServicesFreezableProtectionTooltip',
  [AccessorialsCdEnum.rapidRemoteService]: 'otherServicesRapidRemoteServiceTooltip',
  [AccessorialsCdEnum.inBondFreight]: 'otherServicesInBondFreightTooltip',
  [AccessorialsCdEnum.inBondTirCarnet]: 'otherServicesInBondTirCarnetTooltip',
};

// Comments #################################################################################################
export const AddQuoteCommentsIds = {
  [AddQuoteFormFieldsNames.comments]: 'commentsAddQuote',
  hint: 'commentsAddQuoteHint',
};

// ACCOUNT TRANSPORT INFORMATION ##################################################################################
export enum AddQuoteAccountTransportInformationFormFieldsNames {
  city = 'city',
  ship = 'ship',
  postalCode = 'postalCode',
}

export const AddQuoteAccountTransportInformationIds = {
  [AddQuoteAccountTransportInformationFormFieldsNames.city]: 'cityTransport',
  [AddQuoteAccountTransportInformationFormFieldsNames.ship]: 'shipTransport',
  shipToDestination: 'shipToDestinationTransport',
  shipFromOrigin: 'shipFromOriginTransport',
  [AddQuoteAccountTransportInformationFormFieldsNames.postalCode]: 'postalCodeTransport',
  postalCodeError: 'postalCodeTransportError',
};

export enum AddQuoteAccountTransportationDefaultValue {
  city = 'empty',
}

// ACCOUNT INFORMATION ############################################################################################
export enum AddQuoteAccountInformationFormFieldsNames {
  name = 'name',
  address = 'address',
  accountMdCode = 'accountMdCode',
  paymentTerms = 'paymentTerms',
}

export interface AddQuoteAccountInformationFormObject {
  [AddQuoteAccountInformationFormFieldsNames.name]: any;
  [AddQuoteAccountInformationFormFieldsNames.address]: any;
  [AddQuoteAccountInformationFormFieldsNames.accountMdCode]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.city]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.ship]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.postalCode]?: any;
  [AddQuoteAccountInformationFormFieldsNames.paymentTerms]: any;
}

export const AddQuoteAccountInformationIds = {
  [AddQuoteAccountInformationFormFieldsNames.name]: 'nameAccount',
  [AddQuoteAccountInformationFormFieldsNames.address]: 'addressAccount',
  [AddQuoteAccountInformationFormFieldsNames.accountMdCode]: 'accountMdCodeAccount',
  [AddQuoteAccountTransportInformationFormFieldsNames.city]: AddQuoteAccountTransportInformationIds.city,
  [AddQuoteAccountTransportInformationFormFieldsNames.ship]: AddQuoteAccountTransportInformationIds.ship,
  [AddQuoteAccountInformationFormFieldsNames.paymentTerms]: 'paymentTermsAccount',
  prepaid: 'prepaidPaymentAccount',
  collect: 'collectAccount',
};

// Ship #################################################################################################################

export enum ShipOptionText {
  toDestination = 'Ship to Destination',
  fromOrigin = 'Ship from Origin',
}

export const ShipOptions: ShipOptionsInterface = {
  toDestination: {
    value: ChargeToCd.PPD,
    text: ShipOptionText.toDestination,
  },
  fromOrigin: {
    value: ChargeToCd.COLL,
    text: ShipOptionText.fromOrigin,
  },
};

// Special Services International #####################################################################################################
export enum AddQuoteOtherSpecialServicesInternationalFormFieldsNames {
  specialServicesInternationalCol1 = 'specialServicesInternationalCol1',
  specialServicesInternationalCol2 = 'specialServicesInternationalCol2',
  specialServicesInternationalCol3 = 'specialServicesInternationalCol3',
  specialServicesInternationalCol4 = 'specialServicesInternationalCol4',
  specialServicesInternationalCol5 = 'specialServicesInternationalCol5',
}

export type AddQuoteSpecialServicesInternationalFormObject = {
  [col in AddQuoteOtherSpecialServicesInternationalFormFieldsNames]?: FormGroup;
};

export const AddQuoteSpecialServicesInternationalListText = {
  [AccessorialsCdEnum.insidePickup]: RateQuoteListText.inside,
  [AccessorialsCdEnum.liftGateServicePickup]: RateQuoteListText.liftGateService,
  [AccessorialsCdEnum.containerStationPickup]: RateQuoteListText.containerStation,
  [AccessorialsCdEnum.residentialPickup]: RateQuoteListText.residential,
  [AccessorialsCdEnum.sortingPickup]: RateQuoteListText.sorting,
  [AccessorialsCdEnum.consolidationPickup]: RateQuoteListText.consolidation,
  [AccessorialsCdEnum.inspectionPickup]: RateQuoteListText.inspection,
  [AccessorialsCdEnum.reconsignmentPickup]: RateQuoteListText.reconsignment,
  [AccessorialsCdEnum.constructionPickup]: RateQuoteListText.construction,
  [AccessorialsCdEnum.mineGovAirpPickup]: RateQuoteListText.mineGovAirp,
  [AccessorialsCdEnum.weighPickup]: RateQuoteListText.weigh,
  [AccessorialsCdEnum.holidayWeekendPickup]: RateQuoteListText.holidayWeekend,
  [AccessorialsCdEnum.piersPickup]: RateQuoteListText.piers,
  [AccessorialsCdEnum.liftGateServiceDelivery]: RateQuoteListText.liftGateService,
  [AccessorialsCdEnum.sortingDelivery]: RateQuoteListText.sorting,
  [AccessorialsCdEnum.notificationDelivery]: RateQuoteListText.notification,
  [AccessorialsCdEnum.insideDelivery]: RateQuoteListText.inside,
  [AccessorialsCdEnum.residentialDelivery]: RateQuoteListText.residential,
  [AccessorialsCdEnum.containerStationDelivery]: RateQuoteListText.containerStation,
  [AccessorialsCdEnum.constructionDelivery]: RateQuoteListText.construction,
  [AccessorialsCdEnum.mineGovAirpDelivery]: RateQuoteListText.mineGovAirp,
  [AccessorialsCdEnum.holidayWeekendDelivery]: RateQuoteListText.holidayWeekend,
  [AccessorialsCdEnum.consolidationDelivery]: RateQuoteListText.consolidation,
  [AccessorialsCdEnum.reconsignmentDelivery]: RateQuoteListText.reconsignment,
  [AccessorialsCdEnum.redeliveryDelivery]: RateQuoteListText.redelivery,
  [AccessorialsCdEnum.piersDelivery]: RateQuoteListText.piers,
  [AccessorialsCdEnum.borderCrossingServiceDelivery]: RateQuoteListText.borderCrossingService,
  [AccessorialsCdEnum.excessiveLength]: RateQuoteListText.excessiveLength,
  [AccessorialsCdEnum.excessiveValueCharge]: RateQuoteListText.excessiveValueCharge,
};

// International Shipping Information ########################################################################################################
export interface AddQuoteInternationalShippingInformationFormObject {
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: any;
  [AddQuotePaymentTermsFormFieldsNames.tariff]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.city]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.ship]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.postalCode]: any;
}

export const AddQuoteInternationalShippingInformationTermsIds = {
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: 'internationalShippingDiscountLevel',
  [AddQuotePaymentTermsFormFieldsNames.tariff]: 'internationalShippingTariff',
};

export const AddQuoteInternationalShippingInformationTermsErrorIds = {
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: 'internationalShippingDiscountLevelError',
  [AddQuotePaymentTermsFormFieldsNames.tariff]: 'internationalShippingTariffError',
};

// International Commodity Information ##################################################################################################
export enum AddQuoteInternationalVolume {
  cubicMeters = 'cubicMeters',
  cubicFeet = 'cubicFeet',
}
export interface AddQuoteInternationalCommodityDimentionListInterface {
  weight: RateQuoteSelectInterface[];
  volume: RateQuoteSelectInterface[];
}

export const AddQuoteInternationalCommodityDimentionList: AddQuoteInternationalCommodityDimentionListInterface = {
  weight: [{ value: WeightUomCd.POUNDS, text: 'lbs' }, { value: WeightUomCd.KILOGRAMS, text: 'kgs' }],
  volume: [
    { value: AddQuoteInternationalVolume.cubicFeet, text: 'cubic feet' },
    { value: AddQuoteInternationalVolume.cubicMeters, text: 'cubic meters' },
  ],
};

export enum AddQuoteInternationalCommodityInformationFormFieldsNames {
  commodityList = 'commodityList',
  anticipatedShippingDate = 'anticipatedShippingDate',
  weightDimention = 'weightDimention',
  volumeDimention = 'volumeDimention',
  singleShipment = 'singleShipment',
}

export enum AddQuoteInternationalCommodityListInformationFormFieldsNames {
  class = 'class',
  weight = 'weight',
  volume = 'volume',
  commodity = 'commodity',
  nmfcCode = 'nmfcCode',
}

export interface AddQuoteInternationalCommodityInformationFormObject {
  [AddQuoteInternationalCommodityInformationFormFieldsNames.commodityList]: any;
  [AddQuoteInternationalCommodityInformationFormFieldsNames.anticipatedShippingDate]: any;
  [AddQuoteInternationalCommodityInformationFormFieldsNames.weightDimention]: any;
  [AddQuoteInternationalCommodityInformationFormFieldsNames.volumeDimention]: any;
  [AddQuoteInternationalCommodityInformationFormFieldsNames.singleShipment]: any;
}

export interface AddQuoteInternationalCommodityInformationListFormObject {
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.class]: any;
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.weight]: any;
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.volume]: any;
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.commodity]: any;
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.nmfcCode]: any;
}

export const AddQuoteInternationalCommodityInformationIds = {
  [AddQuoteInternationalCommodityInformationFormFieldsNames.anticipatedShippingDate]: 'anticipatedShippingDate',
  [AddQuoteInternationalCommodityInformationFormFieldsNames.weightDimention]: 'anticipatedShippingDate',
  [AddQuoteInternationalCommodityInformationFormFieldsNames.volumeDimention]: 'anticipatedShippingDate',
  [AddQuoteInternationalCommodityInformationFormFieldsNames.singleShipment]: 'singleShipment',
  addAnhotherCommodity: 'addAnhotherCommodity',
};

export const AddQuoteInternationalCommodityInformationListIds = {
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.class]: 'classInternationalQuoteCommodityList',
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.weight]: 'weightInternationalQuoteCommodityList',
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.volume]: 'volumeInternationalQuoteCommodityList',
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.commodity]: 'commodityInternationalQuoteCommodityList',
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.nmfcCode]: 'nmfcCodeInternationalQuoteCommodityList',
};
