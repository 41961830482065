import { AbstractControl, FormGroup } from '@angular/forms';
import { CreateRateQuoteResp, ListRateQuotesResp, RateQuoteRequest } from '@xpo-ltl-2.0/sdk-rating';
import { CommodityPackageCd, CustomerFunctionCd, CustomerTypeCd, ListInfo, RatingAccessorial, User } from '@xpo-ltl/sdk-common';
import {
  PaymentTermsText,
  RateQuoteCustomerRoleValueFromApi
} from 'app/rate-quote/add-rate-quote/shared/form-utils/add-quote-form.config';
import { LinkButtonInputs } from './button';

export enum RateQuoteSourceValue {
  WAV = 'WAV',
  XML = 'XML',
  API = 'API',
  WEB = 'WEB',
}

export enum RateQuoteTypeValue {
  customerSpecific = 'C',
  generic = 'R',
  shipmentProNmbr = 'S',
}

export enum RateQuoteOtherAccessorialCode {
  hazmat = 'ZHM',
}

export enum RateQuoteAccessorialIndicator {
  input = 'I',
}

export enum SpotQuotePrefix {
  search = 'SQ',
}

export const rateQuoteSource = {
  internal: { label: 'Internal', value: RateQuoteSourceValue.WAV },
  xml: { label: 'XML', value: RateQuoteSourceValue.XML },
  external: { label: 'API / External', value: RateQuoteSourceValue.API },
};

export enum RateQuoteSearchTabsLabel {
  generic = 'Generic',
  customer = 'Customer-Specific',
  shipment = 'Shipment',
  lookup = 'Quote Lookup',
  genericInternational = 'Generic-International',
  customerInternational = 'Customer-Specific-International',
}

export enum RateQuoteOffshoreText {
  textToReplace = '{{stateName}}',
  mailSubtitle = 'Off-Shore {{stateName}} Quote',
  mailSubject = 'XPO Logistics Off-Shore {{stateName}} Quote Request',
  mailTestingNote = 'Testing note: in test, sent to you; in prod, this would have been sent to pricing email = {{stateName}}',
  screenTitle = 'Request an Off-Shore Quote for {{stateName}}',
}
export const rateQuotePhoneCountriesIndicators = {
  usCa: { name: 'US/Canada', indicator: '1' },
  mx: { name: 'Mexico', indicator: '52' },
};

export const rateQuotePhoneCountries: string[] = [
  rateQuotePhoneCountriesIndicators.usCa.name,
  rateQuotePhoneCountriesIndicators.mx.name,
];
export const rateQuoteTypeOfUnits: string[] = [
  'Carton',
  'Pallet',
  'Pieces',
  'Skid',
  'Bag',
  'Bale',
  'Barrel',
  'Bundle',
  'Bin',
  'Box',
  'Basket/Hampr',
  'Bottle',
  'Bucket',
  'Cabinet',
  'Cage',
  'Can',
  'Case',
  'Carby/Gaylrd',
  'Chest',
  'Container',
  'Coil',
  'Crate',
  'Cylinder',
  'Drum',
  'Envelope',
  'Frame',
  'Int Bulk Ctr',
  'Jar',
  'Jerrycan',
  'Jug',
  'Keg',
  'Kit',
  'Loose',
  'Miscellaneous',
  'Pail',
  'Package',
  'Platform',
  'Rack',
  'Reel',
  'Roll',
  'Sack',
  'Sleeve',
  'Spool',
  'Tube',
  'Tote bin',
  'Tank',
  'Tray',
  'Tub',
];

export const rateQuoteTypeOfHandlingUnit: { value: string; label: string }[] = [
  { value: CommodityPackageCd.PALLET, label: 'Pallet' },
  { value: CommodityPackageCd.SKID, label: 'Skid' },
  { value: CommodityPackageCd.BUNDLE, label: 'Bundle' },
  { value: CommodityPackageCd.CRATE, label: 'Crate' },
  { value: CommodityPackageCd.DRUM, label: 'Drum' },
  { value: CommodityPackageCd.RACK, label: 'Rack' },
  { value: CommodityPackageCd.TOTE_BIN, label: 'Tote' },
];

export enum RecalculateRateQuoteTypes {
  guaranteedByNoon = 'guaranteedByNoon',
  instantGuaranteedService = 'instantGuaranteedService',
  rapidRemoteService = 'rapidRemoteService',
  instantGuaranteedAndRapidRemote = 'instantGuaranteedAndRapidRemote',
}

export enum RateQuotePdfData {
  textToScroll = 'textToScroll',
}

export interface RateQuoteCustomerTypeFromApi {
  customerTypeCd: CustomerTypeCd;
  customerNbr: number;
  customerRole: RateQuoteCustomerRoleValueFromApi;
}

export interface RateQuoteSearchTabs {
  generic: {
    label: RateQuoteSearchTabsLabel.generic;
    selected: boolean;
  };
  customer: {
    label: RateQuoteSearchTabsLabel.customer;
    selected: boolean;
  };
  shipment: {
    label: RateQuoteSearchTabsLabel.shipment;
    selected: boolean;
  };
  lookup: {
    label: RateQuoteSearchTabsLabel.lookup;
    selected: boolean;
  };
}

export interface RateQuoteGridSort {
  sortType: string;
  field: string;
}

export class RateQuoteDateGridFilter {
  from: string;
  to: string;
}
export class RateQuoteGridFilter {
  createDate?: RateQuoteDateGridFilter;
  shipmentDate?: RateQuoteDateGridFilter;
  source?: RateQuoteSourceValue[] = [];
  generalSearch?: string;
}

export interface RateQuoteCallGetListAttributtes {
  requestedBy: string;
  customerNbr: number;
  confirmationNumber?: string;
  startAt: number;
  numberOfRows: number;
  observableId?: string;
  sort?: RateQuoteGridSort;
  filters?: RateQuoteGridFilter;
  loadingOverlayEnabled?: boolean;
}

export interface RateQuoteGetListActionSuccessInterface {
  observableId: string;
  rateQuoteList: ListRateQuotesResp;
}

export interface FaceFilterContractedButton {
  arrowRigth: string;
  arrowLeft: string;
}

export interface RateQuoteShowLookupButtons {
  showLookupButtonPickupInformation: boolean;
  showLookupButtonDeliveryInformation: boolean;
}

export interface RateQuotePostalCodes {
  originZipNbr: string;
  destinationZipNbr: string;
}

export interface RateQuoteWithListInfo {
  rateQuoteRequest: RateQuoteRequest[];
  listInfo: ListInfo;
}

export interface PickupServicesCommon {
  exhibition: AbstractControl;
  inside: AbstractControl;
  liftGateService: AbstractControl;
  containerStation: AbstractControl;
  residential: AbstractControl;
  sorting: AbstractControl;
}

export interface PickupServicesOthers {
  consolidation: AbstractControl;
  inspection: AbstractControl;
  reconsignment: AbstractControl;
  construction: AbstractControl;
  mineGovAirp: AbstractControl;
  weigh: AbstractControl;
  holidayWeekend: AbstractControl;
  piers: AbstractControl;
}

export interface SpecialDeliveryServicesCommom {
  liftGateService: AbstractControl;
  sorting: AbstractControl;
  notification: AbstractControl;
  inside: AbstractControl;
  exhibition: AbstractControl;
  residential: AbstractControl;
  containerStation: AbstractControl;
  construction: AbstractControl;
  mineGovAirp: AbstractControl;
  appointment: AbstractControl;
  timeDateCritical: AbstractControl;
  holidayWeekend: AbstractControl;
  afterBusinessHoursDelivery: AbstractControl;
  borderCrossingServiceDelivery: AbstractControl;
}

export interface SpecialDeliveryServicesOther {
  consolidation: AbstractControl;
  reconsignment: AbstractControl;
  redelivery: AbstractControl;
  piers: AbstractControl;
}

export interface OtherSpecialServicesMicellaneous {
  instantGuaranteedService: AbstractControl;
  guaranteedByNoon: AbstractControl;
  blindShipment: AbstractControl;
  correctiontoBol: AbstractControl;
  codCancelChange: AbstractControl;
  returnUndeliveredShipment: AbstractControl;
  returnCheck: AbstractControl;
  freezableProtection: AbstractControl;
  rapidRemoteService: AbstractControl;
  excessiveLength: AbstractControl;
  excessiveLengthList: AbstractControl;
  excessiveValueCharge: AbstractControl;
  excessiveValueChargeValue: AbstractControl;
}

export interface CommodityLineFields {
  totalPalletCount: AbstractControl;
  totalLinealFeet: AbstractControl;
  singleShipment: AbstractControl;
}

export interface CommodityListFields {
  classValue: AbstractControl;
  weight: AbstractControl;
  length: AbstractControl;
  width: AbstractControl;
  height: AbstractControl;
  numberOfUnits: AbstractControl;
  hazmat: AbstractControl;
  nmfcCode: AbstractControl;
}

export interface ShipperGroupManualOffshore {
  company: AbstractControl;
  postalCode: AbstractControl;
  state: AbstractControl;
  address: AbstractControl;
  city: AbstractControl;
  sic: AbstractControl;
}

export interface ConsigneeGroupManualOffshore {
  company: AbstractControl;
  postalCode: AbstractControl;
  state: AbstractControl;
  address: AbstractControl;
  city: AbstractControl;
  sic: AbstractControl;
}

export interface CustomerManualOffshore {
  name: string;
  accCode: string;
  accNbr: number;
  customerRole: string;
  extraText?: string;
}

export interface CommodityListFieldsManualOffshore {
  classValue: AbstractControl;
  weight: AbstractControl;
  length: AbstractControl;
  width: AbstractControl;
  height: AbstractControl;
  numberOfUnits: AbstractControl;
  typeOfHandlingUnit: AbstractControl;
  stackable: AbstractControl;
  description: AbstractControl;
}

export interface ContactInformationManualOffShore {
  customerShipperRole: AbstractControl;
  customerName: AbstractControl;
  companyName: AbstractControl;
  customerEmail: AbstractControl;
  competitiveMarket: AbstractControl;
  customerPhoneCountry: AbstractControl;
  customerPhoneNumber: AbstractControl;
  customerPhoneExt: AbstractControl;
  customerFax: AbstractControl;
  customerFaxCountry: AbstractControl;
  comments: AbstractControl;
}

export interface RateQuoteManualOffshoreEmail {
  subtitle: string;
  shipper: ShipperGroupManualOffshore;
  consignee: ConsigneeGroupManualOffshore;
  contactInformation: ContactInformationManualOffShore;
  dateTimeEmailSend: string;
  user: User;
  paymentTerms: PaymentTermsText;
  accesorials: RatingAccessorial[];
  commodities: CommodityListFieldsManualOffshore[];
  shipmentDate: string;
  employeeNotes: string;
  offshorePlace: string;
  testingNote?: string;
  customer?: CustomerManualOffshore;
}

export interface RateQuoteManualOffshoreEmailSelectedState {
  isHawaii?: boolean;
  isPuertoRico?: boolean;
  isAlaska?: boolean;
}

export interface RateQuoteManualOffshoreEmailTextToReplace {
  hawaii?: string;
  puertoRico?: string;
  alaska?: string;
}

export abstract class DensityCalculatorValues {
  static standard(linkUrl: string): LinkButtonInputs {
    return {
      buttonName: 'Density and Class Calculator',
      iconName: 'calculator',
      linkUrl,
    };
  }
}

export interface AccountInformationForm {
  name: AbstractControl;
  address: AbstractControl;
  accountMdCode: AbstractControl;
  city: AbstractControl;
  ship: AbstractControl;
  postalCode: AbstractControl;
  paymentTerms: AbstractControl;
}

export interface InternationalShippingInformationForm {
  tariff: AbstractControl;
  discountLevel: AbstractControl;
  city: AbstractControl;
  ship: AbstractControl;
  postalCode: AbstractControl;
}

export class SpecialServicesInternationalInterface {
  [accColKey: string]: FormGroup;
}

export interface SpecialServicesInternationalAccesorialItems {
  insidePickup: AbstractControl;
  liftGateServicePickup: AbstractControl;
  containerStationPickup: AbstractControl;
  residentialPickup: AbstractControl;
  sortingPickup: AbstractControl;
  consolidationPickup: AbstractControl;
  inspectionPickup: AbstractControl;
  reconsignmentPickup: AbstractControl;
  constructionPickup: AbstractControl;
  mineGovAirpPickup: AbstractControl;
  weighPickup: AbstractControl;
  holidayWeekendPickup: AbstractControl;
  piersPickup: AbstractControl;
  liftGateServiceDelivery: AbstractControl;
  sortingDelivery: AbstractControl;
  notificationDelivery: AbstractControl;
  insideDelivery: AbstractControl;
  residentialDelivery: AbstractControl;
  containerStationDelivery: AbstractControl;
  constructionDelivery: AbstractControl;
  mineGovAirpDelivery: AbstractControl;
  holidayWeekendDelivery: AbstractControl;
  consolidationDelivery: AbstractControl;
  reconsignmentDelivery: AbstractControl;
  redeliveryDelivery: AbstractControl;
  piersDelivery: AbstractControl;
  borderCrossingService: AbstractControl;
  excessiveLength: AbstractControl;
  excessiveLengthList: AbstractControl;
  excessiveValueCharge: AbstractControl;
  excessiveValueChargeValue: AbstractControl;
}

export interface InternationalCommodityListFields {
  classValue: AbstractControl;
  weight: AbstractControl;
  volume: AbstractControl;
  nmfcCode: AbstractControl;
  commodity: AbstractControl;
}

export interface InternationalCommodityFields {
  anticipatedShippingDate: AbstractControl;
  weightDimention: AbstractControl;
  volumeDimention: AbstractControl;
  singleShipment: AbstractControl;
}

export class AddRateQuoteDateValidation {
  maxDate: number;
  minDate: number;
  maxMessage: string;
  minMessage: string;
}

export class RateQuoteSearchCustomerSpecificFields {
  customerName: AbstractControl;
  customerMadcode: AbstractControl;
  customerCity: AbstractControl;
  customerState: AbstractControl;
}

export interface RateQuoteSearchCustomerSpecificForApi {
  name?: string;
  city?: string;
  state?: string;
  account?: string;
  customerTypes?: CustomerTypeCd[];
}

export interface RateQuoteSearchCustomerSpecificMadCdForApi {
  madCd: string;
  customerTypes: CustomerFunctionCd[];
}

export interface RatequoteSearchCustomerTypes {
  pickupDelivery: CustomerTypeCd.PICKUP_DELIVERY | CustomerFunctionCd.PICKUP_OR_DELIVERY;
  billTo: CustomerFunctionCd.BILL_TO | CustomerTypeCd.BILL_TO;
}

// SPOT QUOTE
export interface ShipperConsigneeGroupSpotQuote {
  name: AbstractControl;
  state: AbstractControl;
  postalCode: AbstractControl;
}

export interface CommodityListFieldsSpotQuote {
  classField: AbstractControl;
  weight: AbstractControl;
  unitsToHandle: AbstractControl;
  typeOfHandlingUnit: AbstractControl;
  length: AbstractControl;
  width: AbstractControl;
  height: AbstractControl;
  description: AbstractControl;
  stackable: AbstractControl;
}

export interface ContactInformationSpotQuote {
  customerShipperRole: AbstractControl;
  customerContactName: AbstractControl;
  customerCompanyName: AbstractControl;
  customerEmail: AbstractControl;
  competitiveCharges: AbstractControl;
  customerPhoneCountry: AbstractControl;
  customerPhoneNumber: AbstractControl;
  customerPhoneExt: AbstractControl;
  customerFaxCountry: AbstractControl;
  customerFax: AbstractControl;
}

export interface RateQuoteSelectInterface {
  value: string | number | boolean;
  text: string;
}

export interface ShipOptionsInterface {
  toDestination: RateQuoteSelectInterface;
  fromOrigin: RateQuoteSelectInterface;
}

export interface RateQuoteSummaryHeaderCommodity {
  numberOfUnits?: number;
  length?: number;
  width?: number;
  height?: number;
  weight: number;
}

export interface CreatedRateQuote {
  createRateQuoteResp: CreateRateQuoteResp;
  recalculateRateQuoteTypes?: RecalculateRateQuoteTypes;
}

export interface AccessorialField {
  [key: string]: boolean
}
