<form [formGroup]="form" xpoDialog class="dialog-email">
  <h1 mat-dialog-title class="dialog-email__title">
    Send Email
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>

  <div mat-dialog-content class="dialog-email__content">
    <div class="dialog-email__content__email">
      <ng-container
        *ngFor="let emailList of (form?.controls)[formFieldsNames.emailList]?.controls; let i = index"
        [formGroup]="emailList"
      >
        <mat-form-field floatLabel="always">
          <mat-label>Email address</mat-label>
          <input
            matInput
            [formControlName]="formFieldsNames.email"
            id="{{ formFieldsId[formFieldsNames.email] }}--{{ i }}"
          />
        </mat-form-field>
        <mat-error
          *ngIf="fieldEmailErrorMessage(formFieldsNames.email, i) as error"
          [id]="formFieldsErrorId[formFieldsNames.email] + i"
          class="error"
        >
          <xpo-icon iconName="error"></xpo-icon>
          {{ error }}
        </mat-error>
      </ng-container>
      <mat-error
        *ngIf="fieldErrorMessage(formFieldsNames.emailList) as error"
        [id]="formFieldsErrorId[formFieldsNames.emailList]"
        class="error"
      >
        <xpo-icon iconName="error"></xpo-icon>
        {{ error }}
      </mat-error>
    </div>
    <div>
      <a xpo-regular-link (click)="addMoreEmails()" [attr.disabled]="!canAddMoreEmails()" id="addMoreBtn">
        <xpo-icon iconName="add-alt"></xpo-icon>Add Another Email
      </a>
    </div>
    <div class="dialog-email__content__email__copy">
      <mat-checkbox [formControlName]="formFieldsNames.sendMeCopy" class="dialog-email__content__email__copy__checkbox">
        Send copy to my email
      </mat-checkbox>
    </div>
    <mat-form-field floatLabel="always" class="dialog-email__comment">
      <mat-label>Email Body</mat-label>
      <textarea
        matInput
        rows="4"
        [formControlName]="formFieldsNames.emailBody"
        [id]="formFieldsId[formFieldsNames.emailBody]"
      ></textarea>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-flat-button xpoButton (click)="sendEmailClick()" id="sendMailBtn">SEND</button>
    <button mat-stroked-button xpoButton (click)="close()" id="cancelBnt">CANCEL</button>
  </div>
</form>
