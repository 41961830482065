import { Injectable } from '@angular/core';
import { InfrastructureRatingService } from '@core/services/infrastructure/infrastructure-rating.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorModel } from '@shared/models/error';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { InfrastructureActions } from '../actions/infrastructure.actions';

@Injectable()
export class InfrastructureEffects {
  sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InfrastructureActions.sendEmail),
      mergeMap(({ request }) => {
        return this.infrastructureRatingService.sendEmail(request).pipe(
          map(() => InfrastructureActions.sendEmailSuccess()),
          catchError((errors: ErrorModel[]) => of(InfrastructureActions.sendEmailFail({ errors })))
        );
      })
    )
  );

  constructor(private actions$: Actions, private infrastructureRatingService: InfrastructureRatingService) {}
}
