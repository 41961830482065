import { ErrorModel } from '@shared/models/error';
import { WhatIfRequests, WhatIfRequestsAgrements } from '@shared/models/what-if-requests';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { requestersAllMock } from '@shared/utils/mocks/user.mock';
import { WhatIfRatingRequester } from '@xpo-ltl-2.0/sdk-rating';
import _ from 'lodash';
import * as WhatIfActions from '../actions/what-if-requests.actions';

export const whatIfRequestsFeatureKey = 'whatIfRequests';
export const whatIfRequestsFeatureSelector = createFeatureSelector<WhatIfRequestsState>(whatIfRequestsFeatureKey);

export interface WhatIfRequestsState extends EntityState<WhatIfRequests> {
  loadingGetAll: boolean;
  loadingAddOne: boolean;
  loadingUsersRequesters: boolean;
  loadingGetAccounts: boolean;
  loadingGetAccountsOverride: boolean;
  usersRequesters: WhatIfRatingRequester[];
  currentRequester: WhatIfRatingRequester;
  agreements: WhatIfRequestsAgrements[];
  loadingAgreements: boolean;
  agreementsErrorMessage: string;
  agreementsIdOverride: WhatIfRequestsAgrements[];
  loadingAgreementsOverride: boolean;
  agreementsErrorMessageOverride: string;
  errorAddOne: ErrorModel[];
  selectedWhatIfDetail: WhatIfRequests;
  requestsOnGrid: number[];
}

export const whatIfRequestsAdapter: EntityAdapter<WhatIfRequests> = createEntityAdapter<WhatIfRequests>({
  selectId: (whatIfRequests: WhatIfRequests) => whatIfRequests.ratingRequestId,
});

export const whatIfRequestsInitialState: WhatIfRequestsState = whatIfRequestsAdapter.getInitialState({
  loadingGetAll: false,
  loadingAddOne: false,
  loadingUsersRequesters: false,
  loadingGetAccounts: false,
  loadingGetAccountsOverride: false,
  usersRequesters: [],
  currentRequester: undefined,
  agreements: undefined,
  loadingAgreements: false,
  agreementsErrorMessage: undefined,
  agreementsIdOverride: undefined,
  loadingAgreementsOverride: false,
  agreementsErrorMessageOverride: undefined,
  errorAddOne: undefined,
  selectedWhatIfDetail: undefined,
  requestsOnGrid: undefined,
});

export const reducer = createReducer(
  whatIfRequestsInitialState,
  on(WhatIfActions.whatIfRequestsClearStore, (state) => {
    return {
      ...whatIfRequestsInitialState,
    };
  }),
  on(WhatIfActions.whatIfRequestsGetAll, (state, { selectedUser }) => {
    return whatIfRequestsAdapter.setAll([], {
      ...state,
      loadingGetAll: true,
      currentRequester: selectedUser,
      requestsOnGrid: undefined,
    });
  }),
  on(WhatIfActions.whatIfRequestsGetAllSuccess, (state, { whatIfRequestsList }) => {
    return whatIfRequestsAdapter.setAll(whatIfRequestsList || [], {
      ...state,
      loadingGetAll: false,
    });
  }),
  on(WhatIfActions.whatIfRequestsGetAllFail, (state) => ({
    ...state,
    loadingGetAll: false,
  })),
  on(WhatIfActions.whatIfRequestsAdd, (state, { whatIfRequest }) => {
    return {
      ...state,
      loadingAddOne: true,
    };
  }),
  on(WhatIfActions.whatIfRequestsAddSuccess, (state, { whatIfRequest }) => {
    return {
      ...state,
      loadingAddOne: false,
    };
  }),
  on(WhatIfActions.whatIfRequestsAddFail, (state, { error }) => ({
    ...state,
    loadingAddOne: false,
    errorAddOne: error,
  })),
  on(WhatIfActions.whatIfGetUsersRequesters, (state, { defaultUser }) => ({
    ...state,
    loadingUsersRequesters: true,
    usersRequesters: defaultUser ? [defaultUser] : [],
  })),
  on(WhatIfActions.whatIfGetUsersRequestersSuccess, (state, { usersRequesters }) => {
    const defaultUser: WhatIfRatingRequester = state.usersRequesters[0];
    const loggedUser: WhatIfRatingRequester =
      usersRequesters.find(
        (requester: WhatIfRatingRequester) => requester.requestedByName === defaultUser?.requestedByName
      ) || defaultUser;
    const users: WhatIfRatingRequester[] = _.union([loggedUser], requestersAllMock, usersRequesters);

    return {
      ...state,
      loadingUsersRequesters: false,
      usersRequesters: users,
    };
  }),
  on(WhatIfActions.whatIfGetUsersRequestersFail, (state) => ({
    ...state,
    loadingUsersRequesters: false,
    usersRequesters: [],
  })),
  on(WhatIfActions.whatIfGetAgreementIdFiltered, (state) => ({
    ...state,
    agreements: undefined,
    loadingAgreements: true,
  })),
  on(WhatIfActions.whatIfGetAgreementIdFilteredSuccess, (state, { agreements }) => ({
    ...state,
    loadingAgreements: false,
    agreements,
    agreementsErrorMessage: undefined,
  })),
  on(WhatIfActions.whatIfGetAgreementIdFilteredFail, (state, { errorMessage }) => ({
    ...state,
    loadingAgreements: false,
    agreementsErrorMessage: errorMessage,
  })),
  on(WhatIfActions.whatIfGetAgreementIdFilteredOverride, (state) => ({
    ...state,
    agreementsIdOverride: undefined,
    loadingAgreementsOverride: true,
  })),
  on(WhatIfActions.whatIfGetAgreementIdFilteredOverrideSuccess, (state, { agreements }) => ({
    ...state,
    loadingAgreementsOverride: false,
    agreementsIdOverride: agreements,
    agreementsErrorMessageOverride: undefined,
  })),
  on(WhatIfActions.whatIfGetAgreementIdFilteredOverrideFail, (state, { errorMessage }) => ({
    ...state,
    loadingAgreementsOverride: false,
    agreementsErrorMessageOverride: errorMessage,
  })),
  on(WhatIfActions.whatIfRequestsGetDetail, (state, { ratingRequestId }) => {
    return {
      ...state,
      selectedWhatIfDetail: state.entities[ratingRequestId],
    };
  }),
  on(WhatIfActions.whatIfSetRequestsOnGrid, (state, { requestIds }) => {
    return {
      ...state,
      requestsOnGrid: requestIds,
    };
  }),
  on(WhatIfActions.whatIfGetAccountsFiltered, (state) => {
    return {
      ...state,
      loadingGetAccounts: true,
    };
  }),
  on(WhatIfActions.whatIfGetAccountsFilteredSuccess, WhatIfActions.whatIfGetAccountsFilteredFail, (state) => {
    return {
      ...state,
      loadingGetAccounts: false,
    };
  }),
  on(WhatIfActions.whatIfGetAccountsOverrideFiltered, (state) => {
    return {
      ...state,
      loadingGetAccountsOverride: true,
    };
  }),
  on(
    WhatIfActions.whatIfGetAccountsOverrideFilteredSuccess,
    WhatIfActions.whatIfGetAccountsOverrideFilteredFail,
    (state) => {
      return {
        ...state,
        loadingGetAccountsOverride: false,
      };
    }
  )
);
