import { createSelector } from '@ngrx/store';
import {
  whatIfRequestsAdapter,
  whatIfRequestsFeatureSelector,
  WhatIfRequestsState,
} from '../reducers/what-if-requests.reducer';

const { selectAll } = whatIfRequestsAdapter.getSelectors();

const whatIfRequestsSelector = createSelector(whatIfRequestsFeatureSelector, (state) => state);

export const selectWhatIfRequestsGetAll = createSelector(whatIfRequestsFeatureSelector, selectAll);

// Get All **********************************************************************
export const selectWhatIfRequestsGetAllToTable = createSelector(
  selectWhatIfRequestsGetAll,
  (whatIfRequests) => whatIfRequests
);

// Get Detail **********************************************************************
export const selectWhatIfRequestsGetDetail = createSelector(
  whatIfRequestsSelector,
  (whatIfRequests: WhatIfRequestsState) => whatIfRequests?.selectedWhatIfDetail
);

// Users *************************************************************************
export const selectWhatIfUsersRequesters = createSelector(
  whatIfRequestsSelector,
  (whatIfRequests: WhatIfRequestsState) => whatIfRequests?.usersRequesters
);

// Add *******************************************************************************
export const selectWhatIfRequestsAddLoading = createSelector(
  whatIfRequestsSelector,
  (whatIfRequests: WhatIfRequestsState) => whatIfRequests?.loadingAddOne || false
);

// Delete ************************************************************************
export const selectWhatIfRequestsSomeGetActionIsLoading = createSelector(
  whatIfRequestsSelector,
  (whatIfRequests: WhatIfRequestsState) => whatIfRequests?.loadingGetAll
);

// GetCurrentRequester Selectors **********************************************************
export const selectWhatIfCurrentRequester = createSelector(
  whatIfRequestsSelector,
  (whatIfRequests: WhatIfRequestsState) => whatIfRequests?.currentRequester
);

// Get request id on grid **********************************************************
export const selectWhatIfRequestIdOnGrid = createSelector(
  whatIfRequestsSelector,
  (whatIfRequests: WhatIfRequestsState) => whatIfRequests?.requestsOnGrid
);

// Get Account loading **********************************************************
export const selectWhatIfRequestGetAccountLoading = createSelector(
  whatIfRequestsSelector,
  (whatIfRequests: WhatIfRequestsState) => whatIfRequests?.loadingGetAccounts
);

export const selectWhatIfRequestGetAccountOverrideLoading = createSelector(
  whatIfRequestsSelector,
  (whatIfRequests: WhatIfRequestsState) => whatIfRequests?.loadingGetAccountsOverride
);

// Get Agreements loading **********************************************************
export const selectWhatIfRequestGetAgreementsLoading = createSelector(
  whatIfRequestsSelector,
  (whatIfRequests: WhatIfRequestsState) => whatIfRequests?.loadingAgreements
);

export const selectWhatIfRequestGetAgreementsOverrideLoading = createSelector(
  whatIfRequestsSelector,
  (whatIfRequests: WhatIfRequestsState) => whatIfRequests?.loadingAgreementsOverride
);
