import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Tariff } from '@xpo-ltl/sdk-tariffmanagement';
import { Observable, of } from 'rxjs';
import { FormFieldAutocompleteParentComponent } from '../form-field-autocomplete-parent-component';

@Component({
  selector: 'app-tariff-form-field',
  templateUrl: './tariff-form-field.component.html',
  styleUrls: ['./tariff-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TariffFormFieldComponent extends FormFieldAutocompleteParentComponent implements OnInit, OnDestroy {
  @Input() labelName: string = 'Tariff';
  @Input() idTariff = 'tariff';
  @Input() errorId = 'tariffError';
  @Input() errorMessage: string;
  @Input() placeHolder; // Optional, if the attribute is empty the component doesn't display a place holder
  @Input() tariffList$ = of();
  @Input() loading$: Observable<true> = of(); // Optional, if the attribute is empty the component doesn't disabled the field when is loading
  @Input() displayPlaceHolderLoading: boolean = true; // Optional, by default is true, if the attribute is false the component doesn't display the placeHolderLoading when is loading
  @Input() messageWithinField: string;

  constructor(public cdr: ChangeDetectorRef, @Optional() @Self() public ngControl: NgControl) {
    super(ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.listenChangeValue();
    this.inheritValidators();
    this.enableOrDisableField();
    this.enableOrDisableWhenIsLoading();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  // FORM METHODS
  enableOrDisableWhenIsLoading() {
    super.enableOrDisableWhenIsLoading(this.loading$, this.displayPlaceHolderLoading);
  }

  // NGFOR METHODS
  trackByTariff(index: number, tariff: Tariff): string {
    return tariff.tariff;
  }
}
