import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ContentPagination } from '@shared/models/content-pagination';
import { WhatIfRequests } from '@shared/models/what-if-requests';
import { CustomerMatch } from '@xpo-ltl-2.0/sdk-customer';
import { CreateWhatIfRatingRqst, WhatIfRatingRequester } from '@xpo-ltl-2.0/sdk-rating';
import { RatingShipmentFileTypeCd, WhatIfDownloadFileTypeCd } from '@xpo-ltl/sdk-common';
import { merge, Observable } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';
import { CloneDataInDeep } from 'typescript-clone-data-in-deep';
import * as WhatIfActions from '../actions/what-if-requests.actions';
import * as WhatIfSelectors from '../selectors/what-if-requests.selectors';

@Injectable({
  providedIn: 'root',
})
export class WhatIfRequestsHandlerService {
  // ACTIONS #############################################################################################################
  whatIfRequestsGetAllFail$ = this.actions$.pipe(ofType(WhatIfActions.whatIfRequestsGetAllFail));
  whatIfRequestsGetAllSuccess$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfRequestsGetAllSuccess),
    map((action) => action.whatIfRequestsList)
  );

  whatIfRequestsDeleteFail$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfRequestsDeleteFail),
    map((action) => action.errors)
  );
  whatIfRequestsDeleteSuccess$ = this.actions$.pipe(ofType(WhatIfActions.whatIfRequestsDeleteSuccess));

  getUsersRequestersFail$ = this.actions$.pipe(ofType(WhatIfActions.whatIfGetUsersRequestersFail));

  addRequestFail$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfRequestsAddFail),
    map((action) => action.error)
  );
  addRequestSuccess$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfRequestsAddSuccess),
    map((action) => action.whatIfRequest)
  );

  // get filtered agreements actions *************************************************************************************************
  agreementsFilteredSuccessAction$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfGetAgreementIdFilteredSuccess),
    map((action) => action.agreements)
  );

  agreementsFilteredErrorAction$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfGetAgreementIdFilteredFail),
    map((action) => action.errorMessage)
  );

  agreementsOverrideFilteredSuccessAction$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfGetAgreementIdFilteredOverrideSuccess),
    map((action) => action.agreements)
  );

  agreementsOverrideFilteredErrorAction$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfGetAgreementIdFilteredOverrideFail),
    map((action) => action.errorMessage)
  );

  // get filtered accounts actions *************************************************************************************************
  accountsFilteredSuccessAction$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfGetAccountsFilteredSuccess),
    map((action) => action.accounts)
  );

  accountsFilteredErrorAction$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfGetAccountsFilteredFail),
    map((action) => action.errors)
  );

  accountsFilteredOverrideSuccessAction$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfGetAccountsOverrideFilteredSuccess),
    map((action) => action.accounts)
  );

  accountsFilteredOverrideErrorAction$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfGetAccountsOverrideFilteredFail),
    map((action) => action.errors)
  );

  // downloadShipmentFile Actions ******************************************************************************************
  downloadShipmentFileSuccess$ = this.actions$.pipe(ofType(WhatIfActions.whatIfRequestsDownloadShipmentFileSuccess));

  downloadAnalysisResultSuccess$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfRequestsDownloadAnalysisResultSuccess)
  );

  downloadShipmentFileFail$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfRequestsDownloadShipmentFileFail),
    map((action) => action.errors)
  );

  // downloadShipmentFile Actions ******************************************************************************************
  downloadShipmentFileTemplateSuccess$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfRequestsDownloadShipmentTemplateSuccess),
    map((action) => action.fileData)
  );

  downloadShipmentFileTemplateFail$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfRequestsDownloadShipmentTemplateFail),
    map((action) => action.errors)
  );

  setRequestsOnGridSuccess$ = this.actions$.pipe(
    ofType(WhatIfActions.whatIfSetRequestsOnGrid),
    map((action) => action.requestIds)
  );

  // SELECTORS ########################################################################################################
  whatIfRequestsSelector$ = merge(this.whatIfRequestsGetAllSuccess$).pipe(
    concatMap(() => this.store$.pipe(select(WhatIfSelectors.selectWhatIfRequestsGetAllToTable)))
  );

  whatIfRequestsSomeGetActionIsLoadingSelector$ = this.store$.pipe(
    select(WhatIfSelectors.selectWhatIfRequestsSomeGetActionIsLoading)
  );

  // get WhatIfRequests Detail Selector *************************************************************
  whatIfRequestsGetDetailSelector$ = this.store$.pipe(select(WhatIfSelectors.selectWhatIfRequestsGetDetail));

  // Add selectors ***********************************************************
  addRequestLoadingSelector$ = this.store$.pipe(select(WhatIfSelectors.selectWhatIfRequestsAddLoading));

  // User Selectors *****************************************************************
  usersRequestersSelector$ = this.store$.pipe(select(WhatIfSelectors.selectWhatIfUsersRequesters));

  // User Selectors *****************************************************************
  currentRequesterSelector$ = this.store$.pipe(select(WhatIfSelectors.selectWhatIfCurrentRequester));

  // Get request id on grid **********************************************************
  requestIdOnGridSelector$ = this.store$.pipe(select(WhatIfSelectors.selectWhatIfRequestIdOnGrid));

  // Get loading accounts id on grid **********************************************************
  requestGetAccountLoading$ = this.store$.pipe(select(WhatIfSelectors.selectWhatIfRequestGetAccountLoading));
  requestGetAccountOverrideLoading$ = this.store$.pipe(
    select(WhatIfSelectors.selectWhatIfRequestGetAccountOverrideLoading)
  );

  // Get loading agreements id on grid **********************************************************
  requestGetAgreementLoading$ = this.store$.pipe(select(WhatIfSelectors.selectWhatIfRequestGetAgreementsLoading));
  requestGetAgreementOverrideLoading$ = this.store$.pipe(
    select(WhatIfSelectors.selectWhatIfRequestGetAgreementsOverrideLoading)
  );

  constructor(public store$: Store, public actions$: Actions) {}

  whatIfRequestsClearStore(): void {
    this.store$.dispatch(WhatIfActions.whatIfRequestsClearStore());
  }

  getAll(selectedUser: WhatIfRatingRequester): void {
    this.store$.dispatch(WhatIfActions.whatIfRequestsGetAll({ selectedUser }));
  }

  addRequest(whatIfRequestToAdd: Partial<CreateWhatIfRatingRqst>): void {
    const whatIfRequest: Partial<CreateWhatIfRatingRqst> = CloneDataInDeep.clone<Partial<CreateWhatIfRatingRqst>>(
      whatIfRequestToAdd
    );
    this.store$.dispatch(WhatIfActions.whatIfRequestsAdd({ whatIfRequest }));
  }

  delete(ratingRequestIds: number[]): void {
    this.store$.dispatch(WhatIfActions.whatIfRequestsDelete({ ratingRequestIds }));
  }

  getUsersRequesters(defaultUser: WhatIfRatingRequester): void {
    this.store$.dispatch(WhatIfActions.whatIfGetUsersRequesters({ defaultUser }));
  }

  getAccountsFiltered(filterData: string): void {
    this.store$.dispatch(WhatIfActions.whatIfGetAccountsFiltered({ filterData }));
  }

  getAccountsOverrideFiltered(filterData: string): void {
    this.store$.dispatch(WhatIfActions.whatIfGetAccountsOverrideFiltered({ filterData }));
  }

  getAgreementIdFiltered(filterData: number, accountValue: CustomerMatch): void {
    this.store$.dispatch(WhatIfActions.whatIfGetAgreementIdFiltered({ filterData, accountValue }));
  }

  getAgreementIdFilteredOverride(filterData: number, accountValue: CustomerMatch): void {
    this.store$.dispatch(WhatIfActions.whatIfGetAgreementIdFilteredOverride({ filterData, accountValue }));
  }

  getWhatIfRequestsGetDetail(ratingRequestId: number): void {
    this.store$.dispatch(WhatIfActions.whatIfRequestsGetDetail({ ratingRequestId }));
  }

  downloadShipmentFile(
    fileId: string,
    whatIfDownloadFileTypeCd: WhatIfDownloadFileTypeCd,
    whatIfRequests: WhatIfRequests
  ): void {
    this.store$.dispatch(
      WhatIfActions.whatIfRequestsDownloadShipmentFile({ fileId, whatIfDownloadFileTypeCd, whatIfRequests })
    );
  }

  downloadShipmentFileTemplate(templateFileTypeCd: RatingShipmentFileTypeCd): void {
    this.store$.dispatch(WhatIfActions.whatIfRequestsDownloadShipmentTemplate({ templateFileTypeCd }));
  }

  setRequestsOnGrid(requestIds: number[]): void {
    this.store$.dispatch(WhatIfActions.whatIfSetRequestsOnGrid({ requestIds }));
  }

  getNextRequestOnGrid(currentRequestId: number): Observable<number[]> {
    return this.requestIdOnGridSelector$.pipe(
      filter((requestsOnGrid: number[]) => requestsOnGrid?.length > 0),
      tap((requestsOnGrid: number[]) => {
        const currentIdIndex: number = requestsOnGrid.indexOf(currentRequestId);
        const nextIndexPosition: number = requestsOnGrid.length <= currentIdIndex + 1 ? 0 : currentIdIndex + 1;
        const nextRequestIdPosition: number = requestsOnGrid[nextIndexPosition];
        this.getWhatIfRequestsGetDetail(nextRequestIdPosition);
      })
    );
  }

  getPreviousRequestOnGrid(currentRequestId: number): Observable<number[]> {
    return this.requestIdOnGridSelector$.pipe(
      filter((requestsOnGrid: number[]) => requestsOnGrid?.length > 0),
      tap((requestsOnGrid: number[]) => {
        const currentIdIndex: number = requestsOnGrid.indexOf(currentRequestId);
        const previousIndexPosition: number = currentIdIndex === 0 ? requestsOnGrid.length - 1 : currentIdIndex - 1;
        const previousRequestIdPosition: number = requestsOnGrid[previousIndexPosition];
        this.getWhatIfRequestsGetDetail(previousRequestIdPosition);
      })
    );
  }

  getRequestOnGridInfoPagination(currentRequestId: number): Observable<ContentPagination> {
    return this.requestIdOnGridSelector$.pipe(
      map((requestsOnGrid: number[]) => {
        if (!requestsOnGrid || requestsOnGrid.length === 0) {
          return {
            currentPage: 0,
            totalPages: 0,
          };
        }
        const currentPage = requestsOnGrid.indexOf(currentRequestId) + 1;
        return {
          currentPage,
          totalPages: requestsOnGrid.length,
        };
      })
    );
  }
}
