import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WhatIfAuthorizedRedirectGuard } from '@core/guards/what-if-authorized-redirect/what-if-authorized-redirect.guard';
import { ClearStoreResolver } from '@core/resolvers/clear-store/clear-store.resolver';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { environment } from 'environments/environment';
import { LayoutComponent } from './core/components/layout/layout.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { MrraAuthorizedRedirectGuard } from '@core/guards/mrra-authorized-redirect/mrra-authorized-redirect.guard';

const skipCanActivate = !!window['Cypress'];

function disableAuth(): boolean {
  return environment.disableAuth && skipCanActivate;
}

const router: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: AppRoutes.whatIf.main,
        loadChildren: () => import('./what-if/what-if.module').then((module) => module.WhatIfModule),
        canActivate: disableAuth()
          ? [WhatIfAuthorizedRedirectGuard]
          : [XpoAuthenticationGuard, WhatIfAuthorizedRedirectGuard],
        resolve: {
          clearStore: ClearStoreResolver,
        },
      },
      {
        path: AppRoutes.rateQuote.main,
        loadChildren: () => import('./rate-quote/rate-quote.module').then((module) => module.RateQuoteModule),
        canActivate: [XpoAuthenticationGuard],
      },
      {
        path: AppRoutes.ratingAnalysis.main,
        loadChildren: () =>
          import('./rating-analysis/rating-analysis.module').then((module) => module.RatingAnalysisModule),
        canActivate: [XpoAuthenticationGuard],
      },
      {
        path: AppRoutes.manualRating.main,
        loadChildren: () => import('./manual-rating/manual-rating.module').then((module) => module.ManualRatingModule),
        canActivate: [XpoAuthenticationGuard],
      },
      {
        path: AppRoutes.mrra.main,
        loadChildren: () => import('./mrra/mrra.module').then((module) => module.MrraModule),
        canActivate: [XpoAuthenticationGuard, MrraAuthorizedRedirectGuard],
      },
      {
        path: '',
        redirectTo: `/${AppRoutes.mainRoute.getDefault()}`,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: AppRoutes.mainRoute.main,
    redirectTo: `/${AppRoutes.mainRoute.getDefault()}`,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.errorPages.main,
    loadChildren: () => import('./error-pages/error-pages.module').then((module) => module.ErrorPagesModule),
    canActivate: [XpoAuthenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(router, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
