import { AuditorSummary } from '@xpo-ltl-2.0/sdk-rating';

// ENUMS ********************************************************************
export enum ManualRatingQueueClassType {
  all = 'ALL',
  audit = 'A',
  entry = 'E',
}

export enum ManualRatingShipmentProQueryParamsEnum {
  proNumber = 'proNumber',
  proNbrRequestInd = 'proNbrRequestInd',
  queueName = 'queueName',
  queueClassCd = 'queueClassCd',
}

// CONSTANTS *****************************************************************
export const manualRatingQueueClasses = [
  { classType: ManualRatingQueueClassType.all, name: 'All' },
  { classType: ManualRatingQueueClassType.audit, name: 'Audit' },
  { classType: ManualRatingQueueClassType.entry, name: 'Entry' },
];

// INTERFACES ****************************************************************
export interface ManualRatingAuditorStats extends AuditorSummary {
  barWidth?: string;
}

export interface ManualRatingShipmentProInitData {
  proNumber: string;
  proNbrRequestInd: boolean;
  queueName: string;
  queueClassCd: string;
}

export interface ManualRatingAccessorial {
  code: string;
  description: string;
  effectiveDate: string;
  expirationDate: string;
}

export interface ManualRatingAuditorStatFilter {
  startDate: string;
  endDate: string;
}

// TYPES ***************************************************************************
export type ManualRatingGridDetailNames = {
  id: { attrName: string; label: string };
  class: { attrName: string; label: string };
  procs: { attrName: string; label: string };
  queue: { attrName: string; label: string };
  total: { attrName: string; label: string };
};

export type ManualRatingAuditorGridDetailNames = {
  proNumber: { attrName: string; label: string };
  queueName: { attrName: string; label: string };
  queueType: { attrName: string; label: string };
  lastUpdated: { attrName: string; label: string };
};
