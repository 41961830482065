import { createSelector } from '@ngrx/store';
import { ReleaseNotesConfig } from '@shared/models/release-notes';
import { userPreferenceFeatureSelector, UserPreferenceState } from '../reducer/user-preference.reducer';

const releaseNotes = createSelector(
  userPreferenceFeatureSelector,
  (state: UserPreferenceState) => state[ReleaseNotesConfig.componentName]
);

export const UserPreferenceSelectors = { releaseNotes };
