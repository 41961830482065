import { messagesConstant } from '@shared/constants/messages.constants';
import { CustomerTypeCdLabel } from '@shared/enums/rating.enum';
import { DateRatingUtils } from '@shared/utils/dates/date.utils';
import { CustomerMatch } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerTypeCd, WhatIfShipmentSelectionTypeCd } from '@xpo-ltl/sdk-common';
import { Tariff } from '@xpo-ltl/sdk-tariffmanagement';
import { RegexRules } from '../regex/regex';
import { ManualRatingProgressCardTitle } from '@shared/enums/manual-rating.enum';
import { ManualRatingQueueClassType } from '@shared/models/manual-rating';

export class TransformData {
  static removeEmptyFields<T>(dataWithAllFields: T): T {
    if (dataWithAllFields == null) {
      throw Error(messagesConstant.general.transformDataFunctionError.replace('{functionName}', '#TransformData'));
    }
    let dataWithoutEmptyFields: T = {} as T;
    for (const [key, value] of Object.entries(dataWithAllFields)) {
      if (
        value != null &&
        value !== '' &&
        JSON.stringify(value) !== JSON.stringify({}) &&
        JSON.stringify(value) !== JSON.stringify([])
      ) {
        dataWithoutEmptyFields[key] = value;
      }
    }
    return dataWithoutEmptyFields;
  }

  static getAgreementSuffix(agreementSuffix: string): string {
    return ('0' + agreementSuffix).slice(-2);
  }

  static getAgreementVersion(agreementVersionNbr: string): string {
    return ('00' + agreementVersionNbr).slice(-3);
  }

  static createAgreementId(
    customerId: number,
    customerAgreementSuffixNbr: string,
    agreementVersionNbr: string
  ): string {
    if (!customerId || !customerAgreementSuffixNbr || !agreementVersionNbr) {
      throw Error(messagesConstant.general.transformDataGetAgreementIdError);
    }
    const customerAgmtSuffixNbr: string = TransformData.getAgreementSuffix(customerAgreementSuffixNbr);
    const agmtVersionNbr: string = TransformData.getAgreementVersion(agreementVersionNbr);
    return `${customerId}-${customerAgmtSuffixNbr}-${agmtVersionNbr}`;
  }

  static getAgreementId(
    customerId: number,
    customerAgreementSuffixNbr: string,
    agreementVersionNbr: string,
    shipmentSelectionTypeCd: WhatIfShipmentSelectionTypeCd
  ): string {
    if (shipmentSelectionTypeCd !== WhatIfShipmentSelectionTypeCd.AGREEMENT_ID) {
      return;
    }
    return TransformData.createAgreementId(customerId, customerAgreementSuffixNbr, agreementVersionNbr);
  }

  static getOverrideAgreementId(
    overrideAgreementCustomerId: number,
    overrideCustomerAgreementSuffixNbr: string,
    overrideAgreementVersionNbr: string
  ): string {
    if (!overrideAgreementCustomerId) {
      return;
    }
    return TransformData.createAgreementId(
      overrideAgreementCustomerId,
      overrideCustomerAgreementSuffixNbr,
      overrideAgreementVersionNbr
    );
  }

  static getAccountInstIdFromAccountWithoutHypen(value: string): string {
    return value.split('-')[0];
  }

  static getAccountToDisplayInAutoComplete(account: CustomerMatch): string {
    if (!account) {
      return;
    }
    const madCd: string = account.madCd ? `${account.madCd} - ` : '';
    const name2 = account.name2 ? ' ' + account.name2 : '';
    return `${madCd}${account.customerNbr} - ${account.name1}${name2}`;
  }

  static getAccountToDisplayInAutoCompleteLongFormat(account: CustomerMatch): string {
    if (!account) {
      return;
    }
    const madCd: string = account.madCd ? `${account.madCd}, ` : '';
    const customerTypeCd: string = account.customerTypeCd === CustomerTypeCd.PICKUP_DELIVERY ? CustomerTypeCdLabel.pickupDelivery : CustomerTypeCdLabel.billTo;
    return `${madCd}${account.customerNbr}, ${account.name1}, ${account.addressLine1}, ${account.cityName}, ${account.stateCd} ${account.postalCd}, (${customerTypeCd})`;
  }

  static addAgreementFieldSearchCriteria(agreement: string): string {
    return `${agreement}-`;
  }

  static findCustomerObjBySelectedAccount(
    accountsTypeaheadList: CustomerMatch[],
    accountValueFromField: string
  ): CustomerMatch {
    return accountsTypeaheadList.find(
      (account: CustomerMatch) => TransformData.getAccountToDisplayInAutoComplete(account) === accountValueFromField
    );
  }

  static sortTariffByName(tariffList: Tariff[]): Tariff[] {
    return tariffList.sort((firstTariff: Tariff, secondTariff: Tariff) => {
      const firstTariffName: string = firstTariff.tariff?.toLowerCase();
      const secondTariffName: string = secondTariff.tariff?.toLowerCase();

      const firstTariffEffectiveDate: Date = DateRatingUtils.getDateFromJulianDate(firstTariff.effectiveDate);
      const secondTariffEffectiveDate: Date = DateRatingUtils.getDateFromJulianDate(secondTariff.effectiveDate);

      if (firstTariffName < secondTariffName) {
        return -1;
      } else if (firstTariffName > secondTariffName) {
        return 1;
      }

      // When the tariff name are the same the application sort by effective date in desc
      if (firstTariffEffectiveDate > secondTariffEffectiveDate || !secondTariffEffectiveDate) {
        return -1;
      } else if (firstTariffEffectiveDate < secondTariffEffectiveDate || !firstTariffEffectiveDate) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  static getPostalCode(postalCodeValue: string): string {
    return postalCodeValue?.replace(RegexRules.findBlankSpaces, '').toUpperCase();
  }

  static getOnlyNumbersFromString(value: string): string {
    return value?.replace(/\D/g, '');
  }

  static getQueueTypeTitleByCode(queueTypeCd: ManualRatingQueueClassType): string {
    switch (queueTypeCd) {
      case ManualRatingQueueClassType.audit:
        return ManualRatingProgressCardTitle.audit;
      case ManualRatingQueueClassType.entry:
        return ManualRatingProgressCardTitle.entry;
      default:
        return queueTypeCd;
    }
  }
}
