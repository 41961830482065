import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserHandlerService } from '@app-store/user/handlers/user-handler.service';
import { ApplicationUtilsService } from '@core/services/application-utils/application-utils.service';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { User } from '@xpo-ltl/sdk-common';
import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { interval } from 'rxjs';
import { filter, skipWhile, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  build: string;

  // applicationCategories: XpoApplicationCategory[] = [];

  constructor(
    public authService: XpoLtlAuthenticationService,
    public applicationUtilsService: ApplicationUtilsService,
    private userHandlerService: UserHandlerService
  ) {
    /** Shell setup */
    this.build = this.applicationUtilsService.buildVersion;
  }

  ngOnInit(): void {
    this.setRegion();
    this.getLoggedUser();
  }

  setRegion(): void {
    const region = this.applicationUtilsService.region;
    this.authService.initAuthSetup$(region).pipe(take(1)).subscribe();
  }

  getLoggedUser(): void {
    this.userHandlerService.getUserSelector$
      .pipe(
        filter((user: User) => !!user),
        take(1)
      )
      .subscribe((user: User) => this.setDynatraceUserIdentity(user));
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(
        window['dtrum'],
        'identifyUser',
        !_isEmpty(user.emailAddress) ? user.emailAddress : !_isEmpty(user.userId) ? user.userId : 'Rating_User'
      );

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }

  /*
    handleFeedbackClick(): void {
      this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe((config) => {
        this.dialog.open(XpoFeedback, { data: config });
      });
    }
  */

  // handleReleaseNotesClick(): void {
  //   this.releaseNotesService.showReleaseNotes().subscribe(() => { });
  // }

  // setApplicationCategories(): void {
  //   this.applicationCategories = (mockAppData as any).default;
  // }

  // onFavoriteApplicationChange(application: XpoApplication): void {
  //   // add custom logic here
  //   console.log(application);
  // }
}
