import { ApplicationRoles } from '@shared/models/user';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { User } from '@xpo-ltl/sdk-common';
import { UserActions } from '../actions/user.actions';

export const userFeatureKey = 'userSession';
export const userFeatureSelector = createFeatureSelector<UserState>(userFeatureKey);

export interface UserState {
  user: User;
  loadingGetUser: boolean;
  requiredRolesCurrentPage: ApplicationRoles;
}

export const userInitState: UserState = {
  user: undefined,
  loadingGetUser: false,
  requiredRolesCurrentPage: undefined,
};

export const reducer = createReducer(
  userInitState,
  on(UserActions.getUser, (state) => {
    return {
      ...state,
      loadingGetUser: true,
    };
  }),
  on(UserActions.getUserSuccess, (state, { user }) => {
    return {
      ...state,
      user,
      loadingGetUser: false,
    };
  }),
  on(UserActions.getUserFail, (state) => {
    return {
      ...state,
      loadingGetUser: false,
    };
  }),
  on(UserActions.setRequiredRolesCurrentPage, (state, { requiredRolesCurrentPage }) => {
    return {
      ...state,
      requiredRolesCurrentPage,
    };
  })
);
