import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkButtonInputs } from '@shared/models/button';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkButtonComponent {
  @Input() linkButtonInputs: LinkButtonInputs;
  @Input() buttonId: string = 'linkButton';
  @Input() tabIndex: number = -1;

  constructor() {}

  openLink(): void {
    window.open(this.linkButtonInputs.linkUrl);
  }
}
