import { ApplicationRoles } from '@shared/models/user';

/**
 * Logged in User Role
 *  - Update roles based on your app's needs!
 */
export enum UserRole {
  User = 'User',
  SuperUser = 'Super User',
}

export const WhatIfRoles: ApplicationRoles = {
  user: {
    key: 'LTL_RATING_WHAT_IF',
    name: 'LTL Rating What If',
  },
  testUser: {
    key: 'TST_LTL_RATING_WHAT_IF',
    name: 'LTL Rating What If TEST',
  },
};

export const MrraRoles: ApplicationRoles = {
  user: {
    key: 'RATING_MRRA',
    name: 'Rating MRRA',
  },
  testUser: {
    key: 'TST_RATING_MRRA',
    name: 'Rating MRRA TEST',
  },
};

export const RestrictedAccountRoles: ApplicationRoles = {
  user: {
    key: 'LTL_RATING_RQ_RESTRICTED',
    name: 'LTL Rating RQ Restricted',
  },
  testUser: {
    key: 'TST_LTL_RATING_RQ_RESTRICTED',
    name: 'LTL Rating RQ Restricted Test',
  },
};

export const ManualRatingUserRole: ApplicationRoles = {
  user: {
    key: 'Manual_Rating_User',
    name: 'Manual Rating User',
  },
  testUser: {
    key: 'TST_Manual_Rating_User',
    name: 'Manual Rating User Test',
  },
};

export const ManualRatingSupervisorRole: ApplicationRoles = {
  user: {
    key: 'Manual_Rating_Supervisor',
    name: 'Manual Rating Supervisor',
  },
  testUser: {
    key: 'TST_Manual_Rating_Supervisor',
    name: 'Manual Rating Supervisor Test',
  },
};
