import { Pipe, PipeTransform } from '@angular/core';
import { RequestCustomerAutocompleteFormat } from '@shared/models/customer';
import { CustomerMatch } from '@xpo-ltl-2.0/sdk-customer';
import { TransformData } from 'app/business/utils/transform-data/transform-data.utils';

@Pipe({
  name: 'requestAccountAutocomplete',
})
export class RequestAccountAutocompletePipe implements PipeTransform {
  transform(account: CustomerMatch, format: RequestCustomerAutocompleteFormat): string {
    if (!account) {
      return;
    }
    return format === RequestCustomerAutocompleteFormat.long
      ? TransformData.getAccountToDisplayInAutoCompleteLongFormat(account)
      : TransformData.getAccountToDisplayInAutoComplete(account);
  }
}
