import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-loader',
  templateUrl: './grid-loader.component.html',
  styleUrls: ['./grid-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridLoaderComponent implements ILoadingOverlayAngularComp {
  public params: ILoadingOverlayParams;

  constructor() {}

  agInit(params: ILoadingOverlayParams): void {
    this.params = params;
  }
}
