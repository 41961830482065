import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { CreateMassRerateRqst } from '@xpo-ltl-2.0/sdk-rating';
import { MrraActions } from '../actions/mrra.actions';

@Injectable({
  providedIn: 'root',
})
export class MrraHandlerService {
  // ACTION ###########################################################################################

  // Create MRRA Actions ************************************************************************
  createMassRerateSuccess$ = this.actions$.pipe(
    ofType(MrraActions.createMassRerateSuccess),
    map(({ createMassRerateResp }) => createMassRerateResp)
  );

  createMassRerateFail$ = this.actions$.pipe(
    ofType(MrraActions.createMassRerateFail),
    map(({ errors }) => errors)
  );

  constructor(private store$: Store, private actions$: Actions) { }

  clearMrraStore(): void {
    this.store$.dispatch(MrraActions.clearMrraStore());
  }

  createMassRerate(request: CreateMassRerateRqst): void {
    this.store$.dispatch(MrraActions.createMassRerate({ request }))
  }
}
