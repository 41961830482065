import { createSelector } from '@ngrx/store';
import { ratingAnalysisFeatureSelector, RatingAnalysisState } from '../reducers/rating-analysis.reducer';

const ratingAnalysisSelector = createSelector(ratingAnalysisFeatureSelector, (state) => state);

const getSelectedProNumberDetail = createSelector(
  ratingAnalysisSelector,
  (state: RatingAnalysisState) => state.selectedProNumberDetail
);

const loadingGetProNumberDetailBreakdown = createSelector(
  ratingAnalysisSelector,
  (state: RatingAnalysisState) => state.loadingGetProNumberDetailBreakdown
);

export const RatingAnalysisSelectors = { getSelectedProNumberDetail, loadingGetProNumberDetailBreakdown };
