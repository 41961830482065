import { Injectable } from '@angular/core';
import { ApplicationRoles } from '@shared/models/user';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UserActions } from '../actions/user.actions';
import { UserSelectors } from '../selectors/user.selector';

@Injectable({
  providedIn: 'root',
})
export class UserHandlerService {
  // SELECTORS
  getUserSelector$ = this.store$.pipe(select(UserSelectors.selectUser));
  getGequiredRolesCurrentPageSelector$ = this.store$.pipe(select(UserSelectors.getGequiredRolesCurrentPage));

  constructor(public store$: Store, public actions$: Actions) {}

  getUser(): void {
    this.store$.dispatch(UserActions.getUser());
  }

  setRequiredRolesCurrentPage(requiredRolesCurrentPage: ApplicationRoles): void {
    this.store$.dispatch(UserActions.setRequiredRolesCurrentPage({ requiredRolesCurrentPage }));
  }
}
