export const AppRoutes = {
  mainRoute: {
    main: 'landing-page',
    getDefault(): string {
      return `${AppRoutes.rateQuote.main}`;
    },
  },
  whatIf: { main: 'what-if' },
  rateQuote: {
    main: 'rate-quote',
    add: {
      main: 'add',
      generic: 'generic',
      genericInternational: 'generic-international',
      customerSpecific: 'customer-specific',
      customerSpecificInternational: 'customer-specific-international',
    },
    viewQuote: { main: 'view-quote' },
    detailQuote: { main: 'detail-quote' },
  },
  ratingAnalysis: { main: 'rating-analysis' },
  manualRating: {
    main: 'manual-rating',
    manualRatePro: { main: 'manual-rate-pro' },
    manualRatingAuditor: 'auditor',
   },
  mrra: { main: 'mrra' },
  dataEntryPage: { main: 'data-entry-page' },
  errorPages: {
    main: 'fail',
    notAuthorizedPage: 'not-authorized',
  },
};
