import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { MexicoCityModel } from '@shared/models/mexico-city.model';
import { TariffManagementActions } from '../actions/tariff-management.actions';

export const tariffManagementFeatureKey = 'tariffManagement';
export const tariffManagementFeatureSelector = createFeatureSelector<TariffManagementState>(tariffManagementFeatureKey);

export interface TariffManagementState {
  tariffListLoading: boolean;
  getZoneCitiesLoading: boolean;
  mexicoCities: MexicoCityModel[];
}

export const tariffManagementInitState: TariffManagementState = {
  tariffListLoading: false,
  getZoneCitiesLoading: false,
  mexicoCities: [],
};

export const reducer = createReducer(
  tariffManagementInitState,
  on(TariffManagementActions.getTariff, (state) => {
    return {
      ...state,
      tariffListLoading: true,
    };
  }),
  on(TariffManagementActions.getTariffSuccess, TariffManagementActions.getTariffFail, (state) => {
    return {
      ...state,
      tariffListLoading: false,
    };
  }),
  on(TariffManagementActions.getMexicoCities, (state) => {
    return {
      ...state,
      getZoneCitiesLoading: true,
      mexicoCities: []
    };
  }),
  on(TariffManagementActions.getMexicoCitiesSuccess, (state, { mexicoCities }) => {
    return {
      ...state,
      getZoneCitiesLoading: false,
      mexicoCities
    };
  }),
  on(TariffManagementActions.getMexicoCitiesFail, (state) => {
    return {
      ...state,
      getZoneCitiesLoading: false,
    };
  }),
);
