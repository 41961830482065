import { Injectable } from '@angular/core';
import { ApplicationUtilsService } from '@core/services/application-utils/application-utils.service';
import { LocationStorageEnum } from '@core/services/location/persistence/location-storage.enum';
import { LocalStorageService } from '@core/services/persistence/local-storage/local-storage.service';
import { TariffManagementEnum } from '@core/services/tariff/persistence/tariff-management-storage.enum';
import { RatingStorageEnum } from './rating-storage.enum';

@Injectable({
  providedIn: 'root'
})
export class RatingStorageService {

  constructor(
    private localStorageService: LocalStorageService,
    private applicationUtilsService: ApplicationUtilsService
  ) { }

  clearUiLocalStorageItems(): void {
    this.localStorageService.removeData(LocationStorageEnum.sicForPostalCodesKey);
    this.localStorageService.removeData(TariffManagementEnum.zoneCities);
  }

  setLocalStorageUiVersion(): void {
    const ratingUiVersion: string = this.applicationUtilsService.buildVersion;
    this.localStorageService.saveData(RatingStorageEnum.version, ratingUiVersion);
  }

  clearLocalStorageBaseOnUiVersion(): void {
    const ratingUiVersion: string = this.localStorageService.getData(RatingStorageEnum.version);

    if (this.applicationUtilsService.buildVersion !== ratingUiVersion) {
      this.setLocalStorageUiVersion();
      this.clearUiLocalStorageItems();
    }
  }
}
