import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@shared/enums/app-routes.enum';
import { RatingEmails } from '@shared/models/rating';
import { RegexRules } from 'app/business/utils/regex/regex';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  readonly mail = RatingEmails.ltlItratingTeam;
  readonly defaultSubject = 'Feedback - Edge Rating';

  constructor(public router: Router) {}

  getMailSubject(): string {
    let subject: string;
    if (RegexRules.mainApplicationRoute(AppRoutes.whatIf.main).test(this.router.url)) {
      subject = `${this.defaultSubject} What-If`;
    } else if (RegexRules.mainApplicationRoute(AppRoutes.rateQuote.main).test(this.router.url)) {
      subject = `${this.defaultSubject} Rate-Quote`;
    } else {
      subject = this.defaultSubject;
    }
    return subject;
  }

  getMailLink(): string {
    return `mailto:${this.mail}?subject=${this.getMailSubject()}`;
  }
}
