export enum AccountUrls {
  myAccount = 'https://portal.office.com/account/#personalinfo',
  help = '',
  privacyPolicy = '',
  switchApiUrl = 'https://switch-sandbox.xpoapi.com/users/',
  pictureUrl = '/picture?size=medium',
}

export const AccountsUrlAndDisplayName = {
  myAccount: { title: 'Account Settings', url: AccountUrls.myAccount },
};
