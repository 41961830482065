import { Pipe, PipeTransform } from '@angular/core';
import { DateFormats, DateRatingUtils } from '@shared/utils/dates/date.utils';
import { Tariff } from '@xpo-ltl/sdk-tariffmanagement';

@Pipe({
  name: 'tariffInfoDates',
})
export class TariffInfoDatesPipe implements PipeTransform {
  transform(tariff: Tariff): unknown {
    const effectiveDate = this.getEffectiveDate(tariff.effectiveDate);
    const expirationDate = this.getExpirationDate(tariff.expirationDate);

    let dateToDisplay: string;

    if (effectiveDate && expirationDate) {
      dateToDisplay = `${effectiveDate} | ${expirationDate}`;
    } else if (effectiveDate) {
      dateToDisplay = effectiveDate;
    } else {
      dateToDisplay = expirationDate;
    }

    return dateToDisplay;
  }

  getEffectiveDate(date: number): string {
    if (!date) {
      return '';
    }

    const effectiveDate = DateRatingUtils.getDateStringFormatFromJulianDate(date, DateFormats.yearMonthDayHypen);
    return effectiveDate ? `Effective: ${effectiveDate}` : '';
  }

  getExpirationDate(date: number): string {
    if (!date) {
      return '';
    }
    const expirationDate = DateRatingUtils.getDateStringFormatFromJulianDate(date, DateFormats.yearMonthDayHypen);
    return expirationDate ? `Expiry: ${expirationDate}` : '';
  }
}
