import { FormGroup } from '@angular/forms';
import { ErrorModel } from '@shared/models/error';
import {
  FormErrorInterface,
  FormErrorMessage,
  FormErrorMessageEmail,
  FormErrorMessageFromApi,
  FormErrorMessageGeneric,
  FormErrorMessageMaxLength,
  FormErrorMessageMaxValue,
  FormErrorMessageMinLength,
  FormErrorMessageMinValue,
  FormErrorMessagePattern,
} from '@shared/models/form-errors';

export class FormErrorsService {
  constructor(private formErrorService: FormErrorInterface) { }

  setError(errors: ErrorModel[], form: FormGroup): void {
    errors.forEach((err: ErrorModel) => {
      const fieldName: string[] = err.location.split('.');
      if (fieldName.length < 2) {
        throw Error('Location attibute format is not correct');
      }
      this.formErrorService.setFormError(form, fieldName, err.message);
    });
  }

  static getInvalidApiErrorFieldMessage(form: FormGroup, fieldName: string): string {
    return (form.controls[fieldName].errors as FormErrorMessageFromApi)?.invalidApiValidation;
  }

  static getErrorFieldMessage(form: FormGroup, fieldName: string): string {
    return (form.controls[fieldName].errors as FormErrorMessage)?.errorMessage;
  }

  static getErrorMessageRequired(form: FormGroup, fieldName: string): string {
    return form.controls[fieldName].errors?.required && form.controls[fieldName].touched ? 'Required' : undefined;
  }

  static getErrorMessageGeneric(form: FormGroup): string {
    return (form.errors as FormErrorMessageGeneric)?.genericErrorMessage;
  }

  static getErrorMessageEmail(
    form: FormGroup,
    fieldName: string,
    errorMessage: string = 'Invalid email format'
  ): string {
    return (form.controls[fieldName].errors as FormErrorMessageEmail)?.email && form.controls[fieldName].touched
      ? errorMessage
      : undefined;
  }

  static getErrorMessageMaxLength(form: FormGroup, fieldName: string): string {
    const maxLengthError = (form.controls[fieldName]?.errors as FormErrorMessageMaxLength)?.maxlength;
    if (!maxLengthError) {
      return;
    }
    return `Max length is ${maxLengthError.requiredLength}`;
  }

  static getErrorMessageMinLength(form: FormGroup, fieldName: string): string {
    const minLengthError = (form.controls[fieldName]?.errors as FormErrorMessageMinLength)?.minlength;
    if (!minLengthError || form.controls[fieldName].untouched) {
      return;
    }
    return `Min length is ${minLengthError.requiredLength}`;
  }

  static getErrorMessageMaxValue(form: FormGroup, fieldName: string): string {
    const maxValueError = (form.controls[fieldName]?.errors as FormErrorMessageMaxValue)?.max;
    if (!maxValueError) {
      return;
    }
    return `The Max value is ${maxValueError.max}`;
  }

  static getErrorMessageMinValue(form: FormGroup, fieldName: string): string {
    const minValueError = (form.controls[fieldName]?.errors as FormErrorMessageMinValue)?.min;
    if (!minValueError) {
      return;
    }
    return `The Min value is ${minValueError.min}`;
  }

  static getErrorMessagePattern(form: FormGroup, fieldName: string): string {
    const errorValue = (form.controls[fieldName]?.errors as FormErrorMessagePattern)?.pattern;
    if (!errorValue) {
      return;
    }
    return `The entered format is wrong`;
  }
}
