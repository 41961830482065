<mat-radio-group class="radio-button" [id]="idRadioButton">
  <ng-container *ngFor="let radioButton of radioButtonList; let i = index">
    <mat-radio-button
      id="{{ idRadioButton }}--{{ i }}"
      class="radio-button__item"
      [value]="radioButton.value"
      [checked]="radioButton.checked"
      (change)="radioChange($event)"
      [disabled]="radioButton.disabled"
    >
      {{ radioButton.text }}
    </mat-radio-button>
  </ng-container>
</mat-radio-group>
