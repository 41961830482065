import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { ReleaseNotes, ReleaseNotesConfig } from '@shared/models/release-notes';
import { UserPreferenceActions } from '../actions/user-preference.actions';

export const userPreferenceFeatureKey = 'userPreference';
export const userPreferenceFeatureSelector = createFeatureSelector<UserPreferenceState>(userPreferenceFeatureKey);

export interface UserPreferenceState {
  [ReleaseNotesConfig.componentName]: ReleaseNotes;
}

export const userPreferenceInitState: UserPreferenceState = {
  [ReleaseNotesConfig.componentName]: ReleaseNotesConfig.releaseNotesInitialState,
};

export const reducer = createReducer(
  userPreferenceInitState,
  on(UserPreferenceActions.getUserPreference, (state, { componentName }) => {
    return {
      ...state,
      [componentName]: userPreferenceInitState[componentName],
    };
  }),
  on(
    UserPreferenceActions.getUserPreferenceSuccess,
    UserPreferenceActions.updateUserPreferenceSuccess,
    (state, { componentName, preferences }) => {
      return {
        ...state,
        [componentName]: preferences,
      };
    }
  )
);
