<mat-label class="selected-header__title" [id]="idTitle">REQUESTER</mat-label>
<mat-select
  placeholder="Select item"
  xpoSelect
  class="selected-header__data"
  (selectionChange)="selectItem($event.value)"
  [id]="idList"
  [(value)]="defaultIdValue"
>
  <ng-container *ngFor="let item of items; let i = index">
    <mat-option [value]="item.viewValue" id="{{ idList }}-{{ i }}"> {{ item.viewValue | titlecase }} </mat-option>
  </ng-container>
</mat-select>
