import { Injectable } from '@angular/core';
import { XpoAppSwitcherApplication } from '@xpo-ltl/ngx-auth';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationUtilsService } from '../application-utils/application-utils.service';

@Injectable({
  providedIn: 'root',
})
export class AppSwitcherService {
  constructor(public applicationUtilsService: ApplicationUtilsService) {}

  getAppList(): Observable<XpoAppSwitcherApplication[]> {
    return of(this.applicationUtilsService.appToDisplayAppSwitcher).pipe(
      map((response: string) => {
        let listOfApps: XpoAppSwitcherApplication[] = [];
        const appsRow: string[] = response.split(';');
        for (const app of appsRow) {
          if (!app) {
            continue;
          }
          const [name, url]: string[] = app.split(',');
          listOfApps.push({ name, url });
        }
        return listOfApps;
      })
    );
  }
}
