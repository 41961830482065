import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/error';
import { CreateMassRerateResp, CreateMassRerateRqst } from '@xpo-ltl-2.0/sdk-rating';

export abstract class MrraActions {
  static clearMrraStore = createAction('[MrraActions] Clear Mrra Store');

  static createMassRerate = createAction(
    '[MrraActions] Create a new Mass Rerate Request',
    props<{ request: CreateMassRerateRqst }>()
  );
  static createMassRerateSuccess = createAction(
    '[MrraActions] Create a new Mass Rerate Request Success',
    props<{ createMassRerateResp: CreateMassRerateResp }>()
  );
  static createMassRerateFail = createAction(
    '[MrraActions] Create a new Mass Rerate Request Fail',
    props<{ errors: ErrorModel[] }>()
  );
}
