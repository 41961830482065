import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ReleaseNotes, ReleaseNotesConfig } from '@shared/models/release-notes';
import { UserPreferenceComponentNameType, UserPreferenceResultType } from '@shared/models/user-preference';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserPreferenceActions } from '../actions/user-preference.actions';
import { UserPreferenceSelectors } from '../selectors/user-preference.selector';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceHandlerService {
  releaseNotesSelector$ = this.store$.pipe(select(UserPreferenceSelectors.releaseNotes));

  constructor(public store$: Store, public actions$: Actions) {}

  getUserPreference(componentName: UserPreferenceComponentNameType): void {
    this.store$.dispatch(UserPreferenceActions.getUserPreference({ componentName }));
  }

  updateUserPreference(componentName: UserPreferenceComponentNameType, preferences: UserPreferenceResultType): void {
    this.store$.dispatch(UserPreferenceActions.updateUserPreference({ componentName, preferences }));
  }

  doNewReleaseNotesExist(currentBuildVersion: string): Observable<boolean> {
    return this.releaseNotesSelector$.pipe(
      filter((preference: ReleaseNotes) => preference !== ReleaseNotesConfig.releaseNotesInitialState),
      map((preference: ReleaseNotes) => preference?.buildVersion !== currentBuildVersion)
    );
  }

  dismissNewReleaseNotes(componentName: UserPreferenceComponentNameType, buildVersion: string): void {
    const preferences: ReleaseNotes = { buildVersion };
    this.updateUserPreference(componentName, preferences);
  }
}
