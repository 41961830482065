import { ActionReducerMap } from '@ngrx/store';
import * as UserReducer from './user/reducer/user.reducer';
import * as UserPreferenceReducer from './user-preference/reducer/user-preference.reducer';
import * as UtilsReducer from './tariffManagement/reducer/tariff-management.reducer';
import * as InfrastructureReducer from './infrastructure/reucers/infrastructure.reducer';
import * as LocationReducer from './location/reucers/location.reducer';
import * as SharedReducer from './shared/reducer/shared.reducer';

export interface AppState {
  [UserReducer.userFeatureKey]: UserReducer.UserState;
  [UserPreferenceReducer.userPreferenceFeatureKey]: UserPreferenceReducer.UserPreferenceState;
  [UtilsReducer.tariffManagementFeatureKey]: UtilsReducer.TariffManagementState;
  [InfrastructureReducer.infrastructureFeatureKey]: InfrastructureReducer.InfrastructureState;
  [LocationReducer.locationFeatureKey]: LocationReducer.LocationState;
  [SharedReducer.sharedFeatureKey]: SharedReducer.SharedState;
}

export const appReducers: ActionReducerMap<AppState> = {
  [UserReducer.userFeatureKey]: UserReducer.reducer,
  [UserPreferenceReducer.userPreferenceFeatureKey]: UserPreferenceReducer.reducer,
  [UtilsReducer.tariffManagementFeatureKey]: UtilsReducer.reducer,
  [InfrastructureReducer.infrastructureFeatureKey]: InfrastructureReducer.reducer,
  [LocationReducer.locationFeatureKey]: LocationReducer.reducer,
  [SharedReducer.sharedFeatureKey]: SharedReducer.reducer,
};
