export enum ManualRatingBackText {
  arrowBack = 'Go Back',
}

export enum ManualRatingProgressCardTitle {
  allTotals = 'All Totals',
  audit = 'Audit',
  entry = 'Entry',
}

export enum ManualRatingFileName {
  unprocessedPros = 'Manual Rating Unprocessed PROs by Queue.csv',
}

export enum ManualRatingRateOption {
  manualText = 'M',
  manualNumber = -0.0001,
}

export enum ManualRatingIdleTimeout {
  proScreenSec = 1480,
  proScreenAdditionalSec = 300,
  proScreenContinueEditing = 1790,
}

export enum ManualRatingQueueName {
  xacc = 'XACC',
}
