import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

export interface CheckBoxList {
  text: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent {
  @Input() radioButtonList: CheckBoxList[];
  @Input() idRadioButton = 'radioButton';

  @Output() selectedRadioButton = new EventEmitter<string>();

  constructor() {}

  radioChange(selectedRaddioButton: MatRadioChange): void {
    this.selectedRadioButton.emit(selectedRaddioButton.value);
  }
}
