import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/error';
import { GetSicForPostalCodesQuery, GetSicForPostalCodesResp } from '@xpo-ltl-2.0/sdk-location';

export abstract class LocationActions {
  static getSicForPostalCodes = createAction(
    '[LocationActions] Get information for postal codes',
    props<{ request: GetSicForPostalCodesQuery }>()
  );
  static getSicForPostalCodesSuccess = createAction(
    '[LocationActions] Get information for postal codes Success',
    props<{ getSicForPostalCodesResp: GetSicForPostalCodesResp }>()
  );
  static getSicForPostalCodesFail = createAction(
    '[LocationActions] Get information for postal codes Fail',
    props<{ errors: ErrorModel[] }>()
  );
}
