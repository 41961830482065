<div class="layout">
  <xpo-shell class="layout__shell">
    <xpo-header-app-name>Edge</xpo-header-app-name>
    <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
    <xpo-header-navigation>
      <button [routerLink]="getWhatIfLink()" routerLinkActive="xpo-selected" *ngIf="displayWhatIf"
        id="whatIfHeaderOption">
        What If
      </button>
      <button [routerLink]="geRateQuoteink()" routerLinkActive="xpo-selected" id="rateQuoteHeaderOption">
        Rate Quote
      </button>
      <button [routerLink]="getRatingAnalysisLink()" routerLinkActive="xpo-selected" id="ratingAnalysisHeaderOption"
        *ngIf="!hideRatingAnalysis">
        Rating Analysis
      </button>
      <button [routerLink]="getManualRatingLink()" routerLinkActive="xpo-selected" id="manualRatingHeaderOption"
        *ngIf="!hideManualRating">
        Manual Rating
      </button>
      <button [routerLink]="getMrraLink()" routerLinkActive="xpo-selected" id="mrraHeaderOption"
        *ngIf="!hideMrra && displayMrraTab">
        MRRA
      </button>
    </xpo-header-navigation>
    <xpo-header-actions>
      <xpo-app-switcher-popover [applications]="apps$ | async"></xpo-app-switcher-popover>
      <xpo-auth-user-profile>
        <xpo-auth-user-profile-links>
          <a [href]="AccountUrls.myAccount" target="_blank">My Account</a>
        </xpo-auth-user-profile-links>
      </xpo-auth-user-profile>
    </xpo-header-actions>
    <router-outlet></router-outlet>
  </xpo-shell>
  <app-footer [buildVersion]="buildVersion" (releaseNotes)="releaseNotesSelectedAndOpen()"></app-footer>
</div>
