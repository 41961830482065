import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserHandlerService } from '@app-store/user/handlers/user-handler.service';
import { AppRoutes } from '@shared/enums/app-routes.enum';
import { User } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { UserRoleService } from '../../../shared/services/user-role/user-role.service';

@Injectable({
  providedIn: 'root',
})
export class WhatIfAuthorizedRedirectGuard implements CanActivate {
  constructor(
    public router: Router,
    public userRoleService: UserRoleService,
    public userHandlerService: UserHandlerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userHandlerService.getUserSelector$.pipe(
      tap((user: User) => {
        if (!user) {
          this.userHandlerService.getUser();
        }
      }),
      filter((user: User) => !!user),
      take(1),
      map((user: User) => this.userRoleService.isAuthorizedUserToWhatIf(user)),
      tap(() => this.userHandlerService.setRequiredRolesCurrentPage(this.userRoleService.whatIfRequiredRoles)),
      tap(
        (isAuthorized: boolean) =>
          !isAuthorized &&
          this.router.navigate([`/${AppRoutes.errorPages.main}/${AppRoutes.errorPages.notAuthorizedPage}`])
      )
    );
  }
}
