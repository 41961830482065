import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationUtilsService } from '@core/services/application-utils/application-utils.service';
import { FeedbackService } from '@core/services/feedback/feedback.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() buildVersion: string;

  @Output() releaseNotes = new EventEmitter<void>();

  constructor(
    public configManagerService: ConfigManagerService,
    public feedbackService: FeedbackService,
    public applicationUtilsService: ApplicationUtilsService
  ) {}

  getDefaultMailLink(): string {
    return this.feedbackService.getMailLink();
  }

  getHelpLink(): string {
    return this.applicationUtilsService.helpLink;
  }

  openHelpLink(): void {
    window.open(this.applicationUtilsService.helpLink);
  }
}
