export enum AccessorialsCdEnum {
  insidePickup = 'OIP',
  exhibitionPickup = 'PEO',
  liftGateServicePickup = 'OLG',
  containerStationPickup = 'OCF',
  residentialPickup = 'RSO',
  sortingPickup = 'OSS',
  consolidationPickup = 'HCB',
  inspectionPickup = 'CBI',
  reconsignmentPickup = 'ORC',
  constructionPickup = 'OCS',
  mineGovAirpPickup = 'OMS',
  weighPickup = 'CBR',
  holidayWeekendPickup = 'OSH',
  piersPickup = 'OPW',
  liftGateServiceDelivery = 'DLG',
  sortingDelivery = 'SRT',
  notificationDelivery = 'DNC',
  insideDelivery = 'DID',
  exhibitionDelivery = 'PED',
  residentialDelivery = 'RSD',
  containerStationDelivery = 'DCF',
  constructionDelivery = 'CSD',
  mineGovAirpDelivery = 'DMS',
  appointmentDelivery = 'APT',
  timeDateCriticalDelivery = 'TDC',
  holidayWeekendDelivery = 'DSH',
  afterBusinessHoursDelivery = 'DAH',
  consolidationDelivery = 'HCC',
  reconsignmentDelivery = 'RCD',
  redeliveryDelivery = 'RED',
  piersDelivery = 'DPW',
  borderCrossingServiceDelivery = 'DRA',
  inBondFreight = 'CIB',
  inBondTirCarnet = 'IBC',
  inBondCustomsInspectionDelay = 'IBI',
  inBondCustomsRelForms = 'WAA',
  sufferanceNbOnQc = 'SWC',
  sufferanceOther = 'SWR',
  sufferanceRemanifest = 'SWD',
  sufferanceHandling = 'SWH',
  sufferanceExamination = 'SWE',
  sufferanceInspectionDelay = 'SWP',
  instantGuaranteedService = 'GUR',
  guaranteedByNoon = 'G12',
  blindShipment = 'BLS',
  correctiontoBol = 'CBL',
  codCancelChange = 'CCD',
  returnUndeliveredShipment = 'RET',
  returnCheck = 'RCF',
  rapidRemoteService = 'RRS',
  excessiveLength = 'ELS',
  excessiveValueCharge = 'EVC',
  freezableProtection = 'XFZ',
};
