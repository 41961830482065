import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PDFDocumentProxy, PdfViewerComponent } from 'ng2-pdf-viewer';
import { PdfViewerConfig } from './pdf-viewer.config';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerGeneralComponent {
  @Input() pdfSrc: string | object | ArrayBufferLike;
  @Input() zoom: number = 1;
  @Input() pageVariable: number = 1;
  @Input() renderText: boolean = true;
  @Input() originalSize: boolean = false;
  @Input() fitToPage: boolean = true;
  @Input() style: string = PdfViewerConfig.style.sm;
  @Input() textToScroll: string;
  @Output() totalPageNumber = new EventEmitter<number>();

  @ViewChild('pdf') pdfViewer: PdfViewerComponent;

  constructor() {}

  afterLoadComplete(pdf: PDFDocumentProxy): void {
    this.totalPageNumber.emit(pdf.numPages);
  }

  textLayerRendered(event: any) {
    this.setDefaultScroll(event.source.textDivs);
  }

  private setDefaultScroll(textElements: Element[]): void {
    if (this.textToScroll) {
      const elementToScroll = textElements.find((element: Element) => element.textContent.includes(this.textToScroll));
      if (elementToScroll) {
        elementToScroll.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
