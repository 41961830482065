import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserPreferenceHandlerService } from '@app-store/user-preference/handlers/user-preference-handler.service';
import { UserHandlerService } from '@app-store/user/handlers/user-handler.service';
import { AppSwitcherService } from '@core/services/app-switcher/app-switcher.service';
import { ApplicationUtilsService } from '@core/services/application-utils/application-utils.service';
import { RatingStorageService } from '@core/services/rating/persistence/rating-storage.service';
import { AccountUrls } from '@shared/enums/account-urls.enum';
import { AppRoutes } from '@shared/enums/app-routes.enum';
import { AppTitle } from '@shared/enums/app-title.enum';
import { ConfigManagerProperties } from '@shared/enums/config-manager-properties.enum';
import { ReleaseNotesConfig } from '@shared/models/release-notes';
import { UserRoleService } from '@shared/services/user-role/user-role.service';
import { ReleaseNotesComponent } from '@shared/ui/dialog/release-notes/release-notes.component';
import { SubCollection } from '@shared/utils/rx/sub-collection';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoAppSwitcherApplication } from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  title: string;
  routes = AppRoutes;

  readonly AccountUrls = AccountUrls;

  buildVersion: string;
  apps$: Observable<XpoAppSwitcherApplication[]>;

  subs$ = new SubCollection();

  displayWhatIf: boolean;
  hideRatingAnalysis: boolean;
  hideManualRating: boolean;
  hideMrra: boolean;
  displayMrraTab: boolean;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public userHandlerService: UserHandlerService,
    public userRoleService: UserRoleService,
    public applicationUtilsService: ApplicationUtilsService,
    public userPreferenceHandlerService: UserPreferenceHandlerService,
    public appSwitcherService: AppSwitcherService,
    private configManagerService: ConfigManagerService,
    private ratingStorageService: RatingStorageService
  ) {
    this.title = AppTitle.rating;
  }

  ngOnInit(): void {
    this.getBuildVersion();
    this.getUserAction();
    this.displayMenuOptions();
    this.getAppList();
    this.checkForUpdatedReleaseNotes();
    this.displayRatingAnalysis();
    this.displayManualRating();
    this.displayMrra();
    this.clearRatingStorage();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  onFavoriteApplicationChange(e): void { }

  getBuildVersion(): void {
    this.buildVersion = this.applicationUtilsService.buildVersion;
  }

  getUserAction(): void {
    this.userHandlerService.getUser();
  }

  // Display or not menu elements
  displayMenuOptions(): void {
    this.subs$.add = this.userHandlerService.getUserSelector$
      .pipe(
        filter((user: User) => !!user),
        tap((user: User) => (
          this.displayWhatIf = this.userRoleService.isAuthorizedUserToWhatIf(user),
          this.displayMrraTab = this.userRoleService.isAuthorizedUserToMrra(user)
          )),
        take(1)
      )
      .subscribe();
  }

  displayRatingAnalysis(): void {
    const turnOffRatingAnalysis: string = this.configManagerService.getSetting<string>(
      ConfigManagerProperties.turnOffRatingAnalysis
    );
    this.hideRatingAnalysis = turnOffRatingAnalysis ? JSON.parse(turnOffRatingAnalysis) : false;
  }

  displayManualRating(): void {
    const turnOffManualRating: string = this.configManagerService.getSetting<string>(
      ConfigManagerProperties.turnOffManualRating
    );
    this.hideManualRating = turnOffManualRating ? JSON.parse(turnOffManualRating) : false;
  }

  displayMrra(): void {
    const turnOffMrra: string = this.configManagerService.getSetting<string>(
      ConfigManagerProperties.turnOffMrra
    );
    this.hideMrra = turnOffMrra ? JSON.parse(turnOffMrra) : false;
  }

  // Header options
  getWhatIfLink(): string {
    return this.routes.whatIf.main;
  }

  geRateQuoteink(): string {
    return this.routes.rateQuote.main;
  }

  getRatingAnalysisLink(): string {
    return this.routes.ratingAnalysis.main;
  }

  getManualRatingLink(): string {
    return this.routes.manualRating.main;
  }

  getMrraLink(): string {
    return this.routes.mrra.main;
  }

  // Footer Options
  releaseNotesOpen(): void {
    window.open(this.applicationUtilsService.releaseNotesLink);
  }

  releaseNotesSelected(): void {
    this.userPreferenceHandlerService.dismissNewReleaseNotes(ReleaseNotesConfig.componentName, this.buildVersion);
  }

  releaseNotesSelectedAndOpen(): void {
    this.releaseNotesOpen();
    this.releaseNotesSelected();
  }

  checkForUpdatedReleaseNotes(): void {
    this.subs$.add = this.userPreferenceHandlerService
      .doNewReleaseNotesExist(this.buildVersion)
      .pipe(take(1))
      .subscribe((doNewReleaseNotesExist: boolean) => {
        if (doNewReleaseNotesExist) {
          this.showReleaseNotes();
        }
      });
    this.userPreferenceHandlerService.getUserPreference(ReleaseNotesConfig.componentName);
  }

  showReleaseNotes(): void {
    const dialogRef = this.dialog.open(ReleaseNotesComponent);
    dialogRef.componentInstance.viewNow.pipe(take(1)).subscribe(() => this.releaseNotesOpen());
    this.dialog
      ._getAfterAllClosed()
      .pipe(take(1))
      .subscribe(() => this.releaseNotesSelected());
  }

  // STORAGE **********************************************************************************
  clearRatingStorage(): void {
    this.ratingStorageService.clearLocalStorageBaseOnUiVersion();
  }

  // APP SWITCHER
  getAppList(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }
}
