export enum AlaskaPostalCode {
  lowerLimit = 99501,
  upperLimit = 99950,
  label = 'Alaska',
}

export enum HawaiiPostalCode {
  lowerLimit = 96701,
  upperLimit = 96898,
  label = 'Hawaii',
}

export enum PuertoRicoPostalCode {
  lowerLimit = 601,
  upperLimit = 988,
  label = 'Puerto Rico',
}
