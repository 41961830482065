import { createFeatureSelector, createReducer } from '@ngrx/store';

export const locationFeatureKey = 'location';
export const locationFeatureSelector = createFeatureSelector<LocationState>(locationFeatureKey);

export interface LocationState {}

export const rateQuoteInitialState: LocationState = {};

export const reducer = createReducer(rateQuoteInitialState);
