import { Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { SubCollection } from '@shared/utils/rx/sub-collection';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export abstract class FormFieldAutocompleteParentComponent implements ControlValueAccessor {
  placeHolderLoading: string;

  onChange: any = () => {};
  onTouch: any = () => {};

  subs$ = new SubCollection();

  form: FormControl = new FormControl(null);

  constructor(@Optional() @Self() public ngControl: NgControl) {}

  // FORM METHODS
  inheritValidators(): void {
    const validators = this.ngControl.control.validator;
    this.form.setValidators(validators ? validators : null);
    this.form.updateValueAndValidity();
  }

  enableOrDisableField(): void {
    this.subs$.add = this.ngControl.control.statusChanges.subscribe(() => {
      this.ngControl.control.disabled ? this.form.disable() : this.form.enable();
    });
  }

  enableOrDisableWhenIsLoading(loadingField$: Observable<boolean>, displayPlaceHolderLoadingField: boolean) {
    this.subs$.add = loadingField$.subscribe((isLoading: boolean) => {
      if (isLoading) {
        this.form.disable();
        if (displayPlaceHolderLoadingField) {
          this.placeHolderLoading = 'Loading...';
        }
      } else {
        this.form.enable();
        this.placeHolderLoading = undefined;
        this.setFocus();
      }
    });
  }

  setFocus(): void {}

  // VALUE ACCESSOR METHODS
  writeValue(value: string): void {
    //
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setValue(val: string): void {
    this.onChange(val);
    this.onTouch(val);
  }

  listenChangeParentValue(): void {
    this.subs$.add = this.ngControl.control.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value: string) => this.form.setValue(value));
  }

  listenFormChangeValue(): void {
    this.subs$.add = this.form.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value: string) => this.setValue(value));
  }

  listenChangeValue(): void {
    this.listenChangeParentValue();
    this.listenFormChangeValue();
  }
}
