import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { RequestCustomerAutocompleteFormat } from '@shared/models/customer';
import { RequestAccountAutocompletePipe } from '@shared/pipes/request-account-autocomplete/request-account-autocomplete.pipe';
import { CustomerMatch } from '@xpo-ltl-2.0/sdk-customer';
import { TransformData } from 'app/business/utils/transform-data/transform-data.utils';
import { Observable, of } from 'rxjs';
import { FormFieldAutocompleteParentComponent } from '../form-field-autocomplete-parent-component';

@Component({
  selector: 'app-account-form-field',
  templateUrl: './account-form-field.component.html',
  styleUrls: ['./account-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountFormFieldComponent extends FormFieldAutocompleteParentComponent implements OnInit, OnDestroy {
  @Input() labelName: string = 'Account';
  @Input() idAccount = 'account';
  @Input() accountLabelId = 'accountLabel';
  @Input() errorId = 'accountError';
  @Input() errorMessage: string;
  @Input() placeHolder = 'Account Code or CIS Number';
  @Input() accountList$ = of();
  @Input() loading$: Observable<boolean>;
  @Input() displayPlaceHolderLoading: boolean; // Optional, by default is false, if the attribute is false the component doesn't display the placeHolderLoading when is loading
  @Input() setFocusAfterEnable: boolean = true; // Optional, by default is true, if the attribute is false the component doesn't apply the focus once the enable method is applied
  @Input() accountFieldFormat: RequestCustomerAutocompleteFormat = RequestCustomerAutocompleteFormat.regular;

  @Output() accountOptionSelected = new EventEmitter<CustomerMatch>();

  requestAccountAutocompletePipe = new RequestAccountAutocompletePipe();

  constructor(public cdr: ChangeDetectorRef, @Optional() @Self() public ngControl: NgControl) {
    super(ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.listenChangeValue();
    this.inheritValidators();
    this.enableOrDisableField();
    this.enableOrDisableWhenIsLoading();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  // FORM METHODS
  enableOrDisableWhenIsLoading() {
    super.enableOrDisableWhenIsLoading(this.loading$, this.displayPlaceHolderLoading);
  }

  setFocus(): void {
    if (this.setFocusAfterEnable) {
      document.getElementById(this.idAccount)?.focus();
    }
  }

  getOptionText(customerOption: string | CustomerMatch): string {
    if (!customerOption) {
      return '';
    }

    return typeof customerOption === 'string'
      ? customerOption
      : this.requestAccountAutocompletePipe.transform(customerOption, this.accountFieldFormat);
  }

  // NGFOR METHODS
  trackByAccount(index: number, account: CustomerMatch): string {
    return `${index}-${account.customerNbr}`;
  }
}
