import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/error';
import { SendEmailRqst } from '@xpo-ltl-2.0/sdk-infrastructure';

export abstract class InfrastructureActions {
  static sendEmail = createAction('[InfrastructureActions] Send Email', props<{ request: SendEmailRqst }>());

  static sendEmailSuccess = createAction('[InfrastructureActions] Send Email Success');

  static sendEmailFail = createAction('[InfrastructureActions] Send Email Fail', props<{ errors: ErrorModel[] }>());
}
