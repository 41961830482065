<div class="maintenance">
  <xpo-error-pages [type]="errorPagesType.Maintenance">
    <xpo-error-pages-action>
      <button mat-flat-button xpoButton xpoLargeButton (click)="reloadSite()" id="refreshBtn">
        Refresh this page
      </button>
      <button mat-stroked-button xpoButton xpoLargeButton>
        <a [href]="sendEmail()" id="contactSupportBtn">Contact Support</a>
      </button>
    </xpo-error-pages-action>
  </xpo-error-pages>
</div>
