export enum AppTitle {
  rating = 'Rating',
}

export enum WhatIfRequestsTitles {
  mainTitle = 'What If Requests',
  trayAdd = 'Add What If Request',
  trayDetail = 'What If - {requestId}',
}

export enum RateQuoteTitle {
  main = 'Rate Quote',
  quoteList = 'View Quotes',
  madCodeHeader = 'MAD CODE',
  accountCodeHeader = 'ACCOUNT CODE',
  addQuoteCustomerSpecific = 'Customer-Specific',
  addQuoteCustomerSpecificInternational = 'Customer-Specific International Mexico',
  addQuoteGeneric = 'Generic',
  addQuoteGenericInternational = 'Generic International Mexico',
  detailQuote = 'Rate Quote Detail',
}

export enum RatingAnalysisTitle {
  main = 'Rating Analysis',
}

export enum RatingPagesTitle {
  rateQuote = 'rate-quote',
  manualRating ='manual-rating',
  ratingAnalysis = 'rating-analysis',
  mrra = 'mrra',
}

export enum ManualRatingTitle {
  main = 'Manual Rating',
  ratePro = 'PRO',
  searchSinglePro = 'Entry',
}

export enum MrraTitle {
  main = "MRRA",
}
