import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserProfileService } from '@shared/services/user-profile/user-profile.service';
import { User } from '@xpo-ltl/sdk-common';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UserActions } from '../actions/user.actions';

@Injectable()
export class UserEffects {
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUser),
      exhaustMap(() => {
        return this.userProfileService.getUser().pipe(
          map((user: User) => UserActions.getUserSuccess({ user })),
          catchError(() => of(UserActions.getUserFail()))
        );
      })
    )
  );

  constructor(private actions$: Actions, public userProfileService: UserProfileService) {}
}
