<mat-form-field floatLabel="always">
  <mat-label>{{ labelName }}</mat-label>
  <input
    [id]="idTariff"
    type="text"
    matInput
    [formControl]="form"
    [matAutocomplete]="tariffAutocomplete"
    [placeholder]="placeHolderLoading || placeHolder"
    xpoAutocomplete
  />
  <mat-icon matSuffix>arrow_drop_down</mat-icon>
  <mat-autocomplete #tariffAutocomplete>
    <ng-container *ngIf="tariffList$ | async; else emptyTariff">
      <mat-option
        *ngFor="let tariff of tariffList$ | async; trackBy: trackByTariff"
        [value]="tariff.tariff"
        xpoOptionTwoLines
      >
        <xpo-select-two-lines-container>
          <span>{{ tariff.tariff }}</span>
          <span>{{ tariff | tariffInfoDates }}</span>
        </xpo-select-two-lines-container>
      </mat-option>
    </ng-container>
    <ng-template #emptyTariff>
      <mat-option *ngIf="messageWithinField" data-test="tarif_message_withing_field">
        {{ messageWithinField }}
      </mat-option>
    </ng-template>
  </mat-autocomplete>
</mat-form-field>
<mat-error [id]="errorId" *ngIf="errorMessage" class="error">
  <xpo-icon iconName="error"></xpo-icon>
  {{ errorMessage }}
</mat-error>
