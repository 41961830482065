<mat-form-field floatLabel="always" class="account">
  <mat-label [id]="accountLabelId">{{ labelName }}</mat-label>
  <input
    type="text"
    matInput
    [placeholder]="placeHolder"
    [id]="idAccount"
    [formControl]="form"
    [matAutocomplete]="accountAutocomplete"
    xpoAutocomplete
  />
  <mat-icon matSuffix>arrow_drop_down</mat-icon>
  <mat-autocomplete
    #accountAutocomplete
    (optionSelected)="accountOptionSelected.emit($event)"
    [displayWith]="getOptionText.bind(this)"
  >
    <mat-option *ngFor="let account of accountList$ | async; trackBy: trackByAccount" [value]="account">
      {{ account | requestAccountAutocomplete: accountFieldFormat }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-error [id]="errorId" *ngIf="errorMessage" class="error">
  <xpo-icon iconName="error"></xpo-icon>
  {{ errorMessage }}
</mat-error>
