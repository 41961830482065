import { Injectable } from '@angular/core';
import { ApplicationRoles } from '@shared/models/user';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import { RegexRules } from 'app/business/utils/regex/regex';
import * as _ from 'lodash';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import UserRoleHelper from '../../enums/user-role/user-role-helper';
import {
  ManualRatingSupervisorRole,
  ManualRatingUserRole,
  MrraRoles,
  RestrictedAccountRoles,
  UserRole,
  WhatIfRoles,
} from '../../enums/user-role/user-role.enum';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  user: User;
  isProduction = false;
  private roleNames = {};
  whatIfRoles = {};
  whatIfRequiredRoles: ApplicationRoles;
  mrraRoles = {};
  mrraRequiredRoles: ApplicationRoles;
  restrictedAccountRoles = {};
  restrictedAccountRequiredRoles: ApplicationRoles;
  manualRatingRoles = {};

  constructor(private configManagerService: ConfigManagerService) {
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);

    /** TODO: establish roles for PROD / NON-PROD */
    this.roleNames = {
      [UserRole.User]: this.isProduction ? ['CIS_DEV'] : ['CIS_DEV', 'CIS_DEV'],
      [UserRole.SuperUser]: this.isProduction ? 'DEFAULT_TABIGATOR' : ['DEFAULT_TABIGATOR', ''],
    };

    this.setManualRatingRoles();
    this.setRestrictedAccountRoles();
    this.setWhatIfRoles();
    this.setMrraRoles();
  }

  setWhatIfRoles(): void {
    const userRole = WhatIfRoles.user;
    const testUserRole = WhatIfRoles.testUser;

    this.whatIfRequiredRoles = {
      user: { ...userRole },
      testUser: this.isProduction ? undefined : { ...testUserRole },
    };

    this.whatIfRoles = {
      [UserRole.User]: this.isProduction
        ? [this.whatIfRequiredRoles.user.key]
        : [this.whatIfRequiredRoles.user.key, this.whatIfRequiredRoles.testUser.key],
    };
  }

  setMrraRoles(): void {
    const userRole = MrraRoles.user;
    const testUserRole = MrraRoles.testUser;

    this.mrraRequiredRoles = {
      user: { ...userRole },
      testUser: this.isProduction ? undefined : { ...testUserRole },
    };

    this.mrraRoles = {
      [UserRole.User]: this.isProduction
        ? [this.mrraRequiredRoles.user.key]
        : [this.mrraRequiredRoles.user.key, this.mrraRequiredRoles.testUser.key],
    };
  }

  setRestrictedAccountRoles(): void {
    const userRole = RestrictedAccountRoles.user;
    const testUserRole = RestrictedAccountRoles.testUser;

    this.restrictedAccountRequiredRoles = {
      user: { ...userRole },
      testUser: this.isProduction ? undefined : { ...testUserRole },
    };

    this.restrictedAccountRoles = {
      [UserRole.User]: this.isProduction
        ? [this.restrictedAccountRequiredRoles.user.key]
        : [this.restrictedAccountRequiredRoles.user.key, this.restrictedAccountRequiredRoles.testUser.key],
    };
  }

  setManualRatingRoles(): void {
    const userRole = ManualRatingUserRole.user;
    const testUserRole = ManualRatingUserRole.testUser;
    const supervisorRole = ManualRatingSupervisorRole.user;
    const testSupervisorRole = ManualRatingSupervisorRole.testUser;

    const manualRatingUserRequiredRoles: ApplicationRoles = {
      user: { ...userRole },
      testUser: this.isProduction ? undefined : { ...testUserRole },
    };
    const manualRatingSupervisorRequiredRoles: ApplicationRoles = {
      user: { ...supervisorRole },
      testUser: this.isProduction ? undefined : { ...testSupervisorRole },
    };

    this.manualRatingRoles = {
      [UserRole.User]: this.isProduction
        ? [manualRatingUserRequiredRoles.user.key, manualRatingSupervisorRequiredRoles.user.key]
        : [
            manualRatingUserRequiredRoles.user.key,
            manualRatingSupervisorRequiredRoles.user.key,
            manualRatingUserRequiredRoles.testUser.key,
            manualRatingSupervisorRequiredRoles.testUser.key,
          ],
    };
  }

  isAuthorizedUserToWhatIf(user: User): boolean {
    return this.hasRole(user.roles, this.whatIfRoles[UserRole.User]);
  }

  isAuthorizedUserToMrra(user: User): boolean {
    return this.hasRole(user.roles, this.mrraRoles[UserRole.User]);
  }

  isAuthorizedUserToRestrictedAccount(user: User): boolean {
    return this.hasRole(user.roles, this.restrictedAccountRoles[UserRole.User]);
  }

  hasManualRatingRole(user: User): boolean {
    return this.hasRole(user.roles, this.manualRatingRoles[UserRole.User]);
  }

  /** TODO: an authorized user can have any set of roles defined above... */
  isAuthorizedUser(user: User): boolean {
    return (
      this.hasRole(user.roles, this.roleNames[UserRole.User]) ||
      this.hasRole(user.roles, this.roleNames[UserRole.SuperUser])
    );
  }

  /** TODO: some authorized users may have mutliple roles... see role-check-guard.ts as it handles this!!! */
  hasMultipleRoles(user: User): boolean {
    return (
      this.hasRole(user.roles, this.roleNames[UserRole.User]) &&
      this.hasRole(user.roles, this.roleNames[UserRole.SuperUser])
    );
  }

  get isBasicUser(): boolean {
    return this.hasRole(this.user.roles, this.roleNames[UserRole.User]);
  }

  get isSuperUser(): boolean {
    return this.hasRole(this.user.roles, this.roleNames[UserRole.SuperUser]);
  }

  private hasRole(userRoles: string[], roleOf: any): boolean {
    const roleOfArray = _.castArray(roleOf);
    const splitUserRolesArr = _.map(userRoles, (role: string) => {
      return role.replace(RegexRules.getTextBeforeSlash, '');
    });
    const results = _.intersectionBy(splitUserRolesArr, roleOfArray, (value) => value.toUpperCase());
    return !!results?.length;
  }

  setRole(role: UserRole) {
    if (!!this.user && !this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production)) {
      const roleEnum = UserRoleHelper.toEnum(role);
      if (!!roleEnum) {
        this.user.roles.length = 0;
        _.castArray(this.roleNames[roleEnum]).forEach((roleName) => this.user.roles.push(roleName));
      }
    }
  }

  getRole(): UserRole {
    if (this.hasRole(this.user.roles, this.roleNames[UserRole.User])) {
      return UserRole.User;
    }
    if (this.hasRole(this.user.roles, this.roleNames[UserRole.SuperUser])) {
      return UserRole.SuperUser;
    }
  }
}
