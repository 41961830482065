import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentPaginationActions } from '@shared/enums/actions.enum';
import { ContentPagination } from '@shared/models/content-pagination';

@Component({
  selector: 'app-content-pagination',
  templateUrl: './content-pagination.component.html',
  styleUrls: ['./content-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPaginationComponent {
  @Input() contentPagination: ContentPagination;

  @Output() selectNextOrPrevPagination = new EventEmitter<ContentPaginationActions>();

  constructor(public cdr: ChangeDetectorRef) {}

  selectPrevious(): void {
    this.selectNextOrPrevPagination.emit(ContentPaginationActions.previous);
  }

  selectNext(): void {
    this.selectNextOrPrevPagination.emit(ContentPaginationActions.next);
  }

  disabledButtons(): boolean {
    return !this.contentPagination || this.contentPagination.totalPages === 1;
  }
}
