<div class="pagination">
  <button
    mat-icon-button
    id="contentPaginationPrev"
    class="pagination__button"
    (click)="selectPrevious()"
    [disabled]="disabledButtons()"
  >
    <xpo-icon iconName="chevron-left"></xpo-icon>
  </button>
  <div class="pagination__text">
    <span id="contentPaginationText">{{ contentPagination?.currentPage }} OF {{ contentPagination?.totalPages }}</span>
    <span *ngIf="contentPagination?.secondTextLine">{{ contentPagination.secondTextLine }}</span>
  </div>
  <button
    mat-icon-button
    id="contentPaginationNext"
    class="pagination__button"
    (click)="selectNext()"
    [disabled]="disabledButtons()"
  >
    <xpo-icon iconName="chevron-right"></xpo-icon>
  </button>
</div>
