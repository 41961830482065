import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseNotesComponent {
  @Output() viewNow = new EventEmitter<void>();

  constructor(public dialogRef: MatDialogRef<ReleaseNotesComponent>) {}

  viewReleaseNotes(): void {
    this.close();
    this.viewNow.emit();
  }

  close(): void {
    this.dialogRef.close();
  }
}
