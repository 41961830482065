import { Injectable } from '@angular/core';
import { ConfigManagerProperties } from '@shared/enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

@Injectable({
  providedIn: 'root',
})
export class ApplicationUtilsService {
  buildVersion = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
  readonly releaseNotesLink =
    'https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Rating%20Release%20Notes.aspx';
  appToDisplayAppSwitcher = this.configManagerService.getSetting<string>(ConfigManagerProperties.ratingEdgeApps);
  readonly helpLink =
    'https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Rating.aspx';

  ratingRulesetDetailsNavigatorEndpoint = `http://${this.configManagerService.getSetting<string>(
    ConfigManagerProperties.navigatorUrl
  )}${this.configManagerService.getSetting<string>(ConfigManagerProperties.ratingRulesetDetailsEndpoint)}`;

  edgeInvoicingUrl = this.configManagerService.getSetting<string>(ConfigManagerProperties.edgeInvoicingUrl);

  freightBillCopyUrl = `http://${this.configManagerService.getSetting<string>(
    ConfigManagerProperties.navigatorUrl
  )}${this.configManagerService.getSetting<string>(ConfigManagerProperties.freightBillCopyEndpoint)}`;

  pricingLegalDocsUrl = `${this.configManagerService.getSetting<string>(
    ConfigManagerProperties.regionUrl
  )}${this.configManagerService.getSetting<string>(ConfigManagerProperties.pricingLegalDocsEndpoint)}`;

  densityAndClassCalculatorUrl = `${this.configManagerService.getSetting<string>(
    ConfigManagerProperties.densityClassCalculatorWebUrl
  )}`;

  shipmentMgmtUrl = `${this.configManagerService.getSetting<string>(ConfigManagerProperties.csShipmentRegionUrl)}`;

  spotQuoteUrl = `http://${this.configManagerService.getSetting<string>(
    ConfigManagerProperties.navigatorUrl
  )}${this.configManagerService.getSetting<string>(ConfigManagerProperties.spotQuoteEndpoint)}`;

  zipQUrl = `${this.configManagerService.getSetting<string>(
    ConfigManagerProperties.tariffMgmtRegionUrl
  )}${this.configManagerService.getSetting<string>(ConfigManagerProperties.zipQEndpoint)}`;

  constructor(public configManagerService: ConfigManagerService) {}
}
