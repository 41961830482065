export enum JustifyContent {
  start = 'start',
  center = 'center',
  end = 'end',
}

export enum SnackBarSize {
  lineBreak = 'line-break-snackBar',
  large = 'large-snackBar',
  medium = 'medium-snackBar',
}
