import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MrraHandlerService } from '@app-store/mrra/handler/mrra.handler';
import { RateQuoteHandlerService } from '@app-store/rate-quote/handler/rate-quote.handler';
import { RatingAnalysisHandlerService } from '@app-store/rating-analysis/handler/rating-analysis-handler.service';
import { WhatIfRequestsHandlerService } from '@app-store/what-if-requests/handlers/what-if-requests-handler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClearStoreResolver implements Resolve<void> {
  constructor(
    private rateQuoteHandlerService: RateQuoteHandlerService,
    private ratingAnalysisHandlerService: RatingAnalysisHandlerService,
    private whatIfRequestsHandlerService: WhatIfRequestsHandlerService,
    private mrraHandlerService: MrraHandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void | Observable<void> | Promise<void> {
    this.rateQuoteHandlerService.clearRateQuoteStore();
    this.ratingAnalysisHandlerService.clearRatingAnalysisStore();
    this.whatIfRequestsHandlerService.whatIfRequestsClearStore();
    this.mrraHandlerService.clearMrraStore();
  }
}
