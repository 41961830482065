import { Injectable } from '@angular/core';
import { InfrastructureApiService, SendEmailRqst } from '@xpo-ltl-2.0/sdk-infrastructure';
import { DataOptions } from '@xpo-ltl/data-api';
import { Envelope } from '@xpo-ltl/sdk-common';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilsRequestsService } from '../utils/utils-requests.service';

@Injectable({
  providedIn: 'root',
})
export class InfrastructureRatingService {
  constructor(
    private infrastructureService: InfrastructureApiService,
    private utilsRequestsService: UtilsRequestsService
  ) { }

  sendEmail(
    request: SendEmailRqst,
    dataOptions: DataOptions = { toastOnError: false, loadingOverlayEnabled: true }
  ): Observable<void> {
    return this.infrastructureService.sendEmail(request, dataOptions).pipe(
      catchError((response: Envelope<any>) => {
        console.error(response);
        return throwError(
          this.utilsRequestsService.handleError<any>(response)
        );
      })
    );
  }
}
