import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { RatingAnalysisDetail } from '@shared/models/rating-analysis';
import { GetRatingInformationHistoryForShipmentResp } from '@xpo-ltl-2.0/sdk-rating';
import { RatingAnalysisActions } from '../actions/rating-analysis.actions';

export const ratingAnalysisFeatureKey = 'ratingAnalysis';
export const ratingAnalysisFeatureSelector = createFeatureSelector<RatingAnalysisState>(ratingAnalysisFeatureKey);

export interface RatingAnalysisState {
  selectedProNumberDetail: RatingAnalysisDetail;
  loadingGetProNumberDetailBreakdown: boolean;
  ratingAnalysisDetailBreakDown: GetRatingInformationHistoryForShipmentResp;
}

export const ratingAnalysisInitState: RatingAnalysisState = {
  selectedProNumberDetail: undefined,
  loadingGetProNumberDetailBreakdown: false,
  ratingAnalysisDetailBreakDown: undefined,
};

export const reducer = createReducer(
  ratingAnalysisInitState,
  on(RatingAnalysisActions.setSelectedProNumberDetail, (state, { selectedProNumberDetail }) => {
    return { ...state, selectedProNumberDetail };
  }),
  on(RatingAnalysisActions.clearRatingAnalysisStore, (state) => {
    return {
      ...state,
      selectedProNumberDetail: undefined,
      loadingGetProNumberDetailBreakdown: false,
      ratingAnalysisDetailBreakDown: undefined,
    };
  }),
  on(RatingAnalysisActions.getProNumberDetailBreakdown, (state) => {
    return {
      ...state,
      loadingGetProNumberDetailBreakdown: true,
    };
  }),
  on(RatingAnalysisActions.getProNumberDetailBreakdownSuccess, (state, { ratingAnalysisDetailBreakDown }) => {
    return {
      ...state,
      loadingGetProNumberDetailBreakdown: false,
      ratingAnalysisDetailBreakDown: ratingAnalysisDetailBreakDown,
    };
  }),
  on(RatingAnalysisActions.getProNumberDetailBreakdownFail, (state) => {
    return {
      ...state,
      loadingGetProNumberDetailBreakdown: false,
      ratingAnalysisDetailBreakDown: undefined,
    };
  })
);
