import {
  WhatIfRequests,
  WhatIfRequestsAgrements,
  whatIfRequestsStatus,
  whatIfRequestsStatusKey,
} from '@shared/models/what-if-requests';
import { whatIfRequestAgrementEmptyMock } from '@shared/utils/mocks/what-if-requests.mock';
import { User } from '@xpo-ltl/sdk-common';

export const WhatIfRequestsRules = {
  allowedFileExtensions: ['.csv'],
  pickUpFromMinAllowDateValid(pickUpFrom: Date): boolean {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 3);
    currentDate.setHours(0, 0, 0, 0);
    return pickUpFrom >= currentDate;
  },
  allowToDeleteBaseOnSelectedUser(request: WhatIfRequests, loggedUser: User): boolean {
    return loggedUser.userId === request.auditInfo.createdById;
  },
  allowToDeleteBaseOnStatusCd(request: WhatIfRequests): boolean {
    return (
      request.statusCd === whatIfRequestsStatus[whatIfRequestsStatusKey.Completed].key ||
      request.statusCd === whatIfRequestsStatus[whatIfRequestsStatusKey.Failed].key
    );
  },
  allowToDelete(request: WhatIfRequests, loggedUser: User): boolean {
    return (
      WhatIfRequestsRules.allowToDeleteBaseOnStatusCd(request) &&
      WhatIfRequestsRules.allowToDeleteBaseOnSelectedUser(request, loggedUser)
    );
  },
  isThereAtLeastOneRequestToDeleteWithWrongSelectedUser(requests: WhatIfRequests[], loggedUser: User): boolean {
    return requests.some(
      (req: WhatIfRequests) => !WhatIfRequestsRules.allowToDeleteBaseOnSelectedUser(req, loggedUser)
    );
  },
  isAgreementIdEmpty(agreementsId: WhatIfRequestsAgrements[]): boolean {
    return !agreementsId || agreementsId.length === 0 || agreementsId[0].id === whatIfRequestAgrementEmptyMock.id;
  },
  setAgreementIdFieldListValues(agreementsId: WhatIfRequestsAgrements[]): WhatIfRequestsAgrements[] {
    return agreementsId?.length > 0 ? agreementsId : [whatIfRequestAgrementEmptyMock];
  },
};
