import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/error';
import { MexicoCityModel } from '@shared/models/mexico-city.model';
import { Tariff } from '@xpo-ltl/sdk-tariffmanagement';

export abstract class TariffManagementActions {
  static getTariff = createAction('[TariffManagement] Get the list of tariffs');
  static getTariffSuccess = createAction(
    '[TariffManagement] Get the list of tariffs Success',
    props<{ tariffList: Tariff[] }>()
  );
  static getTariffFail = createAction(
    '[TariffManagement] Get the list of tariffs Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getMexicoCities = createAction('[TariffManagement] Get the list of Mexico cities');
  static getMexicoCitiesSuccess = createAction(
    '[TariffManagement] Get the list of Mexico cities Success',
    props<{ mexicoCities: MexicoCityModel[] }>()
  );
  static getMexicoCitiesFail = createAction(
    '[TariffManagement] Get the list of Mexico cities Fail',
    props<{ errors: ErrorModel[] }>()
  );
}
