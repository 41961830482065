import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from 'environments/environment';
import { storeDevToolsConfig } from './app-store.config';
import { appReducers } from './app-store.reducer';
import { InfrastructureEffects } from './infrastructure/effects/infrastructure.effects';
import { LocationEffects } from './location/effects/location.effects';
import { UserPreferenceEffects } from './user-preference/effects/user-preference.effects';
import { UserEffects } from './user/effects/user.effects';
import { TariffManagementEffects } from './tariffManagement/effects/tariff-management.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([
      UserEffects,
      UserPreferenceEffects,
      TariffManagementEffects,
      InfrastructureEffects,
      LocationEffects,
    ]),
    ...storeDevToolsConfig(environment.enableDevTools),
  ],
})
export class AppStoreModule {}
