import { Injectable } from '@angular/core';
import { UserPreferencesService } from '@core/services/user-preferences/user-preferences.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorModel } from '@shared/models/error';
import { UserPreferenceResultType } from '@shared/models/user-preference';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UserPreferenceActions } from '../actions/user-preference.actions';

@Injectable()
export class UserPreferenceEffects {
  getUserPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.getUserPreference),
      exhaustMap(({ componentName }) => {
        return this.userPreferencesService.getPreferencesFor(componentName).pipe(
          map((preferences: UserPreferenceResultType) =>
            UserPreferenceActions.getUserPreferenceSuccess({ componentName, preferences })
          ),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.getUserPreferenceFail({ componentName, errors }))
          )
        );
      })
    )
  );

  updateUserPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.updateUserPreference),
      exhaustMap(({ componentName, preferences }) => {
        return this.userPreferencesService.updatePreferencesFor(componentName, preferences).pipe(
          map(() => UserPreferenceActions.updateUserPreferenceSuccess({ componentName, preferences })),
          catchError((errors: ErrorModel[]) =>
            of(UserPreferenceActions.updateUserPreferenceFail({ componentName, errors }))
          )
        );
      })
    )
  );

  constructor(private actions$: Actions, public userPreferencesService: UserPreferencesService) {}
}
