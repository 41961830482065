import { Injectable } from '@angular/core';
import {
  GetSicForPostalCodesQuery,
  GetSicForPostalCodesResp,
  LocationApiService,
  PostalSicAssignment
} from '@xpo-ltl-2.0/sdk-location';
import { Envelope } from '@xpo-ltl/sdk-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UtilsRequestsService } from '../utils/utils-requests.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private locationApiService: LocationApiService, private utilsRequestsService: UtilsRequestsService) { }

  getSicForPostalCodes(request: GetSicForPostalCodesQuery): Observable<GetSicForPostalCodesResp> {
    return this.locationApiService.getSicForPostalCodes(request).pipe(
      map((response: Envelope<GetSicForPostalCodesResp>) => {
        const getSicForPostalCodesResp: GetSicForPostalCodesResp = (response as any) as GetSicForPostalCodesResp;

        if (getSicForPostalCodesResp.postalSicAssignments?.length > 0) {
          getSicForPostalCodesResp.postalSicAssignments = getSicForPostalCodesResp.postalSicAssignments.map(
            (postalSicAssignment: PostalSicAssignment) => {
              postalSicAssignment.stateCd = postalSicAssignment.stateCd?.trim();
              return postalSicAssignment;
            }
          );
        }

        return getSicForPostalCodesResp;
      }),
      catchError((response: Envelope<any>) => {
        console.error(response);
        return throwError(
          this.utilsRequestsService.handleError<any>(response)
        );
      })
    );
  }
}
