<mat-form-field floatLabel="always">
  <mat-label [id]="fieldLabelId">{{ labelName }}</mat-label>
  <input
    [id]="idField"
    type="text"
    matInput
    [placeholder]="placeHolder"
    [formControl]="form"
    [matAutocomplete]="agreementIdAutocomplete"
    xpoAutocomplete
  />
  <mat-icon matSuffix>arrow_drop_down</mat-icon>
  <mat-autocomplete #agreementIdAutocomplete>
    <mat-option
      *ngFor="let agreementId of itemList$ | async; trackBy: trackByAgreementId"
      [value]="agreementId.id"
      xpoOptionTwoLines
    >
      <xpo-select-two-lines-container>
        <span>{{ agreementId.id }}</span>
        <span *ngIf="displayAgreementDetails(agreementId)"
          >Effective: {{ agreementId.effective }} | Expiry: {{ agreementId.expiry }}</span
        >
      </xpo-select-two-lines-container>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-error [id]="errorId" *ngIf="errorMessage" class="error">
  <xpo-icon iconName="error"></xpo-icon>
  {{ errorMessage }}
</mat-error>
