import { Injectable } from '@angular/core';
import { MexicoCityModel } from '@shared/models/mexico-city.model';
import { ListZoneCitiesResp } from '@xpo-ltl/sdk-tariffmanagement';

@Injectable({
  providedIn: 'root'
})
export class MexicoCitiesMapperService {

  constructor() { }

  getCities(zoneCitiesList: ListZoneCitiesResp): MexicoCityModel[] {
    return zoneCitiesList
      ?.zoneCities
      ?.map(city => ({name: city.cityName, state: city.stateName}))
      ?.sort((cityOne, cityTwo) => cityOne.name < cityTwo.name ? -1 : 1)
    || [];
  }

}
